import firebase from 'firebase/app';
import 'firebase/analytics';
import 'firebase/auth';
import 'firebase/firestore';

const config = {
  apiKey: 'AIzaSyDWrCq5KcdfCxd8-oeyrJOg7uc41JPJUQQ',
  authDomain: 'songbook-4fa14.firebaseapp.com',
  databaseURL: 'https://songbook-4fa14.firebaseio.com',
  projectId: 'songbook-4fa14',
  storageBucket: 'songbook-4fa14.appspot.com',
  messagingSenderId: '1068330716024',
  appId: '1:1068330716024:web:64a23083477ef048',
  measurementId: 'G-YKZTMF3KDC'
};

firebase.initializeApp(config);
firebase.analytics.isSupported().then((isSupported) => {
  // need to check if it is supported (it won't work in test mode)
  if (isSupported) {
    firebase.analytics();
  }
});
firebase.firestore().enablePersistence()
  .catch(function (err) {
    if (err.code === 'failed-precondition') {
      console.log('Multiple tabs open, persistence can only be enabled in one tab at a a time.');
    } else if (err.code === 'unimplemented') {
      console.log('The current browser does not support all of the features required to enable persistence');
    }
  });
// firebase.firestore().disableNetwork();

export default firebase;

// Configure FirebaseUI.
export const uiConfig = {
  // Popup signin flow rather than redirect flow.
  signInFlow: 'popup',
  // Redirect to /signedIn after sign in is successful. Alternatively you can provide a callbacks.signInSuccess function.
  signInSuccessUrl: '/signedIn',
  // We will display Google and Facebook as auth providers.
  signInOptions: [
    firebase.auth.GoogleAuthProvider.PROVIDER_ID,
    firebase.auth.FacebookAuthProvider.PROVIDER_ID
  ]
};
