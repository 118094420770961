import React from 'react';

import IconButton from '@material-ui/core/IconButton/index';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import Menu from '@material-ui/core/Menu';

function ConfigMenu (props) {
  const [anchorMenuEl, setAnchorMenuEl] = React.useState(null);
  const menuOpen = Boolean(anchorMenuEl);

  function handleCloseMenu (event) {
    setAnchorMenuEl(null);
  }

  function handleMenu (event) {
    setAnchorMenuEl(event.currentTarget);
  }

  return (
        <div>
            <IconButton
                aria-label="menu of current user"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                onClick={handleMenu}
                color="inherit"
            >
                <MoreVertIcon/>
            </IconButton>
            <Menu
                id="menu-appbar"
                anchorEl={anchorMenuEl}
                anchorOrigin={{
                  vertical: 'top',
                  horizontal: 'right'
                }}
                keepMounted
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'right'
                }}
                open={menuOpen}
                onClose={handleCloseMenu}
            >
                {
                    props.items.map((element, i) => (element))
                }
            </Menu>
        </div>
  );
}

export default ConfigMenu;
