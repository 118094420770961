import firebase from '../../Firestore';

export const FETCH_SONGS_TO_STRENGTHEN_PENDING = 'FETCH_SONGS_TO_STRENGTHEN_PENDING';
export const FETCH_SONGS_TO_STRENGTHEN_SUCCESS = 'FETCH_SONGS_TO_STRENGTHEN_SUCCESS';
export const FETCH_SONGS_TO_STRENGTHEN_ERROR = 'FETCH_SONGS_TO_STRENGTHEN_ERROR';

function fetchSongsToStrengthenPending () {
  return {
    type: FETCH_SONGS_TO_STRENGTHEN_PENDING
  };
}

function fetchSongsToStrengthenSuccess (songs) {
  return {
    type: FETCH_SONGS_TO_STRENGTHEN_SUCCESS,
    songs
  };
}

function fetchSongsToStrengthenError (error) {
  return {
    type: FETCH_SONGS_TO_STRENGTHEN_ERROR,
    error
  };
}

export function fetchSongsToStrengthen () {
  return (dispatch, getState, { services }) => {
    dispatch(fetchSongsToStrengthenPending());

    firebase.auth().currentUser.getIdToken().then(function (idToken) {
      services.songsRepository
        .findSongsStrength(idToken)
        .then((songsStrength) => {
          dispatch(fetchSongsToStrengthenSuccess(songsStrength));
        }).catch(error => {
          dispatch(fetchSongsToStrengthenError(error));
        });
    }).catch(function (error) {
      // Handle error
    });
  };
}
