import {withTheme} from '@material-ui/core/styles';
import React, {Component} from 'react';

import CreatableSelect from 'react-select/creatable';
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";

class TagsSelectRaw extends Component {
    constructor(props) {
        super(props);

        const availableTags = props.availableTags ? props.availableTags : [];

        const options = availableTags.map(function (tag) {
            return {value: tag, label: '#' + tag};
        });
        this.state = {
            options: options
        };
    }

    handleChange = (optionsSelected, actionMeta) => {
        let newValues;

        if (optionsSelected === null) {
            newValues = [];
        } else {
            newValues = optionsSelected.map(function (option) {
                return option.value;
            });
        }
        this.props.onChange(newValues);
    };

    formatCreateLabel = (inputValue) => {
        return inputValue + ' (new tag)';
    }

    getNewOptionData = (inputValue, optionLabel) => {
        return {
            label: '#' + optionLabel,
            value: inputValue,
            __isNew__: true,
        };
    }

    render() {
        const {theme, values} = this.props;
        const {options} = this.state;

        let defaultValues = options.filter(function (option) {
            return values.includes(option.value);
        });

        const customStyles = {
            control: (provided, state) => ({
                ...provided,
                background: theme.palette.background.paper,
            }),
            input: (provided, state) => ({
                ...provided,
                color: 'white',
            }),
            menu: (provided, state) => ({
                ...provided,
                background: theme.palette.background.paper,
            }),
            option: (provided, state) => {
                return {
                    ...provided,
                    background: state.isFocused ? theme.palette.secondary["500"] : theme.palette.background.paper,
                };
            },
            multiValue: (provided, state) => {
                return {
                    ...provided,
                    background: theme.palette.secondary["500"],
                };
            },
            multiValueLabel: (provided, state) => {
                return {
                    ...provided,
                    color: 'white'
                };
            },
            indicatorsContainerCSS: (provided, state) => {
                return {
                    ...provided,
                    padding: '4px'
                };
            },
        };

        let selectedColor = theme.palette.secondary.A200;

        return <FormControl fullWidth margin={this.props.margin}>
            <InputLabel shrink={true}>{this.props.label}</InputLabel>
            <div style={{'marginTop': '16px'}}>
                <CreatableSelect
                    placeholder={this.props.label}
                    isMulti
                    defaultValue={defaultValues}
                    onChange={this.handleChange}
                    formatCreateLabel={this.formatCreateLabel}
                    getNewOptionData={this.getNewOptionData}
                    styles={customStyles}
                    options={options}
                    theme={theme => {
                        return {
                            ...theme,
                            borderRadius: 0,
                            colors: {
                                ...theme.colors,
                                primary: 'white',
                                primary50: selectedColor,
                            },
                        };
                    }}
                />
            </div>
        </FormControl>;
    }
}

const TagsSelect = withTheme(TagsSelectRaw);

export default TagsSelect;
