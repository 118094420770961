import React from 'react';
import Button from '@material-ui/core/Button';
import ButtonGroup from '@material-ui/core/ButtonGroup';

import RepeatIcon from '@material-ui/icons/Repeat';

import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Grow from '@material-ui/core/Grow';
import Paper from '@material-ui/core/Paper';
import Popper from '@material-ui/core/Popper';
import MenuItem from '@material-ui/core/MenuItem';
import MenuList from '@material-ui/core/MenuList';
import {
  SUBSECTION_TYPES_PHRASES
} from '../../../DTO/Song/Section/SubSection/SongSubSectionFormDTOBuilder';
import { FormattedMessage, injectIntl } from 'react-intl';

function RepeatSectionSplitButton (props) {
  const intl = props.intl;
  const section = props.section;

  let firstPhrasesSubSectionPosition = -1;
  for (let i = 0; i < section.subSections.length; i++) {
    if (section.subSections[i].type === SUBSECTION_TYPES_PHRASES) {
      firstPhrasesSubSectionPosition = i + 1;
      break;
    }
  }

  const [open, setOpen] = React.useState(false);
  const anchorRef = React.useRef(null);
  const [selectedIndex, setSelectedIndex] = React.useState(firstPhrasesSubSectionPosition);

  function handleMenuItemClick (event, index) {
    setSelectedIndex(index);
    setOpen(false);
  }

  function handleToggle () {
    setOpen(prevOpen => !prevOpen);
  }

  function handleClose (event) {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }
    setOpen(false);
  }

  let subSectionToRepeatDescription = '';
  let selectedSubSectionTitle = '';
  if (section.subSections.length && selectedIndex) {
    const selectedSubSection = section.subSections[selectedIndex - 1];
    if (selectedSubSection.type !== SUBSECTION_TYPES_PHRASES) {
      // Podria pasar que estaba seleccionada una subseccion de tipo PHRASES, pero entre medio de dos subsecciones de repetición.
      // si esa subsección se elimina, se intería mostrar la descripción de una subsección de repetición, lo cual no es válido
      // Por lo tanto, se reinicia el state a la primera posición
      setSelectedIndex(firstPhrasesSubSectionPosition);
    }

    subSectionToRepeatDescription = intl.formatMessage({ id: 'app.songform.section.phraseSubSection.field.description' }, { position: selectedIndex });
    if (selectedSubSection.description !== '') {
      selectedSubSectionTitle = ' (' + selectedSubSection.description + ')';
    }
  }

  return (
        <span style={props.style}>
            <ButtonGroup variant="contained" color="secondary" ref={anchorRef} aria-label="split button" size="small">
                <Button disabled={selectedIndex === 0} onClick={ () => props.onClick(selectedIndex) }>
                    <RepeatIcon/>
                    <FormattedMessage
                        id="app.songform.section.button.repeat"
                        values={{ subSection: subSectionToRepeatDescription }}
                    />
                    {selectedSubSectionTitle}
                </Button>
                <Button
                    color="secondary"
                    size="small"
                    aria-owns={open ? 'menu-list-grow' : undefined}
                    aria-haspopup="true"
                    onClick={handleToggle}
                >
                    <ArrowDropDownIcon/>
                </Button>
            </ButtonGroup>
            <Popper open={open} anchorEl={anchorRef.current} role={undefined} transition disablePortal={false}
            >
                {({ TransitionProps, placement }) => (
                    <Grow
                        {...TransitionProps}
                        style={{
                          transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom'
                        }}
                    >
                        <Paper id="menu-list-grow">
                            <ClickAwayListener onClickAway={handleClose}>
                                <MenuList>
                                    {section
                                      .subSections
                                      .map(function (subSection, index) {
                                        const position = index + 1;
                                        if (subSection.type === SUBSECTION_TYPES_PHRASES) {
                                          const itemDescription = intl.formatMessage({ id: 'app.songform.section.phraseSubSection.field.description' }, { position });
                                          const subSectionDescription = (subSection.description === '') ? '' : ' (' + subSection.description + ')';
                                          return (
                                                    <MenuItem
                                                        key={itemDescription}
                                                        selected={position === selectedIndex}
                                                        onClick={event => handleMenuItemClick(event, position)}
                                                    >
                                                        <FormattedMessage
                                                            id="app.songform.section.button.repeat"
                                                            values={{ subSection: itemDescription }}
                                                        />
                                                        {subSectionDescription}
                                                    </MenuItem>
                                          );
                                        } else {
                                          return null;
                                        }
                                      })}
                                </MenuList>
                            </ClickAwayListener>
                        </Paper>
                    </Grow>
                )}
            </Popper>
        </span>
  );
}

export default injectIntl(RepeatSectionSplitButton);
