import React from "react";

import {Grid, Chip, withStyles} from '@material-ui/core';
import TextField from "@material-ui/core/TextField";
import InstrumentTypeSelect from "../Selects/InstrumentTypeSelect";
import InstrumentSelect from "../Selects/InstrumentSelect";
import {injectIntl} from 'react-intl';
import ArtistsSelect from "../Selects/ArtistsSelect";

const styles = theme => ({

    root: {
        marginTop: '34px',
        '& > div.MuiGrid-item': {
            paddingLeft: "10px",
            paddingRight: "10px",
            textAlign: "center",
        },
    },

    availableTagsRoot: {
        display: 'flex',
        justifyContent: 'center',
        flexWrap: 'wrap',
        paddingTop: "30px",
        '& > *': {
            margin: theme.spacing(0.5),
        },
    },
});

class SongsFilterForm extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            filterFormModel: props.model,
        };
    }

    handleTitleChange = event => {
        const value = event.target.value;

        let filterFormModel = this.state.filterFormModel;
        filterFormModel.title = value;
        this.setState({
            filterFormModel: filterFormModel
        });
        this.props.onChange();
    }

    handleArtistChange = event => {
        let filterFormModel = this.state.filterFormModel;

        filterFormModel.artistId = event.target.value;
        this.setState({
            filterFormModel: filterFormModel
        });
        this.props.onChange();
    }

    handleInstrumentTypeChange = event => {
        let filterFormModel = this.state.filterFormModel;

        filterFormModel.instrumentType = this.props.availableInstrumentsTypes[event.target.value];
        filterFormModel.instrument = '';

        this.setState({
            filterFormModel: filterFormModel
        });
        this.props.onChange();
    }

    handleInstrumentChange = (value) => {
        let filterFormModel = this.state.filterFormModel;

        filterFormModel.instrument = value;

        this.setState({
            filterFormModel: filterFormModel
        });
        this.props.onChange();
    }

    handleTagChipClick = (tag) => {
        let filterFormModel = this.state.filterFormModel;

        if (filterFormModel.tags.includes(tag)) {
            filterFormModel.tags = filterFormModel.tags.filter(existingTag => existingTag !== tag);
        } else {
            filterFormModel.tags.push(tag);
        }
        this.setState({
            filterFormModel: filterFormModel
        });
        this.props.onChange();
    }

    render() {
        const {filterFormModel} = this.state;
        const {classes, availableTags, availableInstrumentsTypes, artists, intl} = this.props;

        return (
            <form autoComplete="off">
                <Grid container className={classes.root} spacing={1}>
                    <Grid item xs={12} sm={3} md={3}>
                        <TextField
                            label={intl.formatMessage({id: 'app.songslist.filter.field.title'})}
                            InputLabelProps={{
                                shrink: true,
                            }}
                            fullWidth={true}
                            name="title"
                            value={filterFormModel.title}
                            type="search"
                            onChange={this.handleTitleChange}
                        />
                    </Grid>
                    <Grid item xs={12} sm={3} md={3}>
                        <ArtistsSelect
                            label={intl.formatMessage({id: 'app.songform.field.artist'})}
                            name="artistId"
                            value={filterFormModel.artistId}
                            artists={artists}
                            InputLabelProps={{
                                shrink: true,
                            }}
                            onChange={this.handleArtistChange}
                        />
                    </Grid>
                    <Grid item xs={6} sm={3} md={3}>
                        <InstrumentTypeSelect
                            label={intl.formatMessage({id: 'app.songslist.filter.field.instrumenttype'})}
                            instruments={availableInstrumentsTypes}
                            InputLabelProps={{
                                shrink: true,
                            }}
                            name="instrumentTypeId"
                            autoWidth={true}
                            value={filterFormModel.instrumentType ? filterFormModel.instrumentType.id : ''}
                            onChange={this.handleInstrumentTypeChange}
                        />
                    </Grid>
                    <Grid item xs={6} sm={3} md={3}>
                        <InstrumentSelect
                            InputLabelProps={{
                                shrink: true,
                            }}
                            name="instrument"
                            autoWidth={true}
                            instrumentType={filterFormModel.instrumentType ? filterFormModel.instrumentType : null}
                            value={filterFormModel.instrument}
                            onChange={this.handleInstrumentChange}
                        />
                    </Grid>
                    <Grid item xs={12} sm={12} md={12}>
                        <div className={classes.availableTagsRoot}>
                            {
                                availableTags.map((tag, key) => {
                                    return (<Chip
                                        key={key}
                                        clickable
                                        color={(filterFormModel.tags.includes(tag)) ? 'secondary' : 'primary'}
                                        label={'#' + tag}
                                        onClick={() => this.handleTagChipClick(tag)}
                                    />);
                                })
                            }
                        </div>
                    </Grid>
                </Grid>
            </form>
        );
    }
}
export default withStyles(styles)(injectIntl(SongsFilterForm));
