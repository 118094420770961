import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { signup } from '../store/actions/sessionActions';
import SignupView from '../components/SignupView/SignupView';

function mapStateToProps (state, ownProps) {
  return {};
}

const mapDispatchToProps = dispatch => bindActionCreators({
  signup
}, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SignupView);
