import ChordSheetJS from 'chordsheetjs';

export class ChordNotationFormatter {
  constructor () {
    this.innerFormatter = new ChordSheetJS.HtmlTableFormatter();
  }

  format (parsedSong) {
    if (typeof parsedSong === 'string') {
      // if parsedSong is a string, it's because chordjs couldn't parse it
      return parsedSong;
    }
    return this.innerFormatter.format(parsedSong);
  }
}
