import React from "react";
import {ArtistDTO} from "../ArtistForm/DTO/ArtistDTO";
import {Redirect} from "react-router-dom";
import {Container} from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import ArtistForm from "../ArtistForm/ArtistForm";
import {FormattedMessage} from "react-intl";

class ArtistCreationView extends React.Component
{
    constructor(props)
    {
        super(props);

        this.state = {
            artist: new ArtistDTO(),
            actionComplete: false,
        };
    }

    createArtist = () =>
    {
        const { createArtist} = this.props;

        let artist = this.state.artist;

        createArtist(artist);

        this.setState({
            actionComplete: true
        })
    }

    renderRedirect = () =>
    {
        if (this.state.actionComplete)
        {
            const route = '/artists/list';

            return <Redirect to={route} />
        }
    }

    goBack = () =>
    {
        window.history.back();
    };

    render(){

        const { artist } = this.state;

        return (
            <Container maxWidth="lg">

                {this.renderRedirect()}

                <Typography component="h2" variant="h6" gutterBottom>
                    <FormattedMessage id="app.artistcreation.title" />
                </Typography>

                <ArtistForm
                    artist={artist}
                    onSubmit={this.createArtist}
                    onCancel={this.goBack}
                />
            </Container>
        );
    }

}

export default ArtistCreationView;