import React from 'react';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { MuiThemeProvider, createMuiTheme, responsiveFontSizes } from '@material-ui/core/styles';
import './App.css';
import { orange } from '@material-ui/core/colors';

import { observeAuthStateChange } from './store/actions/sessionActions.js';
import Dashboard from './dashboard/Dashboard';
import CacheBuster from './CacheBuster';

let theme = createMuiTheme({
  typography: {
    fontFamily: '"Open Sans", sans-serif'
  },
  palette: {
    primary: {
      main: '#212121'
    },
    secondary: orange,
    tertiary: '#119DA4',
    type: 'dark'
  },
  overrides: {
    MuiTypography: {
      body1: {
        fontFamily: 'inherit'
      },
      subtitle2: {
        color: orange['300']
      }
    }
  }
});
theme = responsiveFontSizes(theme);

export class App extends React.Component {
  constructor (props) {
    super(props);
    const { observeAuthStateChange } = this.props;
    observeAuthStateChange();
  }

  render () {
    return (
            <MuiThemeProvider theme={theme}>
                <CacheBuster>
                    {({ loading, isLatestVersion, refreshCacheAndReload }) => {
                      if (loading) return null;
                      if (!loading && !isLatestVersion) {
                        // You can decide how and when you want to force reload
                        refreshCacheAndReload();
                      }
                      return (
                            <div className="App">
                                <Dashboard />
                            </div>
                      );
                    }}
                </CacheBuster>
            </MuiThemeProvider>
    );
  }
}

const mapStateToProps = state => ({});

const mapDispatchToProps = dispatch => bindActionCreators({
  observeAuthStateChange
}, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(App);
