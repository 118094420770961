import PLAYER_EVENTS from './PlayerEvents';

const INNER_YOUTUBE_PLAYER_EVENTS = {
  VIDEO_ENDED: 0,
  VIDEO_CUED: 5
};

export default PLAYER_EVENTS;

const dispatchOnReadyHandlers = Symbol();
const dispatchOnFinishHandlers = Symbol();

export class YouTubePlayer {
  static get EVENTS () {
    return PLAYER_EVENTS;
  }

  constructor () {
    this.innerPlayer = null;
    this[dispatchOnFinishHandlers] = [];
    this[dispatchOnReadyHandlers] = [];

    this.lastSeekStop = 0;
    this.isRunning = false;
  }

  setInnerPlayer (innerPlayer) {
    this.innerPlayer = innerPlayer;
    this.dispatch(YouTubePlayer.EVENTS.READY);

    // this is necessary for avoiding the state video cued.
    // When a video is in this state, when user seek to X, the song is played
    this.innerPlayer.playVideo();
    this.innerPlayer.pauseVideo();

    this.innerPlayer.addEventListener('onStateChange',
      (videoState) => {
        switch (videoState.data) {
          case INNER_YOUTUBE_PLAYER_EVENTS.VIDEO_ENDED:
            this.dispatch(YouTubePlayer.EVENTS.FINISH);
            this.isRunning = false;
            this.lastSeekStop = 0;
            break;
          default:
            break;
        }
      }
    );
  }

  getInnerPlayer () {
    return this.innerPlayer;
  }

  play () {
    const videoPlayer = this.getInnerPlayer();
    videoPlayer.playVideo();

    this.isRunning = true;
  }

  pause () {
    this.isRunning = false;

    this.getInnerPlayer().pauseVideo();

    this.lastSeekStop = this.getInnerPlayer().getCurrentTime();
  }

  stop () {
    this.isRunning = false;

    /**
         * Hay un issue al llamar a getDuration del video luego de reanudar una canción pausada (devuelve siempre 0)
         * Para evitar que se pierda la información y tener que cargarla de nuevo, se simula un stop pausando y llevando al comienzo
         *  videoPlayer.stopVideo();
         */
    this.getInnerPlayer().pauseVideo();

    this.seekTo(0);
  }

  seekTo (seconds) {
    const videoPlayer = this.getInnerPlayer();

    this.lastSeekStop = seconds;

    videoPlayer.seekTo(this.lastSeekStop);

    if (this.isRunning) {
      this.play();
    }
  }

  getCurrentTime () {
    return (this.isRunning) ? this.getInnerPlayer().getCurrentTime() : this.lastSeekStop;
  }

  getDuration () {
    if (this.isAvailable()) {
      return this.getInnerPlayer().getDuration();
    }
  }

  getAvailablePlaybackRates () {
    return this.getInnerPlayer().getAvailablePlaybackRates();
  }

  setPlaybackRate (playbackRate) {
    this.getInnerPlayer().setPlaybackRate(playbackRate);
  }

  isAvailable () {
    return this.getInnerPlayer() !== null;
  }

  on (eventName, handler) {
    switch (eventName) {
      case YouTubePlayer.EVENTS.READY:
        return this[dispatchOnReadyHandlers].push(handler);
      case YouTubePlayer.EVENTS.FINISH:
        return this[dispatchOnFinishHandlers].push(handler);
      default:
        break;
    }
  }

  dispatch (eventName) {
    let handler, i, len, ref;

    ref = [];

    switch (eventName) {
      case YouTubePlayer.EVENTS.READY:
        ref = this[dispatchOnReadyHandlers];
        break;
      case YouTubePlayer.EVENTS.FINISH:
        ref = this[dispatchOnFinishHandlers];
        break;
      default:
        break;
    }

    for (i = 0, len = ref.length; i < len; i++) {
      handler = ref[i];
      setTimeout(handler, 0);
    }
  }
}
