import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { AUTH_STATE_LOGIN, getAuthState } from '../store/reducers/sessionReducer';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

const PublicRoute = function ({ component: Component, authState, onLoginRedirectToPath, ...rest }) {
  return <Route
        {...rest}
        component={(routeProps) => {
          if (authState === AUTH_STATE_LOGIN) {
            const { from } = routeProps.location.state || { from: { pathname: onLoginRedirectToPath } };
            return (
                    <Route
                        {...rest}
                        render={(props) => (
                            <Redirect to={from}/>
                        )}
                    />
            );
          }
          return <Component {...routeProps} />;
        }}
    />;
};

const mapStateToProps = state => {
  return {
    authState: getAuthState(state)
  };
};

const mapDispatchToProps = dispatch => bindActionCreators({
}, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PublicRoute);
