import './wdyr';
import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';

import { BrowserRouter } from 'react-router-dom';
import App from './App';
import * as serviceWorker from './serviceWorker';

import firebase from './Firestore';

import { createStore, applyMiddleware, compose } from 'redux';
import { offline } from '@redux-offline/redux-offline';
import defaultOfflineConfig from '@redux-offline/redux-offline/lib/defaults';
import { configureFlashMessages } from 'redux-flash-messages';
import rootReducer from './store/reducers/rootReducer.js';
import { Provider } from 'react-redux';
import thunk from 'redux-thunk';
import logger from 'redux-logger';
import { reduxFirestore, getFirestore } from 'redux-firestore';

import { IntlProvider } from 'react-intl';
import { messages, language } from './translations/index';

import { InstrumentsTypeRepository } from 'services/repositories/InstrumentsTypeRepository';
import { ArtistsRepository } from 'services/repositories/ArtistsRepository';
import { SongsRepository } from 'services/repositories/SongsRepository';
import { StatsRepository } from 'services/repositories/StatsRepository';
import { SongReproductionService } from 'services/SongReproductionService';

const services = {};
services.artistsRepository = new ArtistsRepository(getFirestore);
services.instrumentsTypeRepository = new InstrumentsTypeRepository(getFirestore);
services.songsRepository = new SongsRepository(getFirestore);
services.statsRepository = new StatsRepository(getFirestore);
services.songReproductionService = new SongReproductionService(services.songsRepository, services.statsRepository);

const offlineConfig = {
  ...defaultOfflineConfig,
  persistOptions: {
    blacklist: ['session', 'app']
  }
};

let middleware = [thunk.withExtraArgument({ services })];
if (process.env.NODE_ENV === 'development') {
  middleware = [...middleware, logger];
}
const store = createStore(
  rootReducer,
  compose(
    applyMiddleware(...middleware),
    offline(offlineConfig),
    reduxFirestore(firebase)
  )
);

configureFlashMessages({
  // The dispatch function for the Redux store.
  dispatch: store.dispatch
});

ReactDOM.render((<Provider store={store}>
  <BrowserRouter>
    <IntlProvider locale={language} messages={messages[language]}>
      <App/>
    </IntlProvider>
  </BrowserRouter>
</Provider>), document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
// serviceWorker.unregister();
serviceWorker.register();
