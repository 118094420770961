import React from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles/index';
import Drawer from '@material-ui/core/Drawer/index';
import Divider from '@material-ui/core/Divider/index';
import IconButton from '@material-ui/core/IconButton/index';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import MenuIcon from '@material-ui/icons/Menu';
import DashboardMenu from './DashboardMenu';
import AppBar from '@material-ui/core/AppBar/index';
import Toolbar from '@material-ui/core/Toolbar/index';
import Typography from '@material-ui/core/Typography/index';

import ConfigMenu from './ConfigMenu';

import './Dashboard.css';
import { AUTH_STATE_LOGIN, getAuthState } from '../store/reducers/sessionReducer';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { logout } from '../store/actions/sessionActions';
import { FormattedMessage, injectIntl } from 'react-intl';
import MenuItem from '@material-ui/core/MenuItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import ListItemText from '@material-ui/core/ListItemText';

const drawerWidth = 240;

const useStyles = makeStyles(theme => ({
  toolbarIcon: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: '0 8px',
    ...theme.mixins.toolbar
  },
  title: {
    flexGrow: 1
  },
  appBar: {
    backgroundColor: theme.palette.primary.dark
  },
  drawerPaper: {
    position: 'relative',
    whiteSpace: 'nowrap',
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen
    })
  },
  drawerPaperClose: {
    overflowX: 'hidden',
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    }),
    width: 0
  }
}));

function DashboardAppBar (props) {
  const intl = props.intl;
  const classes = useStyles();

  const [open, setOpen] = React.useState(false);

  const handleDrawerOpen = () => {
    setOpen(true);
  };
  const handleDrawerClose = () => {
    setOpen(false);
  };

  return (
        <div>
            <AppBar className={classes.appBar}>
                <Toolbar className={classes.toolbar}>
                    {(props.authState === AUTH_STATE_LOGIN) && (
                        <IconButton
                            color="inherit"
                            aria-label="Open drawer"
                            onClick={handleDrawerOpen}
                        >
                            <MenuIcon/>
                        </IconButton>
                    )}
                    <Typography component="h1" variant="h6" color="inherit" noWrap className={classes.title}>
                        <FormattedMessage id="app.name"/>
                    </Typography>
                    {(props.authState === AUTH_STATE_LOGIN) && (
                        <ConfigMenu
                            items={[
                                <MenuItem onClick={props.handleLogout} key={1}>
                                    <ListItemIcon>
                                        <ExitToAppIcon/>
                                    </ListItemIcon>
                                    <ListItemText primary={intl.formatMessage({ id: 'app.session.logout' })}/>
                                </MenuItem>
                            ]}
                        />
                    )}
                </Toolbar>
            </AppBar>
            <Drawer
                classes={{
                  paper: clsx(classes.drawerPaper, !open && classes.drawerPaperClose)
                }}
                open={open}
                onClose={handleDrawerClose}
            >
                <div
                    role="presentation"
                    className={classes.toolbarIcon}
                    onClick={handleDrawerClose}
                    onKeyDown={handleDrawerClose}
                >
                    <IconButton onClick={handleDrawerClose}>
                        <ChevronLeftIcon/>
                    </IconButton>
                </div>
                <Divider/>
                <DashboardMenu onItemMenuClick={handleDrawerClose}/>
            </Drawer>
        </div>
  );
}

function mapStateToProps (state) {
  return {
    authState: getAuthState(state)
  };
}

const mapDispatchToProps = dispatch => bindActionCreators({
  handleLogout: logout
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(DashboardAppBar));
