import { YouTubePlayer } from './Player/YouTubePlayer';
import { PlayAlongPlayer } from './Player/PlayAlongPlayer';
import { Reproduction } from './Reproduction';

export class ReproductionBuilder {
  constructor (song) {
    this.trainingMode = false;
    this.requiresCountingIn = false;
    this.song = null;
    this.songDuration = null;
    this.songTempo = null;
  }

  withSongDuration (songDuration) {
    this.songDuration = songDuration;
    return this;
  }

  withSongTempo (songTempo) {
    this.songTempo = songTempo;
    return this;
  }

  withTrainingMode (trainingMode) {
    this.trainingMode = trainingMode;
    return this;
  }

  withCountingIn (requiresCountingIn) {
    this.requiresCountingIn = requiresCountingIn;
    return this;
  }

  createReproduction () {
    if (this.requiresCountingIn && this.songTempo === null) {
      throw new Error('The song tempo is mandatory');
    }

    let player;
    if (this.trainingMode) {
      player = new YouTubePlayer();
    } else {
      if (this.songDuration === null) {
        throw new Error('The song duration is mandatory');
      }
      player = new PlayAlongPlayer(this.songDuration);
    }
    return new Reproduction(player, this.requiresCountingIn, this.songTempo);
  }
}
