import {
  getLastPlayedSongs,
  getLastPlayedSongsError,
  getLastPlayedSongsPending
} from '../store/reducers/songsReducer';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import HomeView from '../components/HomeView/HomeView';
import { fetchLastPlayedSongs } from '../store/actions/songsActions';
import { LastPlayedSongDTO } from '../components/HomeView/DTO/LastPlayedSongDTO';
import { getMonthlyStats, getMonthlyStatsError } from '../store/reducers/statsReducer';
import { fetchMonthlyStats } from '../store/actions/statsActions';
import { getDisplayName } from '../store/reducers/sessionReducer';

function mapStateToProps (state, ownProps) {
  const lastPlayedSongsFromState = getLastPlayedSongs(state);

  const lastPlayedSongs = [];
  lastPlayedSongsFromState.forEach(function (lastPlayedSong) {
    const { song, artist } = lastPlayedSong;
    lastPlayedSongs.push(new LastPlayedSongDTO(song.id, song.title, artist.name, artist.image));
  });

  return {
    lastPlayedSongsError: getLastPlayedSongsError(state),
    lastPlayedSongsDTO: lastPlayedSongs,
    lastPlayedSongsPending: getLastPlayedSongsPending(state),
    monthlyStats: getMonthlyStats(state),
    monthlyStatsError: getMonthlyStatsError(state),
    displayName: getDisplayName(state)
  };
}

const mapDispatchToProps = dispatch => bindActionCreators({
  fetchLastPlayedSongs,
  fetchMonthlyStats
}, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(HomeView);
