import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';

class AlertDialog extends React.Component {
  render () {
    const { open, onClose, title, message, buttonText } = this.props;
    return (
            <Dialog
                open={open}
                onClose={onClose}
                keepMounted
                aria-labelledby="alert-dialog-slide-title"
                aria-describedby="alert-dialog-slide-description"
            >
                <DialogTitle id="alert-dialog-slide-title">{title}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-slide-description">{message}</DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button color="secondary" onClick={onClose}>
                        {buttonText}
                    </Button>
                </DialogActions>
            </Dialog>
    );
  }
}

export default AlertDialog;
