import React from 'react';

import { Link } from 'react-router-dom';

import ListItem from '@material-ui/core/ListItem/index';
import ListItemIcon from '@material-ui/core/ListItemIcon/index';
import ListItemText from '@material-ui/core/ListItemText/index';
import DashboardIcon from '@material-ui/icons/Dashboard';
import MusicNoteIcon from '@material-ui/icons/MusicNote';
import AccountBoxIcon from '@material-ui/icons/AccountBox';
import FitnessCenterIcon from '@material-ui/icons/FitnessCenter';
import GroupIcon from '@material-ui/icons/Group';
import List from "@material-ui/core/List";

import {injectIntl} from 'react-intl';

class DashboardMenu extends React.Component
{
    handleItemMenuClick  = (event) => {
        this.props.onItemMenuClick();
    }

    render()
    {
        const {intl} = this.props;

        return (
            <List>
                <ListItem button component={Link} to="/home">
                    <ListItemIcon>
                        <DashboardIcon />
                    </ListItemIcon>
                    <ListItemText
                        primary={intl.formatMessage({id: 'app.menu.home'})}
                        onClick={this.handleItemMenuClick}
                    />
                </ListItem>
                <ListItem button component={Link} to="/artists/list">
                    <ListItemIcon>
                        <GroupIcon />
                    </ListItemIcon>
                    <ListItemText
                        primary={intl.formatMessage({id: 'app.menu.artists'})}
                        onClick={this.handleItemMenuClick}
                    />
                </ListItem>
                <ListItem button component={Link} to="/training">
                    <ListItemIcon>
                        <FitnessCenterIcon />
                    </ListItemIcon>
                    <ListItemText
                        primary={intl.formatMessage({id: 'app.menu.training'})}
                        onClick={this.handleItemMenuClick}
                    />
                </ListItem>
                <ListItem button component={Link} to="/songs/list">
                    <ListItemIcon>
                        <MusicNoteIcon />
                    </ListItemIcon>
                    <ListItemText
                        primary={intl.formatMessage({id: 'app.menu.songs'})}
                        onClick={this.handleItemMenuClick}
                    />
                </ListItem>
                <ListItem button component={Link} to="/profile">
                    <ListItemIcon>
                        <AccountBoxIcon />
                    </ListItemIcon>
                    <ListItemText
                        primary={intl.formatMessage({id: 'app.menu.profile'})}
                        onClick={this.handleItemMenuClick}
                    />
                </ListItem>
            </List>
        );
    }
}

export default injectIntl(DashboardMenu);