
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import ProfileView from '../components/ProfileView/ProfileView';
import { changeAccountDetails, changePassword } from '../store/actions/sessionActions';
import { getDisplayName } from '../store/reducers/sessionReducer';

function mapStateToProps (state, ownProps) {
  return {
    displayName: getDisplayName(state)
  };
}

const mapDispatchToProps = dispatch => bindActionCreators({
  changePasswordHandler: changePassword,
  changeAccountDetailsHandler: changeAccountDetails
}, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ProfileView);
