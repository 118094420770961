import React from "react";
import {Redirect} from "react-router-dom";
import {Container} from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import ArtistForm from "../ArtistForm/ArtistForm";
import {compose} from "redux";
import WithLoading from "../Reusable/WithLoading/WithLoading";
import WithErrorHandling from "../Reusable/WithErrorHandling/WithErrorHandling";
import {FormattedMessage} from "react-intl";

const ComposedArtistForm = compose(
    WithLoading,
    WithErrorHandling
)(ArtistForm);

class ArtistEditionView extends React.Component
{
    constructor(props)
    {
        super(props);

        this.state = {
            actionComplete: false,
        };
    }

    editArtist = () =>
    {

        const { editArtist, artistDTO } = this.props;

        editArtist(artistDTO);

        this.setState({
            actionComplete: true
        })
    }

    renderRedirect = () =>
    {
        if (this.state.actionComplete)
        {
            const route = '/artists/list';

            return <Redirect to={route} />
        }
    }

    goBack = () =>
    {
        window.history.back();
    };

    render()
    {
        const { artistDTO, artistPending, artistError } = this.props;

        return (
            <Container maxWidth="lg">

                {this.renderRedirect()}

                <Typography component="h2" variant="h6" gutterBottom>
                    <FormattedMessage id="app.artistedition.title" />
                </Typography>

                <ComposedArtistForm
                    isLoading={artistPending}
                    error={artistError}
                    artist={artistDTO}
                    onSubmit={this.editArtist}
                    onCancel={this.goBack}
                />
            </Container>
        );
    }

}

export default ArtistEditionView;