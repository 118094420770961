
import React from 'react';
import ArtistAvatar from '../ArtistAvatar/ArtistAvatar';
import Typography from '@material-ui/core/Typography';

class ArtistView extends React.Component {
  render () {
    const artist = this.props.model;

    return (
            <div>
                <ArtistAvatar
                    image={artist.image}
                    size="100px"
                />
                <Typography component="h2" variant="h6" gutterBottom>
                    { artist.name }
                </Typography>
            </div>
    );
  }
}

export default ArtistView;
