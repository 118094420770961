import { combineReducers } from "redux";

import instrumentsTypesReducer from './instrumentsTypesReducer';
import artistsReducer from './artistsReducer';
import songsReducer from './songsReducer';
import strengthenSongsReducer from "./strengthenSongsReducer";
import sessionReducer from "./sessionReducer";
import userPreferencesReducer from "./userPreferencesReducer";
import statsReducer from "./statsReducer";
import appReducer from "./appReducer.js"

import {flashMessage, FlashMessageStore} from 'redux-flash-messages';
import {LOGOUT_SUCCESS} from "../actions/sessionActions";

export interface Store {
    flashMessage: FlashMessageStore;
}

const rootReducer = combineReducers({
    instrumentsTypes: instrumentsTypesReducer,
    artists: artistsReducer,
    strengthenSongs: strengthenSongsReducer,
    songs: songsReducer,
    session: sessionReducer,
    stats: statsReducer,
    userPreferences: userPreferencesReducer,
    app: appReducer,
    flashMessage
});

export default (state, action) => rootReducer(action.type === LOGOUT_SUCCESS ? undefined : state, action);
/*
const rootReducerWorkaround = (state, action) => rootReducer(action.type === LOGOUT_SUCCESS ? undefined : state, action);
export default rootReducerWorkaround;
*/
