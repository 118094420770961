import React from 'react';
import { makeStyles } from '@material-ui/core/styles/index';

const useStyles = makeStyles(theme => ({
  appBarSpacer: theme.mixins.toolbar
}));

function DashboardAppBarSpacer (props) {
  const classes = useStyles();
  return <div className={classes.appBarSpacer}/>;
}

export default DashboardAppBarSpacer;
