import {
  SUBSECTION_TYPES_PHRASES, SUBSECTION_TYPES_REPEAT
} from '../../components/SongForm/DTO/Song/Section/SubSection/SongSubSectionFormDTOBuilder';
import { SectionFormDTOBuilder } from '../../components/SongForm/DTO/Song/Section/SectionFormDTOBuilder';

export function getSongStateObjectFromEntity (songEntity) {
  const { artist, title, sections, instrumentType, videoId, duration, tempo } = songEntity;
  return {
    id: songEntity.id,
    artistId: artist.id,
    title,
    sections,
    instrumentTypeId: instrumentType.id,
    videoId,
    duration,
    tempo
  };
}

export function getSongEntityFromDTO (songDTO, uid, firestore) {
  const { title, sections, instrumentType, artistId, videoId, duration, tempo } = songDTO;

  const artistRef = firestore.doc('/artists/' + artistId);
  const instrumentTypeRef = firestore.doc('/instruments/' + instrumentType.id);

  const jsonSections = [];
  sections.forEach(function (section) {
    jsonSections.push(getSectionEntityFromDTO(section));
  });

  return {
    title,
    instrumentType: instrumentTypeRef,
    videoId,
    artist: artistRef,
    owner: uid,
    duration,
    tempo,
    sections: jsonSections
  };
}

function getSectionEntityFromDTO (sectionDTO) {
  const { instrument, tags, sectionKey, subSections } = sectionDTO;

  const sectionEntity = {
    instrument,
    tags,
    sectionKey
  };

  const jsonSubSections = [];
  subSections.forEach(function (subSection) {
    if (subSection.type === SUBSECTION_TYPES_REPEAT) {
      jsonSubSections.push(getRepeatSubSectionEntityFromDTO(sectionDTO, subSection));
    } else if (subSection.type === SUBSECTION_TYPES_PHRASES) {
      jsonSubSections.push(getPhrasesSubSectionEntityFromDTO(subSection));
    }
  });
  sectionEntity.subSections = jsonSubSections;

  if (sectionDTO.hasOwnProperty('capo')) {
    sectionEntity.capo = sectionDTO.capo;
  }

  return sectionEntity;
}

function getRepeatSubSectionEntityFromDTO (sectionOwner, subSectionDto) {
  const { type, subSectionToRepeat, startingTime, endingTime } = subSectionDto;

  return {
    type,
    songSubSectionKey: SectionFormDTOBuilder.getKeyOfSubSection(sectionOwner, subSectionToRepeat),
    startingTime,
    endingTime
  };
}

function getPhrasesSubSectionEntityFromDTO (subSectionDto) {
  const { type, description, phrases, startingTime, endingTime } = subSectionDto;

  const jsonPhrases = [];
  phrases.forEach(function (phrase) {
    jsonPhrases.push(getPhrasesEntityFromDTO(phrase));
  });

  return {
    type,
    description,
    phrases: jsonPhrases,
    startingTime,
    endingTime
  };
}

function getPhrasesEntityFromDTO (phraseDto) {
  const { description, help } = phraseDto;

  return {
    description,
    help
  };
}
