import React from 'react';

import Typography from '@material-ui/core/Typography';
import { FormattedMessage, injectIntl } from 'react-intl';
import Chart from 'react-google-charts';
import PropTypes from 'prop-types';
import { Box } from '@material-ui/core';
import { withTheme } from '@material-ui/core/styles';

class MonthlyStats extends React.Component {
  render () {
    const { intl, stats, theme } = this.props;

    let data = [['empty', 'empty'], [0, 0]];

    if (stats.length > 0) {
      data = [
        ['Month', intl.formatMessage({ id: 'app.stats.reproductions' })],
        ...stats
      ];
    }

    const chartTextStyle = { color: '#FFF' };
    return (
      <div>
        <Typography variant="h5" color={'secondary'}>
          <FormattedMessage id="app.stats.title"/>
        </Typography>

        {stats.length === 0 && (
          <Box style={{ marginTop: 10 }}>
            <Typography variant="body1">
              <FormattedMessage id="app.common.noResult"/>
            </Typography>
          </Box>
        )}

        {stats.length > 0 && (
          <Chart
            width={'100%'}
            height={'300px'}
            chartType="ComboChart"
            loader={<div>{intl.formatMessage({ id: 'app.common.loading' })}</div>}
            data={data}
            options={{
              vAxis: { textStyle: chartTextStyle, titleTextStyle: chartTextStyle },
              hAxis: { textStyle: chartTextStyle, titleTextStyle: chartTextStyle },
              legend: { textStyle: chartTextStyle, position: 'top', alignment: 'end' },
              seriesType: 'bars',
              series: { 5: { type: 'line' } },
              backgroundColor: { fill: 'transparent' },
              titleTextStyle: chartTextStyle,
              colors: [theme.palette.tertiary]
            }}
          />
        )}
      </div>
    );
  }
}

MonthlyStats.propTypes = {
  stats: PropTypes.array.isRequired
};

export default injectIntl(withTheme(MonthlyStats));
