
import { connect } from 'react-redux';

import { getArtists, getArtistsError, getArtistsPending } from '../store/reducers/artistsReducer';
import ArtistsView from '../components/ArtistsView/ArtistsView';

const mapStateToProps = (state) => {
  return {
    error: getArtistsError(state),
    artists: getArtists(state),
    pending: getArtistsPending(state)
  };
};

export default connect(
  mapStateToProps
)(ArtistsView);
