import { PhrasesSongSubSection } from '../../DTO/Song/Section/SubSection/SongSubSection';
import {
  ChordProParser,
  ChordSheetParser
} from './SongParser';
import Chord from 'chordjs';
import { INSTRUMENT_TYPE_RELATED_TOOL } from '../../../../store/entities/instrumentTypes';
import { ChordNotationFormatter } from './ChordNotationFormatter';

const SONG_FORMATS = {
  REGULAR_CHORD_SHEET: 'REGULAR_CHORD_SHEET',
  CHORD_PRO: 'CHORD_PRO'
};

export class ChordNotationProcessor {
  process (songDTO, tools) {
    const parser = this.__getInnerParser(this.__detectSongFormat(songDTO));
    const formatter = new ChordNotationFormatter();

    for (const section of songDTO.getSections()) {
      if (tools[INSTRUMENT_TYPE_RELATED_TOOL.TRANSPOSE]) {
        // transpose section key
        let parsedSectionKey = Chord.parse(section.sectionKey);
        parsedSectionKey = parsedSectionKey.transpose(tools[INSTRUMENT_TYPE_RELATED_TOOL.TRANSPOSE].offset);
        section.sectionKey = parsedSectionKey.toString();
      }

      for (const subSection of section.getSubSections()) {
        if (subSection instanceof PhrasesSongSubSection) {
          for (const phrase of subSection.getPhrases()) {
            // the phrase is overwritten with what is returned by chordjs
            const parsedDescription = parser.parse(phrase.getDescription());

            if (tools[INSTRUMENT_TYPE_RELATED_TOOL.TRANSPOSE]) {
              this.transpose(parsedDescription, tools[INSTRUMENT_TYPE_RELATED_TOOL.TRANSPOSE].offset);
            }

            const description = formatter.format(parsedDescription);
            phrase.setDescription(description);
          }
        }
      }
    }
  }

  transpose (parsedSong, count) {
    if (typeof parsedSong === 'string') {
      // if parsedSong is a string, it's because chordjs couldn't parse it
      return;
    }
    parsedSong.lines.forEach(line => {
      line.items.forEach(item => {
        let chord = Chord.parse(item.chords);
        if (chord) {
          chord = chord.transpose(count);
          item.chords = chord;
        }
      });
    });
  }

  __getInnerParser (songFormat) {
    const parsers = [];
    parsers[SONG_FORMATS.REGULAR_CHORD_SHEET] = ChordSheetParser;
    parsers[SONG_FORMATS.CHORD_PRO] = ChordProParser;

    return new parsers[songFormat]();
  }

  __detectSongFormat (songDTO) {
    let count = 0;
    /**
         * If we find at least 3 pairs of [ ] in the song it is assumed that the format is ChordPro
         * Otherwise the format is Regular chord sheets
         * For more information visit: https://github.com/martijnversluis/ChordSheetJS
         * */
    for (const section of songDTO.getSections()) {
      for (const subSection of section.getSubSections()) {
        if (subSection instanceof PhrasesSongSubSection) {
          for (const phrase of subSection.getPhrases()) {
            count += (phrase.getDescription().match(/\[.*?\]/g) || []).length;
            if (count >= 3) {
              return SONG_FORMATS.CHORD_PRO;
            }
          }
        }
      }
    }
    return SONG_FORMATS.REGULAR_CHORD_SHEET;
  }
}
