import React from 'react';
import {Box} from "@material-ui/core";
import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";
import FormControl from '@material-ui/core/FormControl';
import InputLabel from "@material-ui/core/InputLabel";
import Input from "@material-ui/core/Input";
import InputAdornment from "@material-ui/core/InputAdornment";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import TextField from "@material-ui/core/TextField";
import Paper from '@material-ui/core/Paper';
import Grid from "@material-ui/core/Grid";
import {FormattedMessage} from "react-intl";
import {injectIntl} from "react-intl";
import Typography from "@material-ui/core/Typography";
import ImageIcon from '@material-ui/icons/Image';
import {getYouTubeAPIClient} from "../../YouTubeAPIClient";
import PropTypes from "prop-types";
import ArtistAvatar from "../ArtistAvatar/ArtistAvatar";

class YouTubeAvatarPicker extends React.Component
{
    constructor(props)
    {
        super(props);

        this.state = {
            open: false,
            avatarURL: props.value,
            avatarURLError: false,
            relatedAvatarsURL: []
        };
    }

    matchUrl(url)
    {
        let p = /(http|ftp|https):\/\/[\w-]+(\.[\w-]+)+([\w.,@?^=%&amp;:~+#-]*[\w@?^=%&amp;~+#-])?/;
        return url.match(p);
    }

    onChangeAvatarURL = (event) =>
    {
        let value = event.target.value;

        this.setAvatarURL(value);

        this.setState({
            avatarURLError: ! this.matchUrl(value)
        });
    }

    setAvatarURL = avatarURL => {
        this.setState({
            avatarURL: avatarURL,
            avatarURLError: false
        });
    }

    handleAccept = () =>
    {
        let { avatarURL } = this.state;

        let valid = true;

        if ( avatarURL === "" )
        {
            valid = false;
            this.setState({
                avatarURLError: true
            });
        }

        if(valid)
        {
            if( this.props.onChange )
            {
                this.props.onChange(avatarURL);
            }
            this.setState({
                avatarURLError: false,
            });
            this.handleClose();
        }
    }

    handleOpen = () =>
    {
        this.retrieveRelatedAvatars();

        this.setState({
            open: true
        });
    }

    handleClose = () =>
    {
        this.setState({
            open: false
        });
    }

    retrieveRelatedAvatars = () =>
    {
        const { relatedTerms } = this.props;

        const youtubeAPIClient = getYouTubeAPIClient();
        youtubeAPIClient.searchChannels(relatedTerms, 6)
            .then(results => {
                const avatarsURL = results.map( (channel) => channel.raw.snippet.thumbnails.high.url );

                this.setState({
                    relatedAvatarsURL: avatarsURL
                });
            });
    }

    render()
    {
        let { value, relatedTerms, intl } = this.props;
        let { relatedAvatarsURL } = this.state;

        let required = this.props.hasOwnProperty("required") ? this.props.required : false;
        let error = this.props.hasOwnProperty("error") ? this.props.error : false;
        let margin = this.props.hasOwnProperty("margin") ? this.props.margin : 'none';

        let self = this;

        return <FormControl margin={margin}>
                    <InputLabel required={required} error={error}>
                        {this.props.label}
                    </InputLabel>
                    <Input
                        disabled
                        error={error}
                        value={value}
                        endAdornment={
                            <InputAdornment position="end">
                                <IconButton
                                    aria-label="artist avatar"
                                    onClick={this.handleOpen}
                                >
                                    <ImageIcon />
                                </IconButton>
                            </InputAdornment>
                        }
                    />

                    <Dialog
                        open={this.state.open}
                        onClose={this.handleClose}
                        aria-labelledby="form-dialog-title"
                        maxWidth="md"
                        fullWidth={true}
                    >
                        <DialogTitle id="form-dialog-title">
                            {this.props.label}
                        </DialogTitle>
                        <DialogContent dividers>
                            <Grid container spacing={3}>
                                <Grid item xs={8}>
                                    <TextField
                                        fullWidth={true}
                                        autoFocus
                                        label={intl.formatMessage({id: 'app.artistform.widget.youTubeAvatarPicker.imagePath'})}
                                        required={required}
                                        error={this.state.avatarURLError}
                                        value={this.state.avatarURL}
                                        onChange={this.onChangeAvatarURL}
                                    />
                                </Grid>
                                <Grid item xs={4}>
                                    { this.state.avatarURL !=='' && (
                                        <div>
                                            <ArtistAvatar
                                                image={this.state.avatarURL}
                                                size={"100px"}
                                            />
                                        </div>
                                    )}
                                </Grid>
                            </Grid>
                            <Paper elevation={2} variant={"elevation"}>
                                <Box m={2}>
                                    <Typography component="h2" variant="h6" gutterBottom>
                                        <FormattedMessage id="app.artistform.widget.youTubeAvatarPicker.relatedAvatarsWithATerm" values={{ relatedTerms: relatedTerms}}/>
                                    </Typography>
                                    <Grid container spacing={3}>
                                        { relatedAvatarsURL.map( (avatarURL, key) =>
                                            <Grid item xs={2} key={key}>
                                                <Box display="flex" justifyContent="center">
                                                    <ArtistAvatar
                                                        image={avatarURL}
                                                        size={"100px"}
                                                    />
                                                </Box>
                                                <Box display="flex" justifyContent="center">
                                                    <Button variant="outlined" color="secondary" onClick={ () => self.setAvatarURL(avatarURL) }>
                                                        <FormattedMessage id="app.common.select" />
                                                    </Button>
                                                </Box>
                                            </Grid>
                                        )}
                                    </Grid>
                                </Box>
                            </Paper>
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={this.handleClose} color="secondary">
                                <FormattedMessage id="app.common.cancel" />
                            </Button>
                            <Button onClick={this.handleAccept} color="secondary">
                                <FormattedMessage id="app.common.ok" />
                            </Button>
                        </DialogActions>
                    </Dialog>
                </FormControl>;
    }
}

YouTubeAvatarPicker.propTypes = {
    label: PropTypes.string.isRequired,
    value : PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
    relatedTerms: PropTypes.string.isRequired,
    required: PropTypes.bool,
    error: PropTypes.bool,
    margin: PropTypes.string
};

export default injectIntl(YouTubeAvatarPicker);