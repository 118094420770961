import React from "react";

import { ReCaptcha } from 'react-recaptcha-v3'
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import PersonIcon from '@material-ui/icons/Person';
import AlternateEmailIcon from '@material-ui/icons/AlternateEmail';
import Button from '@material-ui/core/Button';
import Grid from "@material-ui/core/Grid";
import { FormattedMessage, injectIntl } from "react-intl";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Input from "@material-ui/core/Input";
import InputAdornment from "@material-ui/core/InputAdornment";
import IconButton from "@material-ui/core/IconButton";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogActions from "@material-ui/core/DialogActions";
import { GOOGLE_RECAPTCHA_KEY_SITE } from "config";


class SignupForm extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      formModel: {
        displayName: '',
        email: '',
        password: '',
        passwordConfirmation: '',
      },
      showAlertDialog: false,
      showRecaptcha: false,
      alertDialogMessage: '',
      showPassword: {
        password: false,
        passwordConfirmation: false
      }
    };
  }

  verifyCallbackAndSubmit = (recaptchaToken) => {

    // Here you will get the final recaptchaToken!!!
    let {displayName, email, password} = this.state.formModel;
    this.props.onSubmit(displayName, email, password, recaptchaToken);

    this.setState({showRecaptcha: false});
  }

  changeFormHandler = event => {

    const name = event.target.name;
    const value = event.target.value;

    let formModel = this.state.formModel;
    formModel[name] = value;

    this.setState({
      formModel: formModel
    });
  }

  handleMouseDownPassword = (event) => {
    let currentShowPasswordState = this.state.showPassword;
    currentShowPasswordState[event.currentTarget.name] = !currentShowPasswordState[event.currentTarget.name];
    this.setState(
      {
        showPassword: currentShowPasswordState
      }
    )
  }

  handleCloseAlertDialog = () => {
    this.setState({showAlertDialog: false});
  }

  handleSubmit = (event) => {
    event.preventDefault();

    const {intl} = this.props;
    let {displayName, email, password, passwordConfirmation} = this.state.formModel;

    displayName = displayName.trim();
    email = email.trim();
    password = password.trim();
    passwordConfirmation = passwordConfirmation.trim();

    if (displayName === '' || email === '' || password === '') {
      this.setState({
        showAlertDialog: true,
        alertDialogMessage: intl.formatMessage({id: 'app.common.incompleteFormWarning'})
      });
      return;
    }

    if (password !== passwordConfirmation) {
      this.setState({
        showAlertDialog: true,
        alertDialogMessage: intl.formatMessage({id: 'app.profile.changePassword.form.passwordDoNotMatch'})
      });
      return;
    }

    this.setState({showRecaptcha: true});
  }

  render() {

    const {formModel, showPassword, showAlertDialog, showRecaptcha, alertDialogMessage} = this.state;
    const {intl, submitButtonDisabled} = this.props;

    if (formModel) {
      return (
        <Card>
          <CardContent>
            <form>
              <Grid>
                <Grid item xs>
                  <FormControl>
                    <InputLabel htmlFor="displayName">
                      <FormattedMessage id="app.signup.form.displayName"/>
                    </InputLabel>
                    <Input
                      type={'text'}
                      name="displayName"
                      value={formModel.displayName}
                      onChange={this.changeFormHandler}
                      required
                      endAdornment={
                        <InputAdornment position="end">
                          <PersonIcon/>
                        </InputAdornment>
                      }
                    />
                  </FormControl>
                </Grid>
                <Grid item xs>
                  <FormControl>
                    <InputLabel htmlFor="email">
                      <FormattedMessage id="app.signup.form.email"/>
                    </InputLabel>
                    <Input
                      type={'text'}
                      name="email"
                      value={formModel.email}
                      onChange={this.changeFormHandler}
                      required
                      endAdornment={
                        <InputAdornment position="end">
                          <AlternateEmailIcon/>
                        </InputAdornment>
                      }
                    />
                  </FormControl>
                </Grid>
                <Grid item xs>
                  <FormControl>
                    <InputLabel htmlFor="password-1">
                      <FormattedMessage id="app.signup.form.password"/>
                    </InputLabel>
                    <Input
                      id="password-1"
                      type={showPassword.password ? 'text' : 'password'}
                      name="password"
                      value={formModel.password}
                      onChange={this.changeFormHandler}
                      required
                      endAdornment={
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            name="password"
                            onMouseDown={this.handleMouseDownPassword}
                            style={{padding: 0}}
                          >
                            {showPassword.password ? <Visibility/> : <VisibilityOff/>}
                          </IconButton>
                        </InputAdornment>
                      }
                    />
                  </FormControl>
                </Grid>
                <Grid item xs>
                  <FormControl>
                    <InputLabel htmlFor="password-2">
                      <FormattedMessage id="app.signup.form.passwordConfirmation"/>
                    </InputLabel>
                    <Input
                      id="password-2"
                      type={showPassword.passwordConfirmation ? 'text' : 'password'}
                      name="passwordConfirmation"
                      value={formModel.passwordConfirmation}
                      onChange={this.changeFormHandler}
                      required
                      endAdornment={
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            name="passwordConfirmation"
                            onMouseDown={this.handleMouseDownPassword}
                            style={{padding: 0}}
                          >
                            {showPassword.passwordConfirmation ? <Visibility/> :
                              <VisibilityOff/>}
                          </IconButton>
                        </InputAdornment>
                      }
                    />
                  </FormControl>
                </Grid>
              </Grid>
              <Dialog
                open={showAlertDialog}
                keepMounted
                onClose={this.handleCloseAlertDialog}
                aria-labelledby="alert-dialog-slide-title"
                aria-describedby="alert-dialog-slide-description"
              >
                <DialogTitle
                  id="alert-dialog-slide-title">{intl.formatMessage({id: 'app.common.warning'})}</DialogTitle>
                <DialogContent>
                  <DialogContentText id="alert-dialog-slide-description">
                    {alertDialogMessage}
                  </DialogContentText>
                </DialogContent>
                <DialogActions>
                  <Button onClick={this.handleCloseAlertDialog} color="secondary">
                    {intl.formatMessage({id: 'app.common.ok'})}
                  </Button>
                </DialogActions>
              </Dialog>
            </form>
          </CardContent>
          <CardActions>
            {
              showRecaptcha && (
                <ReCaptcha
                  ref={ref => this.recaptcha = ref}
                  sitekey={GOOGLE_RECAPTCHA_KEY_SITE}
                  action='signup'
                  verifyCallback={this.verifyCallbackAndSubmit}
                />
              )
            }
            <Button size="small" onClick={this.handleSubmit} color="secondary"
                    disabled={submitButtonDisabled}>
              <FormattedMessage id="app.common.ok"/>
            </Button>
          </CardActions>
        </Card>
      );
    }
    return null;
  }
}

export default injectIntl(SignupForm);
