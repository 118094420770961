import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { startSongReproduction, stopSongReproduction } from '../store/actions/songsActions';
import { getSong, getSongsError, getSongsPending } from '../store/reducers/songsReducer';

import ReproductionView from '../components/ReproductionView/ReproductionView';
import { getTrainingMode } from '../store/reducers/userPreferencesReducer';
import { setTrainingMode } from '../store/actions/userPreferencesActions';

function mapStateToProps (state, ownProps) {
  const { match: { params } } = ownProps;
  return {
    songError: getSongsError(state),
    songPending: getSongsPending(state),
    trainingMode: getTrainingMode(state),
    songInfo: getSong(state, params.id)
  };
}

const mapDispatchToProps = dispatch => bindActionCreators(
  {
    startSongReproduction,
    stopSongReproduction,
    setTrainingMode
  },
  dispatch
);

const ReproductionContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(ReproductionView);

export default ReproductionContainer;
