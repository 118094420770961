import React, {PureComponent} from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { removeFlashMessage, FlashMessage as FlashMessageShape } from 'redux-flash-messages';

import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import {FormattedMessage} from "react-intl";
import {getFlashMessageSelector} from "../store/reducers/appReducer";

class FlashMessage extends PureComponent<Props, {}> {

    onFlashMessageClick(flashMessage: FlashMessageShape) {
        /*
          Make sure the onClick is called when a user clicks
          on the flash message.

          Otherwise callbacks on Flash Messages will not work.
        */
        if( typeof flashMessage.onClick === "function" ){
            flashMessage.onClick(flashMessage);
        }
        // This implementation deletes the flash message when it is clicked.
        removeFlashMessage(flashMessage.id)
    }

    render() {
        const messages = this.props.messages;

        if( messages == null ) return (<div></div>);

        return (
            <div>
                { messages.map((message) => this.renderMessage(message))}
            </div>
        );
    }

    /*
      This renders a rather simple flash message.

      But you could and should use the 'type' property to
      render the flash message in a different style for each 'type'.
    */
    renderMessage(message: FlashMessageShape) {

        if( message.type === "APOCALYPSE" ){
            // message type not supported
            return (<div></div>);
        }

        const severity = message.type.toLowerCase();
        const onFlashMessageClick = () => this.onFlashMessageClick(message);

        return (
            <Snackbar key={ message.id } open={true} autoHideDuration={6000} onClick={onFlashMessageClick}>
                <MuiAlert
                    elevation={6}
                    variant="filled"
                    onClose={this.handleSnackBarClose}
                    severity={severity}
                >
                    <FormattedMessage id={ message.text } />
                </MuiAlert>
            </Snackbar>
        );
    }
}

function mapStateToProps(state, ownProps){
    return {
        messages: getFlashMessageSelector(state),
    }
}

const mapDispatchToProps = dispatch => bindActionCreators({
    removeFlashMessage: removeFlashMessage
}, dispatch);

export default connect(mapStateToProps,mapDispatchToProps)(FlashMessage);