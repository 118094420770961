
import React from 'react';

import CustomSelect from './CustomSelect';

class SectionKeySelect extends React.Component {
  constructor (props) {
    super(props);

    let keys = [
      'C', 'Db', 'D', 'Eb', 'E', 'F', 'Gb', 'G', 'Ab', 'A', 'Bb', 'B',
      'Cm', 'Dbm', 'Dm', 'Ebm', 'Em', 'Fm', 'Gbm', 'Gm', 'Abm', 'Am', 'Bbm', 'Bm'
    ];
    keys = keys.map(function (obj) {
      return { key: obj, value: obj };
    });
    this.state = {
      keys
    };
  }

  render () {
    const error = (this.props.hasOwnProperty('error')) ? this.props.error : false;
    return (
            <CustomSelect
                label={this.props.label}
                name={this.props.name}
                id={this.props.id}
                error={error}
                options={this.state.keys}
                value={this.props.value}
                margin={this.props.margin}
                InputLabelProps={this.props.InputLabelProps}
                style={this.props.style}
                onChange={this.props.onChange}
                required={this.props.required}
            />
    );
  }
}

export default SectionKeySelect;
