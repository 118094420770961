import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { editArtist } from '../store/actions/artistsActions';
import {
  getArtistById,
  getArtists,
  getArtistsError,
  getArtistsPending
} from '../store/reducers/artistsReducer';
import ArtistEditionView from '../components/ArtistEditionView/ArtistEditionView';
import { ArtistDTO } from '../components/ArtistForm/DTO/ArtistDTO';

function mapStateToProps (state, ownProps) {
  const { match: { params } } = ownProps;

  const artist = getArtistById(getArtists(state), params.id);

  const artistDTO = new ArtistDTO(artist.id, artist.name, artist.image);

  return {
    artistDTO,
    artistPending: getArtistsPending(state),
    artistError: getArtistsError(state)
  };
}

const mapDispatchToProps = dispatch => bindActionCreators({
  editArtist
}, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ArtistEditionView);
