import firebase from '../../Firestore';
import { fetchArtists } from './artistsActions';
import { fetchSongs } from './songsActions';
import { addError, addSuccess } from 'redux-flash-messages/lib';
import { fetchInstrumentsTypes } from './instrumentsTypesActions';
import { API_URL } from 'config';

export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const CHANGE_ACCOUNT_DETAILS_SUCCESS = 'CHANGE_ACCOUNT_DETAILS_SUCCESS';
export const LOGOUT_SUCCESS = 'LOGOUT_SUCCESS';

function loginSuccess (uid, displayName, email, emailVerified) {
  return {
    type: LOGIN_SUCCESS,
    uid,
    displayName,
    email,
    emailVerified
  };
}

function logoutSuccess () {
  return {
    type: LOGOUT_SUCCESS
  };
}

function changeAccountDetailsSuccess (displayName) {
  return {
    type: CHANGE_ACCOUNT_DETAILS_SUCCESS,
    displayName
  };
}

export const signup = (displayName, email, password, recaptchaToken) => (dispatch) => {
  return new Promise(function (resolve, reject) {
    const data = {
      displayName,
      email,
      password,
      recaptchaToken
    };
    fetch(API_URL + '/users/', {
      method: 'POST',
      body: JSON.stringify(data),
      headers: {
        'Content-Type': 'application/json'
      }
    })
      .then(res => {
        if (res.status === 200) {
          dispatch(login(email, password)).then(function () {
            firebase.auth().currentUser.sendEmailVerification()
              .then(function () {
                addSuccess({ text: 'app.actions.session.signup.success' });
              })
              .catch(function (error) {
                console.log('error al enviar un email para verificar el email');
              });
          });
        } else {
          let errorMessage = '';
          switch (res.status) {
          case 422:
            errorMessage = 'app.actions.session.signup.failure.passwordIsWeak';
            break;
          case 409:
            errorMessage = 'app.actions.session.signup.failure.emailInUse';
            break;
          case 401:
            errorMessage = 'app.actions.session.signup.failure.recaptchaValidation';
            break;
          case 400:
          default:
            errorMessage = 'app.common.messages.badRequest';
            break;
          }
          addError({ text: errorMessage });
          reject(errorMessage);
        }
      })
      .catch(error => {
        console.error('Error:', error);
        reject(Error('It broke'));
      });
  });
};

export function login (userName, password) {
  return (dispatch, getState, { services }) => {
    return firebase.auth()
      .signInWithEmailAndPassword(userName, password)
      .then((response) => {

        // If you need to do anything with the user, do it here
        // The user will be logged in automatically by the
        // `onAuthStateChanged` listener we set up in App.js earlier
      })
      .catch((error) => {
        const { code, message } = error;
        // For details of error codes, see the docs
        // The message contains the default Firebase string
        // representation of the error
        switch (code) {
        case 'auth/wrong-password':
          addError({ text: 'app.session.wrongPassword' });
          break;
        case 'auth/user-not-found':
          addError({ text: 'app.session.userNotFound' });
          break;
        case 'auth/user-disabled':
          // TODO crear cuenta de email de soporte y asociar en las traducciones
          addError({ text: 'app.session.userDisabled' });
          break;
        default:
          console.log(code + ' - ' + message);
        }
      });
  };
}

export function resendEmailVerification () {
  return (dispatch, getState, { services }) => {
    return firebase.auth().currentUser.sendEmailVerification()
      .then(function () {
        addSuccess({ text: 'app.actions.session.resendEmailVerification.success' });
      });
  };
}

export function sendPasswordResetEmail (emailAddress) {
  return (dispatch, getState, { services }) => {
    return firebase.auth().sendPasswordResetEmail(emailAddress).then(function () {
      addSuccess({ text: 'app.actions.session.resetPassword.success' });
    }).catch(function (error) {
      addError({ text: 'app.actions.session.resetPassword.failure' });
    });
  };
}

export function observeAuthStateChange () {
  return (dispatch, getState, { services }) => {
    firebase.auth().onAuthStateChanged(function (user) {
      if (user === null) {
        dispatch(logoutSuccess());
      } else {
        const { uid, displayName, email, emailVerified } = user;
        user.getIdToken(true).then(function (idToken) {
          dispatch(loginSuccess(uid, displayName, email, emailVerified));
          dispatch(fetchSessionData());
        });
      }
    });
  };
}

export function logout () {
  return (dispatch, getState, { services }) => {
    firebase.auth().signOut();

    dispatch(logoutSuccess());
  };
}

export function changePassword (currentPassword, newPassword, onSuccessHandler) {
  return (dispatch) => {
    const user = firebase.auth().currentUser;
    const credential = firebase.auth.EmailAuthProvider.credential(
      user.email,
      currentPassword
    );
    // Prompt the user to re-provide their sign-in credentials
    user.reauthenticateWithCredential(credential).then(function () {
      // User re-authenticated.
      user.updatePassword(newPassword).then(function () {
        addSuccess({ text: 'app.actions.session.changePassword.success' });
        onSuccessHandler();
      }).catch(function (error) {
        if (error.code === 'auth/weak-password') {
          addError({ text: 'app.actions.session.changePassword.failure.newPasswordIsWeak' });
        }
        console.log(error);
      });
    }).catch(function (error) {
      addError({ text: 'app.actions.session.changePassword.failure.currentPasswordIsInvalid' });
    });
  };
}

export function changeAccountDetails (displayName) {
  return (dispatch) => {
    const user = firebase.auth().currentUser;
    user.updateProfile({
      displayName
    }).then(function () {
      addSuccess({ text: 'app.actions.session.changeAccountDetails.success' });
      dispatch(changeAccountDetailsSuccess(displayName));
    }).catch(function (error) {
    });
  };
}

const fetchSessionData = () => (dispatch) => {
  dispatch(fetchInstrumentsTypes()).then(() => {
    // Al iniciar sesión se obtienen las canciones del usuario.
    // Para ese entonces tienen que existir tipos de instrumentos / instrumentos
    dispatch(fetchArtists()).then(() => {
      dispatch(fetchSongs());
    });
  });
};
