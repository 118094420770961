import React from 'react';
import { Box, Container } from '@material-ui/core';
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import { FormattedMessage } from 'react-intl';
import ChangePasswordForm from './ChangePasswordForm';
import styles from './ProfileView.module.css';
import PropTypes from 'prop-types';
import ChangeAccountDetailsForm from './ChangeAccountDetailsForm';

class ProfileView extends React.Component {
  render () {
    return (
            <Container maxWidth="lg">
                <Box className={styles.Box}>
                    <Typography component="h2" variant="h5" gutterBottom>
                        <FormattedMessage id="app.profile.title"/>
                    </Typography>
                    <Typography component="h3" variant="h6" gutterBottom align={'left'}>
                        <FormattedMessage id="app.profile.account.title"/>
                    </Typography>
                    <ChangeAccountDetailsForm
                        displayName={this.props.displayName}
                        onSubmit={this.props.changeAccountDetailsHandler}
                    />
                    <Typography component="h3" variant="h6" gutterBottom align={'left'}>
                        <FormattedMessage id="app.profile.changePassword.title"/>
                    </Typography>
                    <ChangePasswordForm
                        onSubmit={this.props.changePasswordHandler}
                    />
                </Box>
                <Box className={styles.Box}>
                    <Typography component="h3" variant="h6" gutterBottom align={'left'}>
                        <FormattedMessage id="app.profile.backup.title"/>
                    </Typography>
                    <Card>
                        <CardContent>
                            <Button
                                variant="contained"
                                color="primary"
                                size="large"
                                startIcon={<CloudDownloadIcon />}
                            >
                                <FormattedMessage id="app.profile.backup.actionButton"/>
                            </Button>
                        </CardContent>
                    </Card>
                </Box>
            </Container>
    );
  }
}
ProfileView.propTypes = {
  changePasswordHandler: PropTypes.func.isRequired,
  changeAccountDetailsHandler: PropTypes.func.isRequired
};
export default ProfileView;
