import { Phrase } from './Phrase';

class SongSubSection {
  constructor (sectionOwner, type, id, startingTime, endingTime) {
    this.sectionOwner = sectionOwner;
    this.type = type;
    this.id = id;
    this.startingTime = startingTime;
    this.endingTime = endingTime;
  }

  hasReferenceToSubSection (subSection) {
    return false;
  }

  equals (subSection) {
    return this.id === subSection.id;
  }

  hasStartingTime () {
    return this.startingTime !== null;
  }
}

export class PhrasesSongSubSection extends SongSubSection {
  constructor (sectionOwner, id, description, phrasesJson, startingTime, endingTime) {
    super(sectionOwner, 'phrases', id, startingTime, endingTime);
    this.description = description;
    this.phrases = [];

    const self = this;
    phrasesJson.forEach(function (element) {
      self.addPhrase(element.description, element.help);
    });
  }

  getPhrases () {
    return this.phrases;
  }

  setDescription (value) {
    this.description = value;
  }

  addPhrase (description = '', help = '') {
    const newID = Date.now() + Math.random();

    this.phrases.push(new Phrase(this, newID, description, help));
  }

  getPhrasesCount () {
    return this.phrases.length;
  }

  getPhrase (index) {
    return this.phrases[index];
  }
}

export class RepeatSongSubSection extends SongSubSection {
  constructor (sectionOwner, id, subSectionToRepeat, startingTime, endingTime) {
    super(sectionOwner, 'repeat', id, startingTime, endingTime);

    this.subSectionToRepeat = subSectionToRepeat;
  }

  hasReferenceToSubSection (subSection) {
    return this.subSectionToRepeat.equals(subSection);
  }
}
