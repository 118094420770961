import {compose} from "redux";
import WithLoading from "../Reusable/WithLoading/WithLoading";
import WithErrorHandling from "../Reusable/WithErrorHandling/WithErrorHandling";
import SongsFilterForm from "./SongsFilterForm";
import SongsList from "../SongsList/SongsList";
import React from "react";
import {Container, Grid} from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import {FormattedMessage} from "react-intl";
import {SongToDeleteDTO} from "../../store/actions/DTO/song/SongToDeleteDTO";
import {Link} from "react-router-dom";
import Button from "@material-ui/core/Button";
import AddIcon from "@material-ui/icons/Add";

const ComposedSongsFilterForm = compose(
    WithLoading,
    WithErrorHandling
)(SongsFilterForm);

const ComposedSongsList = compose(
    WithLoading,
    WithErrorHandling
)(SongsList);

class SongsListView extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            songsFilter: this.props.songsFilter,
        };
    }

    deleteSong = (song) => {
        let {deleteSong} = this.props;
        deleteSong(new SongToDeleteDTO(song.id));
    }

    filterSongs = () => {
        const {setSongsFilter} = this.props;
        const {songsFilter} = this.state;

        setSongsFilter(songsFilter);
    }

    render() {
        const {
            songsCount,
            filteredSongs,
            songsError,
            songsPending,
            usedTags,
            instrumentsTypes,
            artists
        } = this.props;

        const {songsFilter} = this.state;

        return (
            <Container style={{maxWidth: '100vw'}}>
                <Typography component="h2" variant="h5" gutterBottom>
                    <FormattedMessage id="app.songslist.title"/>
                </Typography>
                {
                    (filteredSongs.length <= songsCount) && (
                        <ComposedSongsFilterForm
                            isLoading={songsPending}
                            availableTags={usedTags}
                            availableInstrumentsTypes={instrumentsTypes}
                            artists={artists}
                            model={songsFilter}
                            onChange={this.filterSongs}
                        />
                    )
                }
                <Grid>
                    <Grid item xs={12} style={{textAlign: 'right', marginTop: 40}}>
                        <Link to={'/songs/creation/'} style={{textDecoration: 'none', marginBottom: 7}}>
                            <Button size="small">
                                <AddIcon/> <FormattedMessage id="app.songsfromartist.button.new"/>
                            </Button>
                        </Link>
                    </Grid>
                </Grid>
                <ComposedSongsList
                    isLoading={songsPending}
                    error={songsError}
                    songs={filteredSongs}
                    onSongDelete={this.deleteSong}
                    showArtist={true}
                    origin={'songs-list'}
                />
            </Container>
        );
    }
}

export default SongsListView;
