import {
  FETCH_INSTRUMENTS_TYPES_PENDING,
  FETCH_INSTRUMENTS_TYPES_SUCCESS,
  FETCH_INSTRUMENTS_TYPES_ERROR
} from '../actions/instrumentsTypesActions';

const initialState = {
  fetchPending: false,
  instruments: [],
  error: null
};

const instrumentsTypesReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_INSTRUMENTS_TYPES_PENDING:
      return {
        ...state,
        fetchPending: true
      };
    case FETCH_INSTRUMENTS_TYPES_SUCCESS:
      return {
        ...state,
        fetchPending: false,
        instruments: {
          ...action.instruments
        }
      };
    case FETCH_INSTRUMENTS_TYPES_ERROR:
      return {
        ...state,
        fetchPending: false,
        error: action.error
      };
    default:
      return state;
  }
};

export default instrumentsTypesReducer;

export const getInstrumentsTypes = (state) => state.instrumentsTypes.instruments;
export const getInstrumentsTypesFetched = (state) => Object.keys(getInstrumentsTypes(state)).length > 0;
export const getInstrumentTypeById = (instrumentTypes, instrumentTypeId) => instrumentTypes[instrumentTypeId];
