import React from 'react';

import SnackbarContent from '@material-ui/core/SnackbarContent';
import ErrorIcon from '@material-ui/icons/Error';
import { FormattedMessage } from 'react-intl';

class ErrorMessage extends React.Component {
  render () {
    const { messageId } = this.props;
    return (
            <div>
                <SnackbarContent
                    aria-describedby="client-snackbar"
                    style={{ backgroundColor: 'orange' }}
                    message={
                        <span id="client-snackbar" style={{ display: 'flex' }}>
                            <ErrorIcon style={{ fontSize: 20, marginRight: '3px' }}/>
                            <FormattedMessage id={messageId}/>
                        </span>
                    }
                />
            </div>
    );
  }
}

export default ErrorMessage;
