import React from 'react';
import PropTypes from 'prop-types';

import IconButton from '@material-ui/core/IconButton';
import AmpStoriesIcon from '@material-ui/icons/AmpStories';
import FormGroup from '@material-ui/core/FormGroup';
import { Badge } from '@material-ui/core';
import styled from 'styled-components';
import { withTheme } from '@material-ui/core/styles';

const CustomBadge = withTheme(styled(Badge)`
  .MuiBadge-badge{
    top: 6px;
    right: -46px;
    background-color: ${props => props.theme.palette.tertiary};    
  }
`);

const CustomIconButton = styled(IconButton)`
  margin-left: 8px;
`;

function CapoInput (props) {
  const { value, onClick } = props;
  return (
        <FormGroup row>
            <CustomBadge color="secondary" badgeContent={value}/>
            <CustomIconButton title={'Capo'}>
                <AmpStoriesIcon onClick={() => onClick(value)}/>
            </CustomIconButton>
        </FormGroup>
  );
}
CapoInput.propTypes = {
  value: PropTypes.number.isRequired,
  onClick: PropTypes.func.isRequired
};
export default CapoInput;
