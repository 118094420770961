const SharedStyles = theme => ({
  content: {
    flexGrow: 1,
    backgroundColor: theme.palette.primary.main,
    overflow: 'hidden'
  },
  container: {
    paddingTop: theme.spacing(4),
    paddingLeft: 0,
    paddingRight: 0,
    height: 'calc(100vh - 64px)', // se le resta el espacio del appbar
    overflow: 'auto'
  },
  container_fullscreen: {
    paddingTop: theme.spacing(4),
    paddingLeft: 0,
    paddingRight: 0,
    overflow: 'auto'
  }
});

export default SharedStyles;
