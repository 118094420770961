import React from 'react';
import Grid from '@material-ui/core/Grid';
import Chip from '@material-ui/core/Chip';
import Button from '@material-ui/core/Button';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container/index';
import styles from './LandingPageView.module.css';
import { FormattedMessage, injectIntl } from 'react-intl';

class LandingPageView extends React.Component {
  render () {
    const { intl } = this.props;
    return (
            <Container className={styles.LandingPageViewClass}>
                <Grid container spacing={0}
                      alignItems="center"
                      justify="center"
                >
                    <Grid item xs={12} sm={5} className={styles.GridItem}>
                        <Chip
                            size="small"
                            label="Easy. Portable. Fun"
                            className={styles.chip}
                        />
                        <Box my={5}>
                            <Typography variant="h4" color={'secondary'} align={'left'}>
                                Keep your songs with you with <FormattedMessage id="app.name"/>!
                            </Typography>
                        </Box>
                        <Box my={5}>
                            <Typography variant="h6" color={'textSecondary'} align={'left'}>
                                Play your songs wherever you are. Forget about printing sheet papers.
                            </Typography>
                        </Box>
                        <Box my={4}>
                            <Button
                                color="secondary"
                                href="home"
                                size={'large'}
                                variant={'contained'}
                                className={styles.ActionButton}
                            >
                                {intl.formatMessage({ id: 'app.session.login' })}
                            </Button>
                            <Button
                                color="secondary"
                                href="signup"
                                size={'large'}
                                variant={'outlined'}
                                className={styles.ActionButton}
                            >
                                {intl.formatMessage({ id: 'app.session.signup' })}
                            </Button>
                        </Box>
                    </Grid>
                    <Grid item xs={12} sm={7} className={styles.GridItem}>
                        <img
                            src={process.env.PUBLIC_URL + '/cap1.png'}
                            title="In action!"
                            alt="In action!"
                            width='100%'
                        />
                    </Grid>
                    <Grid item xs={12} sm={7} className={styles.GridItem}>
                        <img
                            src={process.env.PUBLIC_URL + '/cap2.png'}
                            title="Monthly stats!"
                            alt="Monthly stats!"
                            width='100%'
                        />
                    </Grid>
                    <Grid item xs={12} sm={5} className={styles.GridItem}>
                        <Box my={4}>
                            <Box my={2}>
                                <Typography variant="h3" color={'secondary'} align={'right'}>
                                    Monthly stats!
                                </Typography>
                            </Box>
                            <Typography variant="h5" color={'textSecondary'} align={'right'}>
                                Track your progress. Keep on practicing!
                            </Typography>
                        </Box>
                    </Grid>
                    <Grid item xs={12} sm={5} className={styles.GridItem}>
                        <Box my={2}>
                            <Typography variant="h3" color={'secondary'} align={'left'}>
                                Slow down your songs!
                            </Typography>
                        </Box>
                        <Box my={4}>
                            <Typography variant="h5" color={'textSecondary'} align={'left'}>
                                <FormattedMessage id="app.name"/> allows you to slow down your songs, so you can understand how they are played.
                            </Typography>
                        </Box>
                    </Grid>
                    <Grid item xs={12} sm={7} className={styles.GridItem}>
                        <img
                            src={process.env.PUBLIC_URL + '/cap3.png'}
                            title="Slow down your songs!"
                            alt="Slow down your songs!"
                            width='100%'
                        />
                    </Grid>
                    <Grid item xs={12} sm={7} className={styles.GridItem}>
                        <img
                            src={process.env.PUBLIC_URL + '/cap5.gif'}
                            title="Transpose your songs!"
                            alt="Transpose your songs!"
                            className={styles.ImgPractice}
                        />
                    </Grid>
                    <Grid item xs={12} sm={5} className={styles.GridItem}>
                        <Box my={2}>
                            <Typography variant="h3" color={'secondary'} align={'left'}>
                                Transposing is super easy!
                            </Typography>
                        </Box>
                        <Box my={4}>
                            <Typography variant="h5" color={'textSecondary'} align={'left'}>
                                Change the key of your songs in seconds.
                            </Typography>
                        </Box>
                    </Grid>
                    <Grid item xs={12} sm={5} className={styles.GridItem}>
                        <Box my={2}>
                            <Typography variant="h3" color={'secondary'} align={'left'}>
                                Practice old songs!
                            </Typography>
                        </Box>
                        <Box my={4}>
                            <Typography variant="h5" color={'textSecondary'} align={'left'}>
                                <FormattedMessage id="app.name"/> suggests songs to be up to date.
                            </Typography>
                        </Box>
                    </Grid>
                    <Grid item xs={12} sm={7} className={styles.GridItem}>
                        <img
                            src={process.env.PUBLIC_URL + '/cap4.png'}
                            title="Practice old songs!"
                            alt="Practice old songs!"
                            className={styles.ImgPractice}
                        />
                    </Grid>
                </Grid>
            </Container>
    );
  }
}

export default injectIntl(LandingPageView);
