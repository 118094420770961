import React from 'react';
import CustomSelect from './CustomSelect';

class ArtistsSelect extends React.Component {
  render () {
    const autoWidth = (this.props.hasOwnProperty('autoWidth')) ? this.props.autoWidth : true;

    const artists = this.props.artists;

    const artistsKeys = Object.keys(artists);
    artistsKeys.sort(function (a, b) {
      if (artists[a].name > artists[b].name) {
        return 1;
      }
      if (artists[a].name < artists[b].name) {
        return -1;
      }
      return 0;
    });

    const options = artistsKeys.map(function (artistKey) {
      return { key: artistKey, value: artists[artistKey].name };
    });

    return (
            <CustomSelect
                label={this.props.label}
                name={this.props.name}
                id={this.props.id}
                error={this.props.error}
                options={options}
                value={this.props.value}
                margin={this.props.margin}
                style={this.props.style}
                InputLabelProps={this.props.InputLabelProps}
                autoWidth={autoWidth}
                onChange={this.props.onChange}
                required={this.props.required}
            />
    );
  }
}

export default ArtistsSelect;
