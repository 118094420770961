import {compose} from "redux";
import WithLoading from "../../Reusable/WithLoading/WithLoading";
import WithErrorHandling from "../../Reusable/WithErrorHandling/WithErrorHandling";
import React from "react";
import MonthlyStats from "./MonthlyStats";

const ComposedMonthlyStats = compose(
    WithLoading,
    WithErrorHandling
)(MonthlyStats);

class MonthlyStatsSubView extends React.Component {
    componentDidMount = () => {
        const {fetchMonthlyStats} = this.props;
        fetchMonthlyStats();
    };

    render() {
        const {stats, error} = this.props;

        return (
            <ComposedMonthlyStats
                isLoading={false}
                error={error}
                stats={stats}
            />
        );
    }
}

export default MonthlyStatsSubView;