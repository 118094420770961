import React from 'react';
import PropTypes from 'prop-types';
import Help from './Help';

const Phrase = ({ description, help }) => {
  return (
    <div className="phraseDescriptionHolder">
      <div className="Phrase" dangerouslySetInnerHTML={{ __html: description }}/>
      <Help dangerouslySetInnerHTML={{ __html: help }}/>
    </div>
  );
};

Phrase.propTypes = {
  description: PropTypes.string.isRequired,
  help: PropTypes.string.isRequired
};
export default Phrase;
