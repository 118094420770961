import React from 'react';
import { Box, Container } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import LastPlayedSongsSubView from './LastPlayedSongsSubView/LastPlayedSongsSubView';
import MonthlyStatsSubView from './MonthlyStatsSubView/MonthlyStatsSubView';
import { FormattedMessage } from 'react-intl';
import Typography from '@material-ui/core/Typography';

class HomeView extends React.Component {
  render () {
    const {
      lastPlayedSongsDTO,
      lastPlayedSongsError,
      lastPlayedSongsPending,
      fetchLastPlayedSongs,
      fetchMonthlyStats,
      monthlyStats,
      monthlyStatsError,
      displayName
    } = this.props;

    return (
            <Container maxWidth="lg">
                <Box style={{ padding: 20, paddingBottom: 50, textAlign: 'left' }}>
                    <Typography variant="h4" color={'textPrimary'}>
                        <FormattedMessage id={'app.home.greeting'} values={{ displayName }} />
                    </Typography>
                </Box>
                <Grid container spacing={3}>
                    <Grid item xs={12} md={4}>
                        <LastPlayedSongsSubView
                            lastPlayedSongs={lastPlayedSongsDTO}
                            lastPlayedSongsError={lastPlayedSongsError}
                            lastPlayedSongsPending={lastPlayedSongsPending}
                            fetchLastPlayedSongs={fetchLastPlayedSongs}
                        />
                    </Grid>
                    <Grid item xs={12} md={8}>
                        <MonthlyStatsSubView
                            fetchMonthlyStats={fetchMonthlyStats}
                            error={monthlyStatsError}
                            stats={monthlyStats}
                        />
                    </Grid>
                </Grid>
            </Container>
    );
  }
}

export default HomeView;
