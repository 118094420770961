import React from 'react';

import StopIcon from '@material-ui/icons/Stop';
import IconButton from '@material-ui/core/IconButton';

function StopButton ({ onClick, hidden = false }) {
  if (!hidden) {
    return (
            <IconButton aria-label="stop" onClick={onClick}>
                <StopIcon/>
            </IconButton>
    );
  }

  return null;
}

export default StopButton;
