import React from 'react';

import PauseIcon from '@material-ui/icons/Pause';
import IconButton from '@material-ui/core/IconButton';

function PauseButton ({ onClick, hidden = false }) {
  if (!hidden) {
    return (
            <IconButton aria-label="pause" onClick={onClick}>
                <PauseIcon/>
            </IconButton>
    );
  }
  return null;
}

export default PauseButton;
