
import { TRAINING_MODE_CHANGED } from '../actions/userPreferencesActions';

const initialState = {
  trainingMode: true
};

const userPreferencesReducer = (state = initialState, action) => {
  switch (action.type) {
    case TRAINING_MODE_CHANGED:
      return {
        ...state,
        trainingMode: action.value
      };
    default:
      return state;
  }
};

export default userPreferencesReducer;

export const getTrainingMode = state => { return state.userPreferences.trainingMode; };
