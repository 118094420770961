
import React from 'react';

class Tag extends React.Component {
  render () {
    const { value } = this.props;

    return (
            <span className='Tag'>
                #{value}
            </span>
    );
  }
}

export default Tag;
