import React, { useEffect, useRef, useState } from 'react';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Tag from './Tag';

const Section = ({ instrumentType, sectionKey, instrument, tags, tempo, children }) => {
  const inputRef = useRef(null);
  const [fontSize, setFontSize] = useState(1.0);

  useEffect(() => {
    if (!inputRef.current) return;
    if (inputRef.current.clientWidth < inputRef.current.scrollWidth) {
      setFontSize(fontSize * 0.98);
    }
  }, [fontSize]);

  return (
    <Grid container spacing={1} className='SectionViewContainer'>
      <Grid item xs={6} sm={2}>
        <Typography
          display="block"
          variant="subtitle2"
          className="SectionKeyInfo"
        >
          Section in {sectionKey}
        </Typography>
      </Grid>
      <Grid item xs={6} sm={4}>
        <Typography
          display="block"
          variant="subtitle2"
          className="SectionInstrumentTagsInfo"
        >
          {instrumentType} - {instrument}
        </Typography>
      </Grid>
      <Grid item xs={6} sm={2}>
        <Typography
          display="block"
          variant="subtitle2"
          className="TempoInfo"
        >
          Tempo: {tempo}
        </Typography>
      </Grid>
      <Grid item xs={6} sm={4}>
        <Typography
          display="block"
          variant="subtitle2"
          className="SectionInstrumentTagsInfo"
        >
          {
            tags.map((tag, key) => {
              return <Tag
                value={tag}
                key={key}/>;
            })
          }
        </Typography>
      </Grid>
      <Grid item xs={12} className="SectionSubSectionsViewContainer" ref={inputRef} style={{
        fontSize: `${fontSize}rem`
      }}>
        {children}
      </Grid>
    </Grid>
  );
};

export default Section;
