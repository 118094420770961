import React from 'react';
import Button from '@material-ui/core/Button';
import PlaylistAddIcon from '@material-ui/icons/PlaylistAdd';
import Grid from "@material-ui/core/Grid";
import PhraseWidget from "./PhraseWidget";
import SubSectionWidget from "../SubSectionWidget";
import {FormattedMessage} from "react-intl";
import {injectIntl} from "react-intl";
import {PhrasesSongSubSectionFormDTOBuilder} from "../../../DTO/Song/Section/SubSection/SongSubSectionFormDTOBuilder";

class PhrasesSubSectionWidget extends React.PureComponent {

    songPhraseChangeHandler = (phraseIndex, itemAttributes) => {
        const {indexPosition} = this.props;
        this.props.onPhraseUpdate(indexPosition, phraseIndex, itemAttributes);
    };

    songPhraseDeleteHandler = (phraseIndex) => {
        const {indexPosition} = this.props;
        this.props.onPhraseDelete(indexPosition, phraseIndex);
    };

    songPhraseNewHandler = (event) => {
        const {indexPosition, subSection} = this.props;
        const newPhrase = PhrasesSongSubSectionFormDTOBuilder.buildPhrase(subSection)
        this.props.onPhraseCreate(indexPosition, newPhrase);
    };

    render() {
        const {subSection, onSubSectionDelete, onSubSectionUpdate, selectedForLoop, toggleSelectedSubSectionForLoop, indexPosition, intl} = this.props;

        let self = this;

        const subSectionPhrasesFormView = subSection.phrases.map((phrase, phraseKey) => {
            return <PhraseWidget
                key={phraseKey}
                indexPosition={phraseKey}
                phrase={phrase}
                onChange={self.songPhraseChangeHandler}
                onDelete={self.songPhraseDeleteHandler}
            />
        });

        return (
            <SubSectionWidget
                subSection={subSection}
                indexPosition={indexPosition}
                onSubSectionUpdate={onSubSectionUpdate}
                onSubSectionDelete={onSubSectionDelete}
                selectedForLoop={selectedForLoop}
                toggleSelectedSubSectionForLoop={toggleSelectedSubSectionForLoop}
                descriptionValue={subSection.description}
                descriptionLabel={intl.formatMessage({id: 'app.songform.section.phraseSubSection.field.description'}, {position: indexPosition + 1})}
                descriptionHelperText={intl.formatMessage({id: 'app.songform.section.subsection.field.descriptionHint'}, {
                    hint1: 'Intro',
                    hint2: 'Solo'
                })}
                descriptionChangeEnabled={true}
            >
                <Grid item xs={12} className='PhrasesFormContainer'>
                    {subSectionPhrasesFormView}
                </Grid>
                <Grid item style={{ paddingTop: "0"}}>
                    <Button variant="contained" color="primary" aria-label="new phrase" size="small" onClick={this.songPhraseNewHandler}>
                        <PlaylistAddIcon/> <FormattedMessage id="app.songform.section.subsection.button.newPhrase"/>
                    </Button>
                </Grid>
            </SubSectionWidget>
        );
    }
}

export default injectIntl(PhrasesSubSectionWidget);
