
export class SongListItemDTO {
  constructor (id, songTitle, artistName, sections, instrumentTypeName) {
    this.id = id;
    this.songTitle = songTitle;
    this.artistName = artistName;
    this.instrumentTypeName = instrumentTypeName;
    this.sections = sections;
  }

  getTagsInvolved () {
    // tags involved
    const tagsUsed = this.sections.map((section) => section.tags);
    let tagsUsedMerged = [].concat.apply([], tagsUsed);
    // discard repeated tags
    tagsUsedMerged = tagsUsedMerged.filter((v, i) => tagsUsedMerged.indexOf(v) === i);
    return tagsUsedMerged;
  }

  getKeysInvolved () {
    // song keys involved
    let keysInvolved = this.sections.map((section) => section.sectionKey);
    // discard repeated section keys
    keysInvolved = keysInvolved.filter((v, i) => keysInvolved.indexOf(v) === i);
    return keysInvolved;
  }

  getInstrumentsInvolved () {
    // instruments involved
    let instrumentsInvolved = this.sections.map((section) => section.instrument);
    // discard repeated instruments
    instrumentsInvolved = instrumentsInvolved.filter((v, i) => instrumentsInvolved.indexOf(v) === i);
    return instrumentsInvolved;
  }
}
