
export const INSTRUMENT_TYPE_NOTATION = {
  CHORD: 'chord',
  HARMONICA: 'harmonica'
};

export const INSTRUMENT_TYPE_RELATED_TOOL = {
  TRANSPOSE: 'transpose',
  CAPO: 'capo'
};
