import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { createArtist } from '../store/actions/artistsActions';
import ArtistCreationView from '../components/ArtistCreationView/ArtistCreationView';

function mapStateToProps (state, ownProps) {
  return {
  };
}

const mapDispatchToProps = dispatch => bindActionCreators({
  createArtist
}, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ArtistCreationView);
