import React from 'react';
import Grid from '@material-ui/core/Grid';
import Artist from './Artist';
import { Box } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import { FormattedMessage } from 'react-intl';

class ArtistsList extends React.Component {
  render () {
    const { artists } = this.props;

    const artistsKeys = Object.keys(artists);

    if (artistsKeys.length === 0) {
      return (
                <Box style={{ marginTop: 10 }}>
                    <Typography variant="body1" >
                        <FormattedMessage id="app.common.noResult"/>
                    </Typography>
                </Box>
      );
    }

    const artistsListView = artistsKeys.map((artistId, key) =>
            <Grid item sm={3} xs={12} key={artistId} style={{ paddingTop: 10, paddingBottom: 10 }}>
                <Artist
                    model={artists[artistId]}
                />
            </Grid>
    );
    return (
            <Grid container justify="center">
                {artistsListView}
            </Grid>
    );
  }
}

export default ArtistsList;
