import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { getSongsFromArtist, getSongsError, getSongsPending } from '../store/reducers/songsReducer';
import {
  getArtistById,
  getArtists,
  getArtistsError,
  getArtistsPending
} from '../store/reducers/artistsReducer';
import { deleteSong } from '../store/actions/songsActions';
import { deleteArtist } from '../store/actions/artistsActions';
import SongsFromArtistView from '../components/SongsFromArtistView/SongsFromArtistView';
import { SongListItemDTO } from '../components/SongsList/DTO/SongListItemDTO';
import { ArtistDTO } from '../components/SongsFromArtistView/DTO/ArtistDTO';

function mapStateToProps (state, ownProps) {
  const { match: { params } } = ownProps;
  const artistId = params.id;

  const songsFromArtist = getSongsFromArtist(state, artistId);

  const songsFromArtistDTO = [];
  songsFromArtist.forEach(function (songFromArtist) {
    const { song, artist, instrumentType } = songFromArtist;
    songsFromArtistDTO.push(new SongListItemDTO(
      song.id,
      song.title,
      artist.name,
      song.sections,
      instrumentType.name
    ));
  });

  let artistDTO = null;
  const artist = getArtistById(getArtists(state), artistId);
  if (artist) {
    artistDTO = new ArtistDTO(artist.id, artist.name, artist.image);
  }

  return {
    artistDTO,
    artistPending: getArtistsPending(state),
    artistError: getArtistsError(state),

    songsFromArtistError: getSongsError(state),
    songsFromArtist: songsFromArtistDTO,
    songsFromArtistPending: getSongsPending(state)
  };
}

const mapDispatchToProps = dispatch => bindActionCreators({
  deleteSong,
  deleteArtist
}, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SongsFromArtistView);
