import React from "react";
import PropTypes from "prop-types";

import ReproductionCountIn from "./ReproductionCountIn";
import ReproductionReader from "./ReproductionReader/ReproductionReader";
import ReproductionPlayer from "../ReproductionPlayer";
import ReproductionControls from "../ReproductionControls";
import Grid from "@material-ui/core/Grid";
import ReproductionProgress from "./ReproductionProgress";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import { Link } from "react-router-dom";

import IconButton from "@material-ui/core/IconButton";
import EditIcon from "@material-ui/icons/Edit";
import SkipPreviousIcon from "@material-ui/icons/SkipPrevious";
import SkipNextIcon from "@material-ui/icons/SkipNext";

import { Reproduction } from "../../domain/Reproduction/Reproduction";
import HiddenJs from "@material-ui/core/Hidden/HiddenJs";

import PlayButton from "../Buttons/PlayButton";
import StopButton from "../Buttons/StopButton";
import PauseButton from "../Buttons/PauseButton";
import { SongDTO } from "./DTO/Song/SongDTO";
import { INSTRUMENT_TYPE_NOTATION } from "../../store/entities/instrumentTypes";
import { ChordNotationProcessor } from "./NotationProcessor/ChordNotationProcessor/ChordNotationProcessor";
import {
  HarmonicaNotationProcessor
} from "./NotationProcessor/HarmonicaNotationProccessor/HarmonicaNotationProccessor";

class ReproductionWidget extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      reproduction: this.createReproduction(this.props.songInfo, this.props.trainingMode, this.props.countingIn),
    };
    // there is a test associated with handlePlayClick and handleStopClick
    // there is an issue with arrow functions in enzyme
    // https://github.com/airbnb/enzyme/issues/944
    this.handlePlayClick = this.handlePlayClick.bind(this);
    this.handleStopClick = this.handleStopClick.bind(this);
  }

  componentDidUpdate(prevProps) {
    if (JSON.stringify(this.props.songInfo) !== JSON.stringify(prevProps.songInfo) || this.props.trainingMode !== prevProps.trainingMode) {
      this.setState(state => ({
        reproduction: this.createReproduction(this.props.songInfo, this.props.trainingMode, this.props.countingIn)
      }));
    }
  }

  createReproduction(songInfo, trainingMode, countingIn) {
    let songDuration = null;
    let songTempo = null;
    if (songInfo) {
      const {song} = songInfo;

      songDuration = song.duration;
      songTempo = song.tempo;
    }

    let reproduction = Reproduction
      .newBuilder()
      .withSongDuration(songDuration)
      .withSongTempo(songTempo)
      .withTrainingMode(trainingMode)
      .withCountingIn(countingIn)
      .createReproduction();

    reproduction.on(Reproduction.EVENTS.START, this.onReproductionStart);
    reproduction.on(Reproduction.EVENTS.READY, this.refreshReproduction);
    reproduction.on(Reproduction.EVENTS.COUNTING_IN, this.refreshReproduction);
    reproduction.on(Reproduction.EVENTS.PLAYING, this.refreshReproduction);
    reproduction.on(Reproduction.EVENTS.FINISH, this.onReproductionEnd);
    return reproduction;
  }

  handlePlayClick() {
    let {reproduction} = this.state;
    reproduction.start();
    this.setState(state => ({
      reproduction: reproduction,
    }));
  }

  handlePauseClick = () => {
    let {reproduction} = this.state;

    reproduction.pause();

    this.setState(state => ({
      reproduction: reproduction,
    }));
  }

  handleStopClick() {
    let {reproduction} = this.state;

    reproduction.stop();

    // we give the player a short moment to start the playback after the jump
    setTimeout(() => {
      this.setState(state => ({
        reproduction: reproduction,
      }));
    }, 100);
  }

  handleSeekTo = (event, value) => {
    let {reproduction} = this.state;

    reproduction.seekTo(value);

    this.setState(state => ({
      reproduction: reproduction,
    }));
  }

  onReproductionStart = () => {
    this.props.onReproductionStart();
  }

  onReproductionEnd = () => {
    this.refreshReproduction();
    this.props.onReproductionEnd();
  }

  refreshReproduction = () => {
    this.setState(state => ({
      reproduction: state.reproduction,
    }));
  }

  getNotationProcessor(instrumentType) {

    const {notation} = instrumentType;

    if (notation === INSTRUMENT_TYPE_NOTATION.CHORD) {
      return new ChordNotationProcessor();
    } else if (notation === INSTRUMENT_TYPE_NOTATION.HARMONICA) {
      return new HarmonicaNotationProcessor();
    } else {
      throw new Error("notation not supported");
    }
  }

  render() {
    const {reproduction} = this.state;
    const {songInfo, tools, controlsVisibility} = this.props;
    const {song, artist, instrumentType} = songInfo;

    if (!song || !artist || !instrumentType) {
      return;
    }

    const {id, title, sections, videoId, duration, tempo} = song;
    let songDTO = new SongDTO(id, instrumentType.name, artist.id, artist.name, title, videoId, duration, tempo, sections);

    const notationProcessor = this.getNotationProcessor(instrumentType);
    notationProcessor.process(songDTO, tools);

    return (
      <div className={`ReproductionWidget ReproductionWidgetReproductionState-${reproduction.state}`}>
        {reproduction.isCountingIn() && (
          <ReproductionCountIn
            count={reproduction.countingInCounter}
          />
        )}
        <ReproductionReader
          reproduction={reproduction}
          song={songDTO}
        />
        <ReproductionPlayer
          videoId={song.videoId}
          model={reproduction.getPlayer()}
        />
        <ReproductionControls visibility={controlsVisibility}>
          <ReproductionProgress
            value={reproduction.getCurrentTime()}
            duration={songDTO.getDuration()}
            onChange={this.handleSeekTo}
            marks={songDTO.getAllSubSectionsWithStartingTime().map((subSection) => subSection.startingTime)}
          />
          <Grid container spacing={1}>
            <Grid item xs={8} md={4} className='ActiveSongInfo'>
              <Typography component="div" color={"secondary"}>
                <Box fontWeight={500} m={0}>
                  <div className='ActiveSongInfoTitle'>
                    {song.title}
                    <Link
                      to={{
                        pathname: '/songs/edition/' + song.id,
                        state: {
                          origin: 'reproduction'
                        }
                      }}
                    >
                      <IconButton size="small" color="secondary">
                        <EditIcon/>
                      </IconButton>
                      {/* This is not seen on portrait mode smartphones. Soon it will become an icon on the app bar */}
                    </Link>
                  </div>
                </Box>
              </Typography>
              <Typography component="div">
                <Box fontSize="fontSize" m={0} className='ActiveSongInfoArtist'>
                  <Link
                    to={'/artists/songs/' + artist.id}
                    style={{
                      color: "white",
                      textDecoration: "none"
                    }}
                  >
                    {artist.name}
                  </Link>
                </Box>
              </Typography>
            </Grid>
            <Box
              component={Grid}
              item
              xs={1}
              display={{xs: 'block', sm: 'none'}}
            >
            </Box>
            <Grid item xs={2} md={4} className='ReproductionActionsButtons'>
              <HiddenJs xsUp>
                <IconButton aria-label="previous" hidden={true}>
                  <SkipPreviousIcon/>
                </IconButton>
              </HiddenJs>
              <PlayButton onClick={this.handlePlayClick}
                          hidden={!reproduction.isReady() || reproduction.isPlaying() || reproduction.isCountingIn()}/>
              <PauseButton onClick={this.handlePauseClick} hidden={!reproduction.isPlaying()}/>
              <StopButton onClick={this.handleStopClick}
                          hidden={reproduction.isStopped() || reproduction.isCountingIn()}/>
              <HiddenJs xsUp>
                <IconButton aria-label="next">
                  <SkipNextIcon/>
                </IconButton>
              </HiddenJs>
            </Grid>
            <Grid item xs={1} md={4} className='ReproductionExtraActionButtons'>
            </Grid>
          </Grid>
        </ReproductionControls>
      </div>
    );
  }
}

ReproductionWidget.propTypes = {
  songInfo: PropTypes.object.isRequired,
  trainingMode: PropTypes.bool.isRequired,
  tools: PropTypes.object.isRequired,
  controlsVisibility: PropTypes.bool.isRequired,
  onReproductionStart: PropTypes.func.isRequired,
  onReproductionEnd: PropTypes.func.isRequired,
  countingIn: PropTypes.bool
}
export default ReproductionWidget;
