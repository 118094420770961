import React from "react";
import {Redirect} from "react-router-dom";
import SongForm from "../SongForm/SongFormWidget";
import {compose} from "redux";
import WithLoading from "../Reusable/WithLoading/WithLoading";
import WithErrorHandling from "../Reusable/WithErrorHandling/WithErrorHandling";

import './SongEditionView.css';

const ComposedSongForm = compose(
    WithLoading,
    WithErrorHandling
)(SongForm);

export default class SongEditionView extends React.PureComponent {
    constructor(props) {
        super(props);

        this.state = {
            actionComplete: false,
        };
    }

    onSave = (song) => {
        const {editSong} = this.props;
        return editSong(song);
    };

    onSaveAndExit = (song) => {
        this.onSave(song).then(()=> {
            this.setState({
                actionComplete: true
            });
        });
    };

    renderRedirect = () => {
        if (this.state.actionComplete) {
            const {songFormDTO, history} = this.props;

            let origin = "songs-from-artist";

            if ((history.location.state !== undefined) && (history.location.state.origin !== null)) {
                origin = history.location.state.origin;
            }

            let route;
            switch (origin) {
                case "songs-from-artist":
                    route = '/artists/songs/' + songFormDTO.artistId;
                    break;
                case "songs-list":
                    route = '/songs/list';
                    break;
                case "reproduction":
                    route = '/songs/play/' + songFormDTO.key;
                    break;
                default:
                    break;
            }
            return <Redirect to={route}/>
        }
    };

    goBack = () => {
        window.history.back();
    };

    render() {
        const {songFormDTO, songPending, songError, usedTags, instrumentsTypes, artists} = this.props;
        return (
            <div className='SongEditionViewWidget'>
                {this.renderRedirect()}
                <ComposedSongForm
                    isLoading={!songFormDTO || songPending}
                    error={songError}
                    song={songFormDTO}
                    availableTags={usedTags}
                    availableInstrumentsType={instrumentsTypes}
                    artists={artists}
                    title="app.songedition.title"
                    onSave={this.onSave}
                    onSaveAndExit={this.onSaveAndExit}
                    onCancel={this.goBack}
                />
            </div>
        );
    }
}
