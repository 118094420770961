import React from 'react';
import Avatar from '@material-ui/core/Avatar';
import PropTypes from 'prop-types';

class ArtistAvatar extends React.Component {
  render () {
    const { image, size } = this.props;

    return (
            <Avatar
                src={image}
                style={{
                  width: size,
                  height: size,
                  margin: 'auto'
                }}
            />
    );
  }
}

ArtistAvatar.propTypes = {
  image: PropTypes.string.isRequired,
  size: PropTypes.string.isRequired
};

export default ArtistAvatar;
