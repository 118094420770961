import { SONG_REPRODUCTION_START, SONG_REPRODUCTION_STOP } from '../actions/songsActions';

const initialState = {};

const appReducer = (state = initialState, action) => {
  switch (action.type) {
    case SONG_REPRODUCTION_START:
      return {
        ...state
      };
    case SONG_REPRODUCTION_STOP:
      return {
        ...state
      };
    default:
      return state;
  }
};

export default appReducer;

export const getFlashMessageSelector = (state) => state.flashMessage.messages;
