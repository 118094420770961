import React from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  root: {
    marginTop: theme.spacing(5),
    marginBottom: theme.spacing(5)
  }
}));

function WithLoading (Component) {
  return function WithLoadingComponent ({ isLoading, ...props }) {
    const classes = useStyles();

    if (!isLoading) { return (<Component {...props} />); }
    return (<div className={classes.root}><CircularProgress color="secondary" /></div>);
  };
}
export default WithLoading;
