import { HarmonicaNotationFormatter } from './HarmonicaNotationFormatter';
import { PhrasesSongSubSection } from '../../DTO/Song/Section/SubSection/SongSubSection';

export class HarmonicaNotationProcessor {
  process (songDTO, tools) {
    /**
         *  todo podría hacer algo similar a lo que hace el parse de chordjs
         *  reemplazar el description original por algo semantico
         *  luego el formatter lo pasa a string
         *  podríamos hacer estadisticas de notas mas usadas (o interpretar escala)
          */
    const formatter = new HarmonicaNotationFormatter();

    for (const sectionId in songDTO.getSections()) {
      const section = songDTO.getSection(sectionId);
      for (const subSectionKey in section.getSubSections()) {
        const subSection = section.getSubSectionByKey(subSectionKey);
        if (subSection instanceof PhrasesSongSubSection) {
          for (const phraseKey in subSection.getPhrases()) {
            const phrase = subSection.getPhrase(phraseKey);
            const description = formatter.format(phrase.description);
            phrase.setDescription(description);
          }
        }
      }
    }
  }
}
