import {compose} from "redux";
import WithLoading from "../Reusable/WithLoading/WithLoading";
import WithErrorHandling from "../Reusable/WithErrorHandling/WithErrorHandling";
import SongsList from "../SongsList/SongsList";
import ArtistView from "./ArtistView";
import React from "react";
import {Link, Redirect} from "react-router-dom";
import {Container} from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import IconButton from "@material-ui/core/IconButton";
import CreateIcon from "@material-ui/icons/Create";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import DeleteIcon from "@material-ui/icons/Delete";
import Button from "@material-ui/core/Button";
import AddIcon from "@material-ui/icons/Add";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogActions from "@material-ui/core/DialogActions";
import {FormattedMessage} from "react-intl";
import {SongToDeleteDTO} from "../../store/actions/DTO/song/SongToDeleteDTO";

const ComposedSongsList = compose(
    WithLoading,
    WithErrorHandling
)(SongsList);

const ComposedArtistView = compose(
    WithLoading,
    WithErrorHandling
)(ArtistView);

class SongsFromArtistView extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            menuOpen: null,
            deleteDialogOpen: false,
            forceRedirection: false
        };
    }

    handleCloseDeleteDialog = () => {
        this.setState({
            deleteDialogOpen: false,
        });
    }

    handleOpenDeleteDialog = (songKey) => {
        this.setState({
            menuOpen: null,
            deleteDialogOpen: true,
        });
    }

    handleConfirmDeleteDialog = () => {
        const {deleteArtist, artistDTO} = this.props;

        deleteArtist(artistDTO);

        this.setState({
            forceRedirection: true,
        });
    }

    renderRedirect = () => {
        if (this.state.forceRedirection) {
            const route = '/artists/list';

            return <Redirect to={route}/>
        }
    }

    handleMenuOpen = (event) => {
        this.setState({
            menuOpen: event.currentTarget,
        });
    }

    handleMenuClose = () => {
        this.setState({
            menuOpen: null,
        });
    }

    deleteSong = (song) => {
        let {deleteSong} = this.props;

        deleteSong(new SongToDeleteDTO(song.id));
    }

    render() {
        const {songsFromArtist, songsFromArtistError, songsFromArtistPending, artistDTO, artistPending, artistError} = this.props;
        const {deleteDialogOpen, menuOpen} = this.state;

        if (artistDTO === null) {
            // If user signed out, data is lost
            return (<div></div>);
        }

        return (

            <Container style={{maxWidth: '100vw'}}>

                {this.renderRedirect()}

                <Grid container alignItems="flex-start" justify="flex-end" direction="row">
                    <Link to={'/artists/edition/' + artistDTO.key}>
                        <IconButton edge="end" aria-label="Edit" size="small" style={{marginLeft: 5}}>
                            <CreateIcon fontSize="inherit"/>
                        </IconButton>
                    </Link>

                    <IconButton edge="end" size="small" aria-controls="artist-menu" aria-haspopup="true"
                                onClick={this.handleMenuOpen}>
                        <MoreVertIcon fontSize="inherit"/>
                    </IconButton>
                    <Menu
                        id="simple-menu"
                        keepMounted
                        onClose={this.handleMenuClose}
                        anchorEl={menuOpen}
                        open={Boolean(menuOpen)}
                    >
                        <MenuItem onClick={this.handleOpenDeleteDialog}>
                            <DeleteIcon fontSize="inherit"/> <FormattedMessage id="app.common.delete"/>
                        </MenuItem>
                    </Menu>
                </Grid>

                <Grid>
                    <Grid item xs={12}>
                        <ComposedArtistView
                            isLoading={artistPending}
                            error={artistError}
                            model={artistDTO}
                        />
                    </Grid>

                    <Grid item xs={12} style={{textAlign: 'right'}}>
                        <Link to={'/songs/creation/' + artistDTO.key}
                              style={{textDecoration: 'none', marginTop: 15, marginBottom: 7}}>
                            <Button size="small">
                                <AddIcon/> <FormattedMessage id="app.songsfromartist.button.new"/>
                            </Button>
                        </Link>
                    </Grid>

                    <Grid>
                        <Grid item xs={12}>
                            <ComposedSongsList
                                isLoading={songsFromArtistPending}
                                error={songsFromArtistError}
                                showArtist={false}
                                songs={songsFromArtist}
                                onSongDelete={this.deleteSong}
                                origin={'songs-from-artist'}
                            />
                        </Grid>
                    </Grid>
                </Grid>

                <Dialog
                    open={deleteDialogOpen}
                    onClose={this.handleCloseDeleteDialog}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle id="alert-dialog-slide-title">
                        <FormattedMessage id="app.common.confirmation"/>
                    </DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            <FormattedMessage id="app.songsfromartist.confirmation.deleteartist"/>
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={this.handleCloseDeleteDialog} color="secondary" autoFocus>
                            <FormattedMessage id="app.common.cancel"/>
                        </Button>
                        <Button onClick={this.handleConfirmDeleteDialog} color="secondary">
                            <FormattedMessage id="app.common.ok"/>
                        </Button>
                    </DialogActions>
                </Dialog>

            </Container>
        );
    }
}

export default SongsFromArtistView;
