import { compose } from 'redux';
import WithLoading from '../Reusable/WithLoading/WithLoading';
import WithErrorHandling from '../Reusable/WithErrorHandling/WithErrorHandling';
import ArtistsList from './ArtistsList';
import React from 'react';
import { Container, Box } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import { Link } from 'react-router-dom';
import Button from '@material-ui/core/Button';
import AddIcon from '@material-ui/icons/Add';
import { FormattedMessage } from 'react-intl';

const ComposedArtistList = compose(
  WithLoading,
  WithErrorHandling
)(ArtistsList);

class ArtistsView extends React.Component {
  render () {
    const { artists, error, pending } = this.props;

    return (
            <Container maxWidth="lg">
                <Typography component="h2" variant="h5" gutterBottom>
                    <FormattedMessage id="app.artists.title"/>
                </Typography>

                <Box style={{ textAlign: 'end' }}>
                    <Link to={'/artists/creation'} style={{ textDecoration: 'none', marginTop: 15, marginBottom: 7 }}>
                        <Button size="small">
                            <AddIcon/> <FormattedMessage id="app.artists.button.new"/>
                        </Button>
                    </Link>
                </Box>

                <ComposedArtistList
                    isLoading={pending}
                    error={error}
                    artists={artists}
                />

            </Container>
    );
  }
}

export default ArtistsView;
