export class Phrase {
  constructor (subSectionOwner, id, description, help) {
    this.subSectionOwner = subSectionOwner;
    this.id = id;
    this.description = description;
    this.help = help;
  }

  getDescription () {
    return this.description;
  }

  setDescription (aDescription) {
    this.description = aDescription;
  }
}
