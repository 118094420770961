import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { AUTH_STATE_LOGIN, AUTH_STATE_LOGOUT, getAuthState, isEmailVerified } from '../store/reducers/sessionReducer';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import EmailNotVerifiedMessage from './EmailNotVerifiedMessage';

const PrivateRoute = function ({ component: Component, authState, emailVerified, ...rest }) {
  if (authState === AUTH_STATE_LOGOUT) {
    return (
            <Route
                {...rest}
                render={(props) => (
                    <Redirect to={{
                      pathname: '/login',
                      state: { from: props.location }
                    }}/>
                )}
            />
    );
  } else {
    if (authState === AUTH_STATE_LOGIN) {
      if (emailVerified) {
        return <Route {...rest} render={(props) => (<Component {...props} />)} />;
      } else {
        return <Route {...rest} component={EmailNotVerifiedMessage} />;
      }
    }
  }
  return (<div></div>);
};

const mapStateToProps = state => ({
  emailVerified: isEmailVerified(state),
  authState: getAuthState(state)
});

const mapDispatchToProps = dispatch => bindActionCreators({
}, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PrivateRoute);
