import { compose } from 'redux';
import WithLoading from '../Reusable/WithLoading/WithLoading';
import WithErrorHandling from '../Reusable/WithErrorHandling/WithErrorHandling';
import SongsToStrengthenList from './SongsToStrengthenList';
import React from 'react';
import { Container } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import { FormattedMessage } from 'react-intl';

const ComposedSongsToStrengthenList = compose(
  WithLoading,
  WithErrorHandling
)(SongsToStrengthenList);

class TrainingView extends React.Component {
  componentDidMount () {
    const { fetchSongsToStrengthen } = this.props;
    fetchSongsToStrengthen();
  }

  render () {
    const { songsToStrengthenError, songsToStrengthenPending, songsToStrengthenDTO } = this.props;

    return (
            <Container maxWidth="lg">
                <Typography component="h2" variant="h5" gutterBottom>
                    <FormattedMessage id="app.training.title" />
                </Typography>
                <ComposedSongsToStrengthenList
                    isLoading={songsToStrengthenPending}
                    error={songsToStrengthenError}
                    songsToStrengthen={songsToStrengthenDTO}
                />
            </Container>
    );
  }
}

export default TrainingView;
