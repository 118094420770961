import PLAYER_EVENTS from './PlayerEvents';

const dispatchOnReadyHandlers = Symbol();
const dispatchOnFinishHandlers = Symbol();

export class PlayAlongPlayer {
  static get EVENTS () {
    return PLAYER_EVENTS;
  }

  constructor (duration) {
    this.innerPlayer = null;
    this.interval = null;
    this.currentTime = 0;
    this.isRunning = false;
    this.duration = duration;
    this.currentPlaybackRate = 1;

    this[dispatchOnFinishHandlers] = [];
    this[dispatchOnReadyHandlers] = [];
  }

  setInnerPlayer (innerPlayer) {
    this.innerPlayer = innerPlayer;
    this.dispatch(PlayAlongPlayer.EVENTS.READY);
  }

  play () {
    this.isRunning = true;

    const intervalTimeout = 1000;

    this.interval = setInterval(() => {
      if (this.isRunning) {
        this.currentTime++;
        if (this.currentTime >= this.getDuration()) {
          this.stop();
          this.dispatch(PlayAlongPlayer.EVENTS.FINISH);
        }
      }
    }, intervalTimeout);
  }

  pause () {
    this.isRunning = false;
  }

  stop () {
    clearInterval(this.interval);
    this.currentTime = 0;
    this.isRunning = false;
  }

  seekTo (newTime) {
    this.currentTime = newTime;
  }

  getCurrentTime () {
    return this.currentTime;
  }

  getDuration () {
    return this.duration;
  }

  isAvailable () {
    return true;
  }

  getAvailablePlaybackRates () {
    return [1];
  }

  setPlaybackRate (playbackRate) {
    this.currentPlaybackRate = playbackRate;
  }

  on (eventName, handler) {
    switch (eventName) {
      case PlayAlongPlayer.EVENTS.READY:
        return this[dispatchOnReadyHandlers].push(handler);
      case PlayAlongPlayer.EVENTS.FINISH:
        return this[dispatchOnFinishHandlers].push(handler);
      default:
        break;
    }
  }

  dispatch (eventName) {
    let handler, i, len, ref;

    ref = [];

    switch (eventName) {
      case PlayAlongPlayer.EVENTS.READY:
        ref = this[dispatchOnReadyHandlers];
        break;
      case PlayAlongPlayer.EVENTS.FINISH:
        ref = this[dispatchOnFinishHandlers];
        break;
      default:
        break;
    }

    for (i = 0, len = ref.length; i < len; i++) {
      handler = ref[i];
      handler();
    }
  }
}
