import React from "react";

import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';

import TextField from "@material-ui/core/TextField";
import Button from '@material-ui/core/Button';
import {FormattedMessage} from "react-intl";
import {injectIntl} from 'react-intl';
import YouTubeAvatarPicker from "../YouTubeAvatarPicker";

import './ArtistForm.css';
import ArtistAvatar from "../ArtistAvatar/ArtistAvatar";

class ArtistForm extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            artist: props.artist,
            fieldErrors: {
                name: false,
                image: false,
            }
        };
    }

    changeArtistNameHandler = event => {

        let artist = this.state.artist;

        artist.setName(event.target.value);

        this.setState({
            artist: artist
        });
    }

    changeArtistImageHandler = value => {
        let artist = this.state.artist;

        artist.setImage(value);

        this.setState({
            artist: artist
        });
    }

    handleSubmit = (event) => {
        event.preventDefault();

        let {artist, fieldErrors} = this.state;

        fieldErrors.name = artist.getName() === '';
        fieldErrors.image = artist.getImage() === '';

        this.setState({
            fieldErrors: fieldErrors
        });

        for (var propertyName in fieldErrors) {

            if (fieldErrors[propertyName]) {
                return false;
            }
        }

        this.props.onSubmit();
    }

    render() {
        const {artist} = this.state;
        const {intl} = this.props;

        if (artist) {
            return (
                <Card>
                    <CardContent>
                        <form autoComplete="off" className={'artistForm'}>
                            <div>
                                <TextField
                                    label={intl.formatMessage({id: 'app.artistform.field.name'})}
                                    error={this.state.fieldErrors.name}
                                    margin={'normal'}
                                    value={artist.getName()}
                                    onChange={this.changeArtistNameHandler}
                                    required
                                />
                            </div>
                            <div>
                                <YouTubeAvatarPicker
                                    label={intl.formatMessage({id: 'app.artistform.field.image'})}
                                    error={this.state.fieldErrors.image}
                                    margin={'normal'}
                                    value={artist.getImage()}
                                    onChange={this.changeArtistImageHandler}
                                    relatedTerms={artist.getName()}
                                    required
                                />
                            </div>
                            <div>
                                <ArtistAvatar
                                    image={artist.getImage()}
                                    size={'100px'}
                                />
                            </div>
                        </form>
                    </CardContent>
                    <CardActions>
                        <Button variant="contained" size="small" color="secondary" onClick={this.handleSubmit}>
                            <FormattedMessage id="app.common.ok"/>
                        </Button>
                        <Button variant="outlined" size="small" color="secondary" onClick={this.props.onCancel}>
                            <FormattedMessage id="app.common.cancel"/>
                        </Button>
                    </CardActions>
                </Card>
            );
        }
        return null;
    }
}

export default injectIntl(ArtistForm);