import React from 'react';
import Phrase from './Phrase';
import List from '@material-ui/core/List';
import ListSubheader from '@material-ui/core/ListSubheader';
import toMinutesSecondsFormat from '../../../../../../../utils/time';
import PropTypes from 'prop-types';

const PhrasesSubSection = ({ description, startingTime, endingTime, phrases }) => {
  let descriptionView = description;
  if (startingTime !== null && endingTime !== null) {
    descriptionView = descriptionView + ' [' + toMinutesSecondsFormat(startingTime) + ' - ' + toMinutesSecondsFormat(endingTime) + ']';
  }

  const subSectionPhrasesView = phrases.map((phrase, key) => {
    return <Phrase
      key={key}
      description={phrase.description}
      help={phrase.help}
    />;
  });

  return <List
    className="SongListPhrase"
    subheader={
      <ListSubheader component="div" id="nested-list-subheader" className="SubSectionDescription" disableSticky={true}>
        {descriptionView}
      </ListSubheader>
    }
  >
    {subSectionPhrasesView}
  </List>;
};

PhrasesSubSection.propTypes = {
  phrases: PropTypes.array.isRequired,
  description: PropTypes.string.isRequired
};

export default PhrasesSubSection;
