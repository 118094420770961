import styled from 'styled-components';
import { withTheme } from '@material-ui/core/styles';

const Help = styled.div`
  text-align: left;
  font-style: italic;
  margin-bottom: 20px;
  white-space: break-spaces;
  color: ${props => props.theme.palette.tertiary};
`;

export default withTheme(Help);
