import React from 'react';
import CssBaseline from '@material-ui/core/CssBaseline/index';
import Container from '@material-ui/core/Container/index';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';

import HomeContainer from '../containers/HomeContainer';
import ArtistsContainer from '../containers/ArtistsContainer';
import ArtistCreationContainer from '../containers/ArtistCreationContainer';
import ArtistEditionContainer from '../containers/ArtistEditionContainer';
import SongsFromArtistContainer from '../containers/SongsFromArtistContainer';
import SongsListContainer from '../containers/SongsListContainer';
import ReproductionContainer from '../containers/ReproductionContainer';
import SongEditionContainer from '../containers/SongEditionContainer';
import SongCreationContainer from '../containers/SongCreationContainer';
import ProfileContainer from '../containers/ProfileContainer';
import SignupContainer from '../containers/SignupContainer';
import TrainingContainer from '../containers/TrainingContainer';
import LoginContainer from '../containers/LoginContainer';
import LandingPageView from '../components/LandingPageView/LandingPageView';

import FlashMessage from './FlashMessage';
import DashboardAppBarSpacer from './DashboardAppBarSpacer';
import DashboardAppBar from './DashboardAppBar';
import PublicRoute from './PublicRoute';
import PrivateRoute from './PrivateRoute';
import SharedStyles from './SharedStyles';
import './Dashboard.css';

function Dashboard (props) {
  const { classes } = props;
  return (
        <Router>
            <div className="Root">
                <CssBaseline/>
                <Switch>
                    <Route path={['/songs/play/:id']}>
                        <PrivateRoute path='/songs/play/:id' component={ReproductionContainer} />
                    </Route>
                    <Route path={['/']}>
                        <DashboardAppBar />
                        <main className={classes.content}>
                            <DashboardAppBarSpacer/>
                            <Container maxWidth="xl" className={classes.container}>
                                <PublicRoute exact path='/' component={LandingPageView} onLoginRedirectToPath='/home'/>
                                <PublicRoute exact path='/login' component={LoginContainer} onLoginRedirectToPath='/home'/>
                                <PublicRoute exact path='/signup' component={SignupContainer} onLoginRedirectToPath='/home'/>
                                <PrivateRoute exact path='/home' component={HomeContainer} />
                                <PrivateRoute path='/artists/list' component={ArtistsContainer} />
                                <PrivateRoute path='/artists/creation' component={ArtistCreationContainer} />
                                <PrivateRoute path='/artists/edition/:id' component={ArtistEditionContainer} />
                                <PrivateRoute path='/artists/songs/:id' component={SongsFromArtistContainer} />
                                <PrivateRoute path='/training' component={TrainingContainer} />
                                <PrivateRoute path='/songs/list' component={SongsListContainer} />
                                <PrivateRoute path='/profile' component={ProfileContainer} />
                                <PrivateRoute path='/songs/edition/:id' component={SongEditionContainer} />
                                <PrivateRoute path='/songs/creation/:idArtist?' component={SongCreationContainer} />
                            </Container>
                        </main>
                    </Route>
                </Switch>
                <FlashMessage />
            </div>
        </Router>
  );
}

const styles = theme => ({
  ...SharedStyles(theme)
});
export default withStyles(styles)(Dashboard);
