import React, { useState } from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { FormattedMessage, injectIntl } from 'react-intl';

function ResetPasswordDialog (props) {
  const { intl } = props;

  const [email, setEmail] = useState('');
  const [showAlertDialog, setShowAlertDialog] = useState(false);

  const onSubmit = (event) => {
    if (email === '') {
      setShowAlertDialog(true);
    } else {
      props.onResetPassword(email);
    }
  };

  return (
        <div>
            <Dialog open={props.visible} onClose={props.onClose} aria-labelledby="form-dialog-title">
                <DialogTitle id="form-dialog-title"><FormattedMessage id="app.session.passwordReset"/></DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        <FormattedMessage id="app.passwordReset.description" />
                    </DialogContentText>
                    <TextField
                        autoFocus
                        margin="dense"
                        id="name"
                        label={intl.formatMessage({ id: 'app.passwordReset.form.email' })}
                        type="email"
                        onChange={e => setEmail(e.target.value)}
                        value={email}
                        fullWidth
                        required
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={props.onClose} color="secondary">
                        <FormattedMessage id="app.common.cancel" />
                    </Button>
                    <Button onClick={onSubmit} color="secondary" >
                        <FormattedMessage id="app.common.ok" />
                    </Button>
                </DialogActions>
                <Dialog
                    open={showAlertDialog}
                    keepMounted
                    onClose={ e => { setShowAlertDialog(false); }}
                    aria-labelledby="alert-dialog-slide-title"
                    aria-describedby="alert-dialog-slide-description"
                >
                    <DialogTitle id="alert-dialog-slide-title">{intl.formatMessage({ id: 'app.common.warning' })}</DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-slide-description">
                            <FormattedMessage id="app.common.incompleteFormWarning" />
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={ e => { setShowAlertDialog(false); }} color="secondary">
                            {intl.formatMessage({ id: 'app.common.ok' })}
                        </Button>
                    </DialogActions>
                </Dialog>
            </Dialog>
        </div>
  );
}

export default injectIntl(ResetPasswordDialog);
