import { PhrasesSongSubSection, RepeatSongSubSection } from './SubSection/SongSubSection';

export class SongSection {
  constructor (id, instrument, tags, sectionKey, subSectionsJson) {
    this.id = id;
    this.instrument = instrument;
    this.tags = tags;
    this.sectionKey = sectionKey;
    this.subSections = [];
    this.subSectionNextId = 0;

    const self = this;
    subSectionsJson.forEach(function (element) {
      if ((element.type === 'phrases') || (element.type === undefined)) {
        self.addPhrasesSubSection(element.description, element.phrases, element.startingTime, element.endingTime);
      } else if (element.type === 'repeat') {
        const subSectionToRepeat = self.getSubSectionByKey(element.songSubSectionKey);
        self.addRepeatSubSection(subSectionToRepeat, element.startingTime, element.endingTime);
      }
    });
  }

  getSubSections () {
    return this.subSections;
  }

  equals (section) {
    return this.id === section.id;
  }

  getSubSectionNewID () {
    // return Date.now() + Math.random();
    const newId = this.subSectionNextId + (this.id * 100);
    this.subSectionNextId++;
    return newId;
  }

  addPhrasesSubSection (description = '', phrases = [], startingTime = null, endingTime = null) {
    const newSubSection = new PhrasesSongSubSection(this, this.getSubSectionNewID(), description, phrases, startingTime, endingTime);
    this.subSections.push(newSubSection);
    return newSubSection;
  }

  addRepeatSubSection (subSectionToRepeat, startingTime = null, endingTime = null) {
    const newSubSection = new RepeatSongSubSection(this, this.getSubSectionNewID(), subSectionToRepeat, startingTime, endingTime);
    this.subSections.push(newSubSection);
    return newSubSection;
  }

  hasSubSectionInKey (subSectionKey) {
    return typeof this.subSections[subSectionKey] !== 'undefined';
  }

  getSubSectionByKey (subSectionKey) {
    if (this.hasSubSectionInKey(subSectionKey)) {
      return this.subSections[subSectionKey];
    } else {
      throw new Error('No existe la subsección con índice ' + subSectionKey);
    }
  }
}
