import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import LoginView from '../components/LoginView/LoginView';
import { login, sendPasswordResetEmail } from '../store/actions/sessionActions';

function mapStateToProps (state, ownProps) {
  return {};
}

const mapDispatchToProps = dispatch => bindActionCreators({
  handleLogin: login,
  handleSendPasswordResetEmail: sendPasswordResetEmail
}, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(LoginView);
