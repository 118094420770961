
export class ArtistDTO {
  constructor (key = '', name = '', image = '') {
    this.key = key;
    this.name = name;
    this.image = image;
  }

  getKey () {
    return this.key;
  }

  getName () {
    return this.name;
  }

  getImage () {
    return this.image;
  }

  setName (name) {
    this.name = name;
  }

  setImage (image) {
    this.image = image;
  }
}
