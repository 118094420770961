import ChordSheetJS from 'chordsheetjs';

class Parser {
  parse (songPhrase) {
    // replace character ' ' to Unicode. https://stackoverflow.com/questions/24432576/reactjs-render-string-with-non-breaking-spaces
    songPhrase = songPhrase.replace(/ /g, '\u00a0');
    songPhrase = songPhrase.replace(/\t/g, '\u00a0\u00a0\u00a0\u00a0\u00a0\u00a0\u00a0\u00a0');
    return songPhrase;
  }
}

export class ChordSheetParser extends Parser {
  constructor () {
    super();
    this.adaptee = new ChordSheetJS.ChordSheetParser();
  }

  parse (songPhrase) {
    /*
         A phrase may have empty (e.g. a subsection for "guitar solo", just for timing purpose)
        ChordSheetJS throws an exception when trying to parse
        */
    if (songPhrase === '') return songPhrase;

    songPhrase = super.parse(songPhrase);

    /**
         * this is a hack. When in the last line of the description we have something like this: " C    F    G "
         * ChordSheetJS.ChordSheetParser doesn't recognize the chords.
         */
    songPhrase = songPhrase + '\r\n';

    /**
         * TODO: chordsheet does not like "-", example: "B - Bb - A"
         */
    return this.adaptee.parse(songPhrase);
  }
}

export class ChordProParser extends Parser {
  constructor () {
    super();
    this.adaptee = new ChordSheetJS.ChordProParser();
  }

  parse (songPhrase) {
    /*
         A phrase may have empty (e.g. a subsection for "guitar solo", just for timing purpose)
        ChordSheetJS throws an exception when trying to parse
        */
    if (songPhrase === '') return songPhrase;

    songPhrase = super.parse(songPhrase);

    try {
      /**
             * In chordpro '{}' are used to define metatags.
             * If user write something like:
             * { 2}***
             * chordpro will raise an error.
             */
      songPhrase = this.adaptee.parse(songPhrase);
    } catch (e) {
      console.log(e);
    }
    return songPhrase;
  }
}
