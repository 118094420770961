import {
  FETCH_SONGS_TO_STRENGTHEN_ERROR,
  FETCH_SONGS_TO_STRENGTHEN_SUCCESS,
  FETCH_SONGS_TO_STRENGTHEN_PENDING
} from '../actions/strengthenSongsActions';
import { getRawSongs, getSongsFetched } from './songsReducer';
import { getArtistById, getArtists } from './artistsReducer';

const initialState = {
  songsToStrengthenPending: false,
  songsToStrengthen: [],
  songsToStrengthenError: null
};

const strengthenSongsReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_SONGS_TO_STRENGTHEN_PENDING:
      return {
        ...state,
        songsToStrengthenPending: true
      };
    case FETCH_SONGS_TO_STRENGTHEN_SUCCESS:
      return {
        ...state,
        songsToStrengthenPending: false,
        songsToStrengthenError: null,
        songsToStrengthen: action.songs
      };
    case FETCH_SONGS_TO_STRENGTHEN_ERROR:
      return {
        ...state,
        songsToStrengthenPending: false,
        songsToStrengthen: [],
        songsToStrengthenError: action.error
      };
    default:
      return state;
  }
};

export default strengthenSongsReducer;

export const getSongsToStrengthenError = state => state.strengthenSongs.songsToStrengthenError;
export const getSongsToStrengthenPending = (state) => !getSongsFetched(state) || state.strengthenSongs.songsToStrengthenPending;
export const getSongsToStrengthen = state => {
  const songs = getRawSongs(state);
  if (songs.length === 0) return [];

  const songsToStrengthen = [];
  state.strengthenSongs.songsToStrengthen.forEach(function (songStrength) {
    const song = songs.find((song) => song.id === songStrength.songId);
    const artist = getArtistById(getArtists(state), song.artistId);
    const strength = songStrength.strength;

    songsToStrengthen.push({
      song,
      artist,
      strength
    });
  });
  return songsToStrengthen;
};
