import React from "react";
import {Container} from "@material-ui/core";
import LoginForm from "./LoginForm";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import {FormattedMessage} from "react-intl";

class LoginView extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            formModel: {
                email: "",
                password: '',
            }
        };
    }

    login = () => {
        const {email, password} = this.state.formModel;
        this.props.handleLogin(email, password);
    }

    resetPassword = (emailAddress) => {
        this.props.handleSendPasswordResetEmail(emailAddress);
    }

    render() {
        return (
            <Container maxWidth="lg">
                <Grid container spacing={1}>
                    <Grid item xs={12} sm={2}>
                    </Grid>
                    <Grid item xs={12} sm={8}>
                        <Typography component="h2" variant="h6" gutterBottom>
                            <FormattedMessage id="app.session.login"/>
                        </Typography>
                        <LoginForm
                            formModel={this.state.formModel}
                            onSubmit={this.login}
                            onResetPassword={this.resetPassword}
                        />
                    </Grid>
                </Grid>
            </Container>
        )
    }
}

export default LoginView;