import React from 'react';
import CustomSelect from './CustomSelect';

class InstrumentTypeSelect extends React.Component {
  render () {
    const autoWidth = (this.props.hasOwnProperty('autoWidth')) ? this.props.autoWidth : true;

    const instruments = this.props.instruments;
    const options = Object.keys(instruments).map(function (instrumentKey) {
      return { key: instrumentKey, value: instruments[instrumentKey].name };
    });

    return (
            <CustomSelect
                label={this.props.label}
                name={this.props.name}
                id={this.props.id}
                error={this.props.error}
                options={options}
                value={this.props.value}
                margin={this.props.margin}
                style={this.props.style}
                InputLabelProps={this.props.InputLabelProps}
                autoWidth={autoWidth}
                onChange={this.props.onChange}
                required={this.props.required}
            />
    );
  }
}

export default InstrumentTypeSelect;
