import React from 'react';
import Alert from '@material-ui/lab/Alert';
import { Box, Button } from '@material-ui/core';
import SendIcon from '@material-ui/icons/Send';

import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { resendEmailVerification } from '../store/actions/sessionActions';

const EmailNotVerifiedMessage = function (props) {
  return (
        <Box m={2}>
            <Alert
                severity="info"
                variant={'filled'}
                action={
                    <Button variant="outlined" size="small" endIcon={<SendIcon/>} onClick={props.resendEmailVerificationHandler}>
                        <FormattedMessage id="app.session.resendValidationEmail" />
                    </Button>
                }
            >
                <FormattedMessage id="app.session.emailNotVerified" />
            </Alert>
        </Box>
  );
};

function mapStateToProps (state, ownProps) {
  return {};
}

const mapDispatchToProps = dispatch => bindActionCreators({
  resendEmailVerificationHandler: resendEmailVerification
}, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(EmailNotVerifiedMessage);
