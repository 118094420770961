import React from "react";
import PropTypes from 'prop-types';

import FormReproductionControls from "./FormReproductionControls";
import SongForm from "./SongForm";
import './SongForm.css';

class SongFormWidget extends React.PureComponent {

    constructor(props) {
        super(props);

        this.state = {
            loopStartingTime: null,
            loopEndingTime: null,
            inferredSongDuration: null,
            videoId: this.props.song.videoId
        };
    }

    changeLoopTimesHandler = (startingTime, endingTime) => {
        this.setState({
            loopStartingTime: startingTime,
            loopEndingTime: endingTime
        });
    }

    changeDurationFormHandler = seconds => {
        this.setState({
            inferredSongDuration: seconds
        });
    }

    changeVideoIdFormHandler = videoId => {
        this.setState({
            videoId: videoId
        });
    }

    render() {
        const {availableInstrumentsType, availableTags, artists, song, onCancel, title, onSave, onSaveAndExit} = this.props;
        const {inferredSongDuration, videoId, loopStartingTime, loopEndingTime} = this.state;
        return (
            <div>
                <SongForm
                    title={title}
                    song={song}
                    inferredSongDuration={inferredSongDuration}
                    artists={artists}
                    availableInstrumentsType={availableInstrumentsType}
                    availableTags={availableTags}
                    onCancel={onCancel}
                    onSave={onSave}
                    onSaveAndExit={onSaveAndExit}
                    changeVideoIdFormHandler={this.changeVideoIdFormHandler}
                    changeLoopTimesHandler={this.changeLoopTimesHandler}
                    />
                <FormReproductionControls
                    videoId={videoId}
                    loopStartingTime={loopStartingTime}
                    loopEndingTime={loopEndingTime}
                    changeDurationFormHandler={this.changeDurationFormHandler}
                />
            </div>
        );
    }
}

SongFormWidget.propTypes = {
    song: PropTypes.object.isRequired,
    title: PropTypes.string.isRequired,
    availableInstrumentsType: PropTypes.object.isRequired,
    availableTags: PropTypes.arrayOf(PropTypes.string).isRequired,
    artists: PropTypes.object.isRequired,
    onSave: PropTypes.func,
    onSaveAndExit: PropTypes.func.isRequired,
    onCancel: PropTypes.func.isRequired,
};

export default SongFormWidget;
