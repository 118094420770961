
import React from 'react';
import ListItemText from '@material-ui/core/ListItemText';
import ListItem from '@material-ui/core/ListItem';

class RepeatedPhraseView extends React.Component {
  render () {
    const phrase = this.props.phrase;

    let phraseDescription = phrase.description;

    // reemplazo caracter ' ' a Unicode. https://stackoverflow.com/questions/24432576/reactjs-render-string-with-non-breaking-spaces
    phraseDescription = phraseDescription.replace(/ /g, '\u00a0');
    phraseDescription = phraseDescription.replace(/\t/g, '\u00a0\u00a0\u00a0\u00a0\u00a0\u00a0\u00a0\u00a0');
    return (
            <ListItem className="phraseDescriptionHolder">
                <ListItemText
                    primary={ phraseDescription }
                    secondary={ phrase.help }
                    className="Phrase"
                />
            </ListItem>
    );
  }
}

export default RepeatedPhraseView;
