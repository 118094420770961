import { connect } from 'react-redux';
import {
  getSongsToStrengthen,
  getSongsToStrengthenError,
  getSongsToStrengthenPending
} from '../store/reducers/strengthenSongsReducer';
import { bindActionCreators } from 'redux';
import { fetchSongsToStrengthen } from '../store/actions/strengthenSongsActions';

import TrainingView from '../components/TrainingView/TrainingView';
import { getSongsPending } from '../store/reducers/songsReducer';
import { SongToStrengthenDTO } from '../components/TrainingView/DTO/SongToStrengthenDTO';

function mapStateToProps (state, ownProps) {
  const songsToStrengthenFromState = getSongsToStrengthen(state);

  const songsToStrengthenDTO = [];
  songsToStrengthenFromState.forEach(function (songToStrengthen) {
    const { song, artist, strength } = songToStrengthen;
    songsToStrengthenDTO.push(new SongToStrengthenDTO(song.id, song.title, artist.name, strength));
  });

  return {
    songsPending: getSongsPending(state),
    songsToStrengthenError: getSongsToStrengthenError(state),
    songsToStrengthenDTO,
    songsToStrengthenPending: getSongsToStrengthenPending(state)
  };
}

const mapDispatchToProps = dispatch => bindActionCreators(
  {
    fetchSongsToStrengthen
  }, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(TrainingView);
