import React from 'react';

import TimerIcon from "@material-ui/icons/Timer";

import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";
import FormControl from '@material-ui/core/FormControl';

import InputLabel from "@material-ui/core/InputLabel";
import Input from "@material-ui/core/Input";
import InputAdornment from "@material-ui/core/InputAdornment";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import TextField from "@material-ui/core/TextField";
import toMinutesSecondsFormat, {extractMinutes, extractSeconds} from "../../utils/time";
import {FormattedMessage} from "react-intl";
import {injectIntl} from "react-intl";

class SongTimePicker extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            open: false,
            inputMinutesVal: extractMinutes(props.value),
            inputSecondsVal: extractSeconds(props.value),
            inputMinutesError: false,
            inputSecondsError: false,
        };
    }

    formatInputValue(seconds) {
        if ((seconds === "") || seconds === null) {
            return "--:--";
        }

        return toMinutesSecondsFormat(seconds);
    }

    toSecondsFormat(minutes, seconds) {
        return parseInt(minutes * 60) + parseInt(seconds);
    }

    onChangeMinutes = (event) => {
        this.setState({
            inputMinutesVal: event.target.value
        });
    }

    onChangeSeconds = (event) => {
        this.setState({
            inputSecondsVal: event.target.value
        });
    }

    handleAccept = () => {
        let {inputMinutesVal, inputSecondsVal} = this.state;

        let valid = true;

        if (inputMinutesVal === "") {
            valid = false;
            this.setState({
                inputMinutesError: true
            });
        }

        if (inputSecondsVal === "") {
            valid = false;
            this.setState({
                inputSecondsError: true
            });
        }

        if (valid) {
            if (this.props.onChange) {
                this.props.onChange(this.toSecondsFormat(inputMinutesVal, inputSecondsVal));
            }

            this.setState({
                inputMinutesError: false,
                inputSecondsError: false,
            });

            this.handleClose();
        }
    }

    handleOpen = () => {
        this.setState({
            open: true
        });
    }

    handleClose = () => {
        this.setState({
            open: false
        });
    }

    render() {
        let {value, intl} = this.props;

        let required = this.props.hasOwnProperty("required") ? this.props.required : false;
        let error = this.props.hasOwnProperty("error") ? this.props.error : false;

        return <FormControl>
            <InputLabel required={required} error={error}>{this.props.label}</InputLabel>
            <Input
                disabled
                error={error}
                value={this.formatInputValue(value)}
                endAdornment={
                    <InputAdornment position="end">
                        <IconButton
                            aria-label="set time"
                            onClick={this.handleOpen}
                        >
                            <TimerIcon/>
                        </IconButton>
                    </InputAdornment>
                }
            />

            <Dialog open={this.state.open} onClose={this.handleClose} aria-labelledby="form-dialog-title"
                    className="song-time-picker-dialog">
                <DialogTitle id="form-dialog-title">{this.props.label}</DialogTitle>
                <DialogContent>
                    <TextField
                        autoFocus
                        label={intl.formatMessage({id: 'app.songform.widget.songtimepicker.minutesAbbr'})}
                        margin="dense"
                        type="number"
                        id="minutes"
                        required
                        error={this.state.inputMinutesError}
                        value={this.state.inputMinutesVal}
                        onChange={this.onChangeMinutes}
                        inputProps={{min: "0", max: "99", step: "1"}}
                    />

                    <TextField
                        label={intl.formatMessage({id: 'app.songform.widget.songtimepicker.secondsAbbr'})}
                        margin="dense"
                        type="number"
                        id="seconds"
                        required
                        error={this.state.inputSecondsError}
                        value={this.state.inputSecondsVal}
                        onChange={this.onChangeSeconds}
                        inputProps={{min: "0", max: "59", step: "1"}}
                        style={{marginLeft: '5px'}}
                    />

                </DialogContent>
                <DialogActions>
                    <Button onClick={this.handleClose} color="secondary">
                        <FormattedMessage id="app.common.cancel"/>
                    </Button>
                    <Button onClick={this.handleAccept} color="secondary">
                        <FormattedMessage id="app.common.ok"/>
                    </Button>
                </DialogActions>
            </Dialog>
        </FormControl>;
    }
}

export default injectIntl(SongTimePicker);
