import React from "react";

import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';

import Button from '@material-ui/core/Button';
import Grid from "@material-ui/core/Grid";
import {FormattedMessage, injectIntl} from "react-intl";
import PropTypes from "prop-types";
import {Box} from "@material-ui/core";
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import IconButton from '@material-ui/core/IconButton';
import InputAdornment from '@material-ui/core/InputAdornment';
import InputLabel from '@material-ui/core/InputLabel';
import Input from '@material-ui/core/Input';
import FormControl from '@material-ui/core/FormControl';
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogActions from "@material-ui/core/DialogActions";

class ChangePasswordForm extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            formModel: {
                currentPassword: "",
                newPassword: "",
                newPasswordConfirmation: "",
            },
            showAlertDialog: false,
            alertDialogMessage: '',
            showPassword: {
                currentPassword: false,
                newPassword: false,
                newPasswordConfirmation: false
            }
        };
    }

    changeFormHandler = event => {

        const name = event.target.name;
        const value = event.target.value;

        let formModel = this.state.formModel;
        formModel[name] = value;

        this.setState({
            formModel: formModel
        });
    }

    handleMouseDownPassword = (event) => {
        let currentShowPasswordState = this.state.showPassword;
        currentShowPasswordState[event.currentTarget.name] = ! currentShowPasswordState[event.currentTarget.name];
        this.setState(
            {
                showPassword: currentShowPasswordState
            }
        )
    };

    handleCloseAlertDialog = () => {
        this.setState({showAlertDialog: false} );
    }

    handleSubmit = (event) => {
        event.preventDefault();

        let { currentPassword, newPassword, newPasswordConfirmation } = this.state.formModel;
        const { intl } = this.props;

        currentPassword = currentPassword.trim();
        newPassword = newPassword.trim();
        newPasswordConfirmation = newPasswordConfirmation.trim();

        if (newPassword === '' || currentPassword === '') {
            this.setState({showAlertDialog: true, alertDialogMessage: intl.formatMessage({id: 'app.common.incompleteFormWarning'}) } );
            return;
        }

        if (newPassword !== newPasswordConfirmation) {
            this.setState({showAlertDialog: true, alertDialogMessage: intl.formatMessage({id: 'app.profile.changePassword.form.passwordDoNotMatch'}) } );
            return;
        }

        this.props.onSubmit(currentPassword, newPassword, this.onSubmitSuccess);
    }

    onSubmitSuccess = () => {
        this.setState({
            formModel: {
                currentPassword: "",
                newPassword: "",
                newPasswordConfirmation: "",
            }
        });
    }

    render() {

        const {formModel, showPassword, showAlertDialog, alertDialogMessage} = this.state;
        const {intl} = this.props;

        return (
            <Card>
                <CardContent>
                    <form>
                        <Grid>
                            <Grid item xs>
                                <FormControl>
                                    <InputLabel htmlFor="standard-adornment-password-1"><FormattedMessage id="app.profile.changePassword.form.currentPassword"/></InputLabel>
                                    <Input
                                        id="standard-adornment-password-1"
                                        type={showPassword.currentPassword ? 'text' : 'password'}
                                        name="currentPassword"
                                        value={formModel.currentPassword}
                                        onChange={this.changeFormHandler}
                                        required
                                        endAdornment={
                                            <InputAdornment position="end">
                                                <IconButton
                                                    aria-label="toggle password visibility"
                                                    name="currentPassword"
                                                    style={{padding: 0}}
                                                    onMouseDown={this.handleMouseDownPassword}
                                                >
                                                    {showPassword.currentPassword ? <Visibility /> : <VisibilityOff />}
                                                </IconButton>
                                            </InputAdornment>
                                        }
                                    />
                                </FormControl>
                            </Grid>
                            <Grid item xs>
                                <FormControl>
                                    <InputLabel htmlFor="standard-adornment-password-2"><FormattedMessage id="app.profile.changePassword.form.newPassword"/></InputLabel>
                                    <Input
                                        id="standard-adornment-password-2"
                                        type={showPassword.newPassword ? 'text' : 'password'}
                                        name="newPassword"
                                        value={formModel.newPassword}
                                        onChange={this.changeFormHandler}
                                        required
                                        endAdornment={
                                            <InputAdornment position="end">
                                                <IconButton
                                                    aria-label="toggle password visibility"
                                                    name="newPassword"
                                                    style={{padding: 0}}
                                                    onMouseDown={this.handleMouseDownPassword}
                                                >
                                                    {showPassword.newPassword ? <Visibility /> : <VisibilityOff />}
                                                </IconButton>
                                            </InputAdornment>
                                        }
                                    />
                                </FormControl>
                            </Grid>
                            <Grid item xs>
                                <FormControl>
                                    <InputLabel htmlFor="standard-adornment-password-3"><FormattedMessage id="app.profile.changePassword.form.newPasswordConfirmation"/></InputLabel>
                                    <Input
                                        id="standard-adornment-password-3"
                                        type={showPassword.newPasswordConfirmation ? 'text' : 'password'}
                                        name="newPasswordConfirmation"
                                        value={formModel.newPasswordConfirmation}
                                        onChange={this.changeFormHandler}
                                        required
                                        endAdornment={
                                            <InputAdornment position="end">
                                                <IconButton
                                                    aria-label="toggle password visibility"
                                                    name="newPasswordConfirmation"
                                                    style={{padding: 0}}
                                                    onMouseDown={this.handleMouseDownPassword}
                                                >
                                                    {showPassword.newPasswordConfirmation ? <Visibility /> : <VisibilityOff />}
                                                </IconButton>
                                            </InputAdornment>
                                        }
                                    />
                                </FormControl>
                            </Grid>
                            <Dialog
                                open={showAlertDialog}
                                keepMounted
                                onClose={this.handleCloseAlertDialog}
                                aria-labelledby="alert-dialog-slide-title"
                                aria-describedby="alert-dialog-slide-description"
                            >
                                <DialogTitle id="alert-dialog-slide-title">{intl.formatMessage({id: 'app.common.warning'})}</DialogTitle>
                                <DialogContent>
                                    <DialogContentText id="alert-dialog-slide-description">
                                        {alertDialogMessage}
                                    </DialogContentText>
                                </DialogContent>
                                <DialogActions>
                                    <Button onClick={this.handleCloseAlertDialog} color="secondary">
                                        {intl.formatMessage({id: 'app.common.ok'})}
                                    </Button>
                                </DialogActions>
                            </Dialog>
                        </Grid>
                    </form>

                    <Box display="flex" justifyContent="flex-end">
                        <Button size="small" onClick={this.handleSubmit} color="secondary" variant={"contained"} >
                            <FormattedMessage id="app.common.ok"/>
                        </Button>
                    </Box>
                </CardContent>
            </Card>
        );
    }
}

ChangePasswordForm.propTypes = {
    onSubmit: PropTypes.func.isRequired
}
export default injectIntl(ChangePasswordForm);