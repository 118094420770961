import { FETCH_STATS_ERROR, FETCH_STATS_PENDING, FETCH_STATS_SUCCESS } from '../actions/statsActions';

const initialState = {
  statsPending: false,
  statsError: null,
  monthlyStats: []
};

const statsReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_STATS_PENDING:
      return {
        ...state,
        statsPending: true
      };
    case FETCH_STATS_SUCCESS:
      return {
        ...state,
        statsPending: false,
        statsError: null,
        monthlyStats: action.stats
      };
    case FETCH_STATS_ERROR:
      return {
        ...state,
        statsPending: false,
        monthlyStats: [],
        statsError: action.error
      };
    default:
      return state;
  }
};

export default statsReducer;

export const getMonthlyStats = state => state.stats.monthlyStats;
export const getMonthlyStatsError = state => state.stats.statsError;
