import { SongSection } from './Section/SongSection';

export class SongDTO {
  constructor (id = '', instrumentTypeName = '', artistId = '', artistName = '', title = '', videoId = '', duration = '', tempo = '', sectionsJson = []) {
    this.key = id;
    this.instrumentTypeName = instrumentTypeName;
    this.artistId = artistId;
    this.artistName = artistName;
    this.title = title;
    this.videoId = videoId;
    this.duration = duration;
    this.tempo = tempo;

    this.sections = [];

    const self = this;
    sectionsJson.forEach(function (element) {
      self.addSection(element.instrument, element.tags, element.sectionKey, element.subSections);
    });

    this.flattenSubSections = [].concat.apply([], this.sections.map((section) => section.subSections));
  }

  getSections () {
    return this.sections;
  }

  getAllSubSections () {
    return this.flattenSubSections;
  }

  getAllSubSectionsWithStartingTime () {
    return this.flattenSubSections.filter((subSection) => subSection.hasStartingTime());
  }

  getSubSectionAtTime (time) {
    return this.getAllSubSectionsWithStartingTime().reverse().find((subSection) => subSection.startingTime <= time);
  }

  getPriorSubSectionAtTime (time) {
    const priorSubSections = this.getAllSubSectionsWithStartingTime().filter((subSection) => subSection.startingTime < time);
    return priorSubSections[priorSubSections.length - 2];
  }

  getNextSubSectionAtTime (time) {
    return this.getAllSubSectionsWithStartingTime().find((subSection) => subSection.startingTime > time);
  }

  addSection (instrument = '', tags = [], sectionKey = '', subSections = []) {
    const newSection = new SongSection(this.sections.length, instrument, tags, sectionKey, subSections);

    this.sections.push(newSection);

    return newSection;
  }

  getSection (sectionKey) {
    return this.sections[sectionKey];
  }

  getDuration () {
    return this.duration;
  }

  getTempo () {
    return this.tempo;
  }

  getVideoId () {
    return this.videoId;
  }

  getTitle () {
    return this.title;
  }
}
