import React, {Component} from "react";
import Grid from "@material-ui/core/Grid";
import PlayButton from "../Buttons/PlayButton";
import PauseButton from "../Buttons/PauseButton";
import StopButton from "../Buttons/StopButton";
import PlaybackRateSelect from "./PlaybackRateSelect";
import ReproductionControls from "../ReproductionControls";
import {Reproduction} from "../../domain/Reproduction/Reproduction";
import ReproductionPlayer from "../ReproductionPlayer";
import toMinutesSecondsFormat from "../../utils/time";
import Timeline from "./Timeline";
import ButtonGroup from "@material-ui/core/ButtonGroup";
import Button from "@material-ui/core/Button";
import ZoomOutIcon from "@material-ui/icons/ZoomOut";
import ZoomInIcon from "@material-ui/icons/ZoomIn";
import {Chip} from "@material-ui/core";
import { withTheme } from '@material-ui/core/styles';

class FormReproductionControls extends Component {
    constructor(props) {
        super(props);

        const selectedTimeRange = (props.loopStartingTime !== null) ? [props.loopStartingTime, props.loopEndingTime] : [];
        this.state = {
            reproduction: this.createReproductionObject(),
            selectedTimeRange: selectedTimeRange,
            timelineZoomLevel: 0,
            mountPlayer: true
        }
    }

    createReproductionObject() {
        let reproduction = Reproduction
            .newBuilder()
            .withTrainingMode(true)
            .createReproduction();
        reproduction.on(Reproduction.EVENTS.READY, this.youTubePlayerReady);
        reproduction.on(Reproduction.EVENTS.PLAYING, this.refreshReproduction);
        reproduction.on(Reproduction.EVENTS.FINISH, this.refreshReproduction);

        return reproduction;
    }

    componentDidUpdate(prevProps) {
        const {loopStartingTime, videoId} = this.props;
        if (loopStartingTime !== prevProps.loopStartingTime) {

            let {reproduction} = this.state;
            if (loopStartingTime == null) {
                reproduction.seekTo(0);
            } else {
                reproduction.seekTo(loopStartingTime);
            }

            const selectedTimeRange = (this.props.loopStartingTime !== null) ? [this.props.loopStartingTime, this.props.loopEndingTime] : [];
            this.setState(state => ({
                reproduction: reproduction,
                selectedTimeRange: selectedTimeRange,
            }));
        }
        if (videoId !== prevProps.videoId) {
            this.setState(state => ({
                mountPlayer: false
            }), () => {
                this.setState(state => ({
                    reproduction: this.createReproductionObject(),
                    mountPlayer: true
                }));
            });
        }
    }

    youTubePlayerReady = () => {
        this.props.changeDurationFormHandler(this.state.reproduction.getPlayer().getDuration());
    }

    handlePlayClick = () => {
        let {reproduction, selectedTimeRange} = this.state;

        if ((selectedTimeRange.length === 2) && (reproduction.getCurrentTime() < selectedTimeRange[0] || reproduction.getCurrentTime() >= selectedTimeRange[1])) {
            reproduction.seekTo(selectedTimeRange[0]);
        }
        reproduction.play();
        this.setState(state => ({
            reproduction: reproduction,
        }));
    }

    handlePauseClick = () => {
        let {reproduction} = this.state;
        reproduction.pause();
        this.setState(state => ({
            reproduction: reproduction,
        }));
    }

    handleStopClick = () => {
        const {selectedTimeRange} = this.state;

        let {reproduction} = this.state;
        reproduction.stop();
        if (selectedTimeRange.length === 2) {
            reproduction.seekTo(selectedTimeRange[0]);
        }
        this.setState(state => ({
            reproduction: reproduction,
        }));
    }

    refreshReproduction = () => {
        let {reproduction} = this.state;
        const {selectedTimeRange} = this.state;

        if ((selectedTimeRange.length === 2) && (reproduction.getCurrentTime() < selectedTimeRange[0] || reproduction.getCurrentTime() >= selectedTimeRange[1])) {
            reproduction.seekTo(selectedTimeRange[0]);
        }

        this.setState(state => ({
            reproduction: reproduction,
        }));
    }

    handlePlaybackRate = (rate) => {
        let {reproduction} = this.state;
        reproduction.setPlaybackRate(rate);
    }

    handleCurrentTimeChange = (value) => {
        let {reproduction} = this.state;
        reproduction.seekTo(value);
        this.setState(state => ({
            reproduction: reproduction,
        }));
    }

    handleLoopRangeChange = (value) => {
        this.setState(state => ({
            selectedTimeRange: value,
        }));
    }

    handleRemoveLoopRange = () => {
        this.setState(state => ({
            selectedTimeRange: [],
        }));
    }

    handleZoomInButtonClick = () => {
        this.setState(state => ({
            timelineZoomLevel: state.timelineZoomLevel + 1,
        }));
    }

    handleZoomOutButtonClick = () => {
        this.setState(state => ({
            timelineZoomLevel: state.timelineZoomLevel - 1,
        }));
    }

    render() {
        let {reproduction, timelineZoomLevel, selectedTimeRange} = this.state;
        const {videoId, theme} = this.props;
        let duration = reproduction.getPlayer().getDuration();

        return <ReproductionControls visibility={true}>
            <Grid container className={'ReproductionControlsMain'}>
                <Grid item xs={1}>
                    {reproduction.isAvailable() && (
                        <>
                            <div>
                                <PlayButton onClick={this.handlePlayClick} hidden={reproduction.isPlaying()}/>
                                <PauseButton onClick={this.handlePauseClick} hidden={!reproduction.isPlaying()}/>
                                <StopButton onClick={this.handleStopClick} hidden={reproduction.isStopped()}/>
                            </div>
                            <div>
                                <PlaybackRateSelect
                                    rates={reproduction.getAvailablePlaybackRates()}
                                    onChange={this.handlePlaybackRate}
                                />
                            </div>
                        </>
                    )}
                </Grid>
                <Grid item xs={10}>
                    {reproduction.isAvailable() && (
                        <div style={{position: "relative"}}>
                            <span style={{ position: "absolute", right: "12px", top: "10px", color: theme.palette.tertiary }} className='CurrentTime'>
                                {toMinutesSecondsFormat(reproduction.getCurrentTime())}
                            </span>
                            <Timeline
                                duration={duration}
                                value={reproduction.getCurrentTime()}
                                selectedRange={selectedTimeRange}
                                zoomLevel={timelineZoomLevel}
                                onChange={this.handleCurrentTimeChange}
                                onRangeChange={this.handleLoopRangeChange}
                            />
                        </div>
                    )}
                </Grid>
                <Grid item xs={1}>
                    {reproduction.isAvailable() && (
                        <>
                            <div style={{ height: "50px", paddingTop: "10px" }}>
                                {
                                    selectedTimeRange.length === 2 && (
                                        <Chip
                                            size="small"
                                            label={"[" + toMinutesSecondsFormat(selectedTimeRange[0]) + " - " + toMinutesSecondsFormat(selectedTimeRange[1]) + "]"}
                                            onDelete={this.handleRemoveLoopRange}
                                            color="secondary"
                                        />
                                    )
                                }
                            </div>
                            <div>
                                <ButtonGroup size="small">
                                    <Button disabled={timelineZoomLevel === 0} onClick={this.handleZoomOutButtonClick}><ZoomOutIcon fontSize="small"/></Button>
                                    <Button disabled={timelineZoomLevel === 9} onClick={this.handleZoomInButtonClick}><ZoomInIcon fontSize="small"/></Button>
                                </ButtonGroup>
                            </div>
                        </>
                    )}
                </Grid>
            </Grid>
            <Grid container>
                <Grid item xs={12} style={{paddingTop: '60px'}}>
                    {
                        this.state.mountPlayer && (
                            <ReproductionPlayer
                                videoId={videoId}
                                model={reproduction.player}
                            />
                        )
                    }
                </Grid>
            </Grid>
        </ReproductionControls>;
    }
}
export default withTheme(FormReproductionControls);
