import React from "react";

import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';

import TextField from "@material-ui/core/TextField";
import Button from '@material-ui/core/Button';
import Grid from "@material-ui/core/Grid";
import {FormattedMessage} from "react-intl";
import {Box} from "@material-ui/core";
import ResetPasswordDialog from "./ResetPasswordDialog";


class LoginForm extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            formModel: props.formModel,
            showResetPasswordDialog: false
        };
    }

    changeFormHandler = event => {

        const name = event.target.name;
        const value = event.target.value;

        let formModel = this.state.formModel;
        formModel[name] = value;

        this.setState({
            formModel: formModel
        });
    }

    toggleResetPasswordDialogVisibility = (event) => {
        this.setState({
            showResetPasswordDialog: ! this.state.showResetPasswordDialog
        })
    }

    resetPassword = (emailAddress) => {
        this.props.onResetPassword(emailAddress);
        this.toggleResetPasswordDialogVisibility();
    }

    handleSubmit = (event) => {
        event.preventDefault();

        let formModel = this.state.formModel;

        if ((formModel.email !== '') && (formModel.password !== '')) {
            this.props.onSubmit();
        }
    }

    render() {
        const {formModel} = this.state;

        if (formModel) {
            return (
                <Card>
                    <CardContent>
                        <form>
                            <Grid container>
                                <Grid item xs={12}>
                                    <TextField
                                        label="Email"
                                        name="email"
                                        value={formModel.email}
                                        margin="normal"
                                        style={{marginRight: 3}}
                                        onChange={this.changeFormHandler}
                                        required
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField
                                        label="Password"
                                        name="password"
                                        value={formModel.password}
                                        margin="normal"
                                        style={{marginRight: 3}}
                                        onChange={this.changeFormHandler}
                                        required
                                        type="password"
                                    />
                                </Grid>
                                <Grid item xs={8}>
                                    <Box m={2} style={{ textAlign: "left" }}>
                                        <Button onClick={this.toggleResetPasswordDialogVisibility} color="secondary">
                                            <FormattedMessage id="app.session.forgotPassword"/>
                                        </Button>
                                        <ResetPasswordDialog
                                            visible={this.state.showResetPasswordDialog}
                                            onClose={this.toggleResetPasswordDialogVisibility}
                                            onResetPassword={this.resetPassword}
                                        />
                                    </Box>
                                </Grid>
                                <Grid item xs={4}>
                                    <Box m={2} style={{ textAlign: "right" }}>
                                        <Button size="small" onClick={this.handleSubmit} variant={"contained"} color="secondary">
                                            <FormattedMessage id="app.common.ok"/>
                                        </Button>
                                    </Box>
                                </Grid>
                            </Grid>
                        </form>
                    </CardContent>
                </Card>
            );
        }

        return null;
    }
}

export default LoginForm;
