import React from "react";
import {Redirect} from "react-router-dom";

import SongForm from "../SongForm/SongFormWidget";
import {compose} from "redux";
import WithLoading from "../Reusable/WithLoading/WithLoading";
import WithErrorHandling from "../Reusable/WithErrorHandling/WithErrorHandling";

import './SongCreationView.css';

const ComposedSongForm = compose(
    WithLoading,
    WithErrorHandling
)(SongForm);

export default class SongCreationView extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            actionComplete: false
        };
    }

    createSong = (song) => {
        let {createSongForArtist} = this.props;
        createSongForArtist(song);
    }

    onSaveAndExit = (song) => {
        this.createSong(song);
        this.setState({
            actionComplete: true
        });
    }

    renderRedirect = () => {
        const {actionComplete} = this.state;

        if (actionComplete) {
            let route;
            const artistId = this.props.songFormDTO.artistId;
            if (artistId === '') {
                route = '/songs/list';
            } else {
                route = '/artists/songs/' + artistId;
            }
            return <Redirect to={route}/>
        }
    }

    goBack = () => {
        window.history.back();
    };

    render() {
        const {songFormDTO, usedTags, instrumentsTypes, artists} = this.props;
        return (
            <div className='SongCreationViewWidget'>

                {this.renderRedirect()}

                <ComposedSongForm
                    isLoading={!songFormDTO}
                    song={songFormDTO}
                    availableTags={usedTags}
                    availableInstrumentsType={instrumentsTypes}
                    artists={artists}
                    title={"app.songcreation.title"}
                    onSaveAndExit={this.onSaveAndExit}
                    onCancel={this.goBack}
                />
            </div>
        );
    }
}
