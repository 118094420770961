import React, { useEffect } from 'react';

export default function PlayAlongInnerPlayer (props) {
  /**
     Functions passed to useEffect are executed on every component rendering—unless you pass a second argument to it.
     If the second argument is an empty array, it will behave exactly like the componentDidMount, only executing on the first rendering.
     */
  const { onReady } = props;
  const callbackOnReady = () => {
    onReady({ target: 'mock' });
  };
  useEffect(callbackOnReady, []);

  return <div></div>;
}
