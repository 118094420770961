import React from 'react';
import { Link } from 'react-router-dom';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import ArtistAvatar from '../ArtistAvatar/ArtistAvatar';

class Artist extends React.Component {
  render () {
    const artist = this.props.model;

    return (
            <Grid container justify="center">

                <Grid item sm={12} xs={6}>
                    <Link
                        to={'/artists/songs/' + artist.id}
                        style={{
                          color: 'white',
                          textDecoration: 'none'
                        }}
                    >
                        <ArtistAvatar
                            image={artist.image}
                            size="90px"
                        />
                    </Link>
                </Grid>
                <Grid item sm={12} xs={6} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <Link
                        to={'/artists/songs/' + artist.id}
                        style={{
                          color: 'white',
                          textDecoration: 'none'
                        }}
                    >
                        <Button>{artist.name}</Button>
                    </Link>
                </Grid>
            </Grid>
    );
  }
}

export default Artist;
