import { getSongEntityFromDTO } from 'store/entities/song';
import { API_URL, LAST_PLAYED_SONGS_LIST_SIZE } from 'config';

export class SongsRepository {
  constructor (firestore) {
    this.firestore = firestore;
  }

  findAll (uid, onSnapshotSuccess, onSnapshotError) {
    const firestore = this.firestore();

    return firestore
      .collection('songs')
      .where('owner', '==', uid)
      .onSnapshot(
        (snapshot) => {
          const songEntities = [];
          snapshot.forEach(doc => {
            const songEntity = doc.data();
            songEntity.id = doc.id;
            songEntities.push(songEntity);
          });
          onSnapshotSuccess(songEntities);
        },
        (error) => {
          onSnapshotError(error);
        }
      );
  }

  async create (song, uid) {
    const firestore = this.firestore();

    const songEntity = getSongEntityFromDTO(song, uid, firestore);

    return firestore
      .collection('songs')
      .add(songEntity)
      .then(async function (docRef) {
        songEntity.id = docRef.id;
        return songEntity;
      });
  }

  async edit (songFormDTO, uid) {
    const firestore = this.firestore();

    const songEntity = getSongEntityFromDTO(songFormDTO, uid, firestore);
    return firestore
      .collection('songs')
      .doc(songFormDTO.key)
      .set(songEntity)
      .then(function () {
        songEntity.id = songFormDTO.key;
        // console.log(songEntity);
        return songEntity;
      })
      .catch(function (error) {
        console.error('Error writing document: ', error);
      });
  }

  delete (id) {
    const firestore = this.firestore();

    return firestore
      .collection('songs')
      .doc(id)
      .delete()
      .catch(function (error) {
        console.error('Error deleting document: ', error);
      });
  }

  async logReproduction (song, uid) {
    const firestore = this.firestore();

    await firestore
      .collection('users')
      .doc(uid)
      .collection('reproductions')
      .add({
        songId: song.id,
        timestamp: new Date()
      });
  }

  async updateLastPlayedSongs (song, uid) {
    const firestore = this.firestore();

    const userRef = firestore
      .collection('users')
      .doc(uid);

    const userDoc = await userRef.get();
    const userData = userDoc.data();

    // if the song is already in the list, remove it since it will be added at the beginning of the list
    const updatedLastPlayedSongs = userData.lastPlayedSongs.filter(songId => songId !== song.id);
    updatedLastPlayedSongs.unshift(song.id);

    await userRef.update({ lastPlayedSongs: updatedLastPlayedSongs.slice(0, LAST_PLAYED_SONGS_LIST_SIZE) });
  }

  async findLastPlayedSongs (uid) {
    const firestore = this.firestore();

    const userRef = firestore
      .collection('users')
      .doc(uid);

    const userDoc = await userRef.get();
    const userData = userDoc.data();

    return userData.lastPlayedSongs;
  }

  findSongsStrength (idToken) {
    const headers = new Headers({
      Authorization: idToken,
      'Content-Type': 'application/x-www-form-urlencoded'
    });

    return fetch(API_URL + '/strengthenSongs?order=ASC', {
      headers,
      method: 'GET'
    }).then(function (response) {
      if (response.ok) {
        return response.clone().json();
      }
    }).catch(function (error) {
      throw new Error(error.message);
    }).then(function (songsStrength) {
      return songsStrength;
    });
  }
}
