import React from "react";
import PropTypes from 'prop-types';

import Grid from '@material-ui/core/Grid';
import TextField from "@material-ui/core/TextField";
import Button from '@material-ui/core/Button';
import NoteAddIcon from "@material-ui/icons/NoteAdd";
import SaveIcon from '@material-ui/icons/Save';
import SaveAltIcon from '@material-ui/icons/SaveAlt';
import KeyboardBackspaceIcon from '@material-ui/icons/KeyboardBackspace';

import {FormattedMessage, injectIntl} from "react-intl";

import InstrumentTypeSelect from "../Selects/InstrumentTypeSelect";
import SectionWidget from "./SectionWidget/SectionWidget";
import SongTimePickerWidget from "./SongTimePicker";

import './SongForm.css';
import YouTubeVideoPicker from "../YouTubeVideoPicker";
import Typography from "@material-ui/core/Typography";
import {Box} from "@material-ui/core";
import ArtistsSelect from "../Selects/ArtistsSelect";
import {SongFormDTOBuilder} from "./DTO/Song/SongFormDTOBuilder";
import {SectionFormDTOBuilder} from "./DTO/Song/Section/SectionFormDTOBuilder";
import {PhrasesSongSubSectionFormDTOBuilder} from "./DTO/Song/Section/SubSection/SongSubSectionFormDTOBuilder";
import AlertDialog from "../Reusable/AlertDialog";

class SongForm extends React.PureComponent {

    constructor(props) {
        super(props);

        this.state = {
            song: this.props.song,
            fieldErrors: {},
            showAlertDialog: false,
            selectedSubSectionIdForLoop: null,
        };
    }

    componentDidMount() {
        const {song} = this.state;

        if (song.sections.length === 0) {
            this.addSection();
        }
    }

    componentDidUpdate(prevProps) {
        const {inferredSongDuration} = this.props;
        if (inferredSongDuration !== prevProps.inferredSongDuration) {
            this.changeDurationFormHandler(inferredSongDuration);
        }
    }

    handleSave = (event) => {
        event.preventDefault();
        let valid = this.validateForm();
        if (valid) {
            this.props.onSave(this.state.song);
        }
    }

    handleSaveAndExit = (event) => {
        event.preventDefault();
        const valid = this.validateForm();
        if (valid) {
            this.props.onSaveAndExit(this.state.song);
        }
    }

    validateForm = () => {
        let {song, fieldErrors} = this.state;

        fieldErrors.title = song.title === "";
        fieldErrors.artistId = song.artistId === "";
        fieldErrors.instrumentTypeId = song.instrumentType === null;
        fieldErrors.duration = song.duration === null;

        if (fieldErrors['sections'] === undefined) fieldErrors.sections = [];
        song.sections.forEach((section, index)=>{
            if (fieldErrors.sections[index] === undefined){
                fieldErrors.sections[index] = {};
            }
            fieldErrors.sections[index].sectionKey = section.sectionKey === "";
            fieldErrors.sections[index].instrument = section.instrument === "";
        });

        this.setState({
            fieldErrors: fieldErrors
        });

        for (const propertyName in fieldErrors) {
            if (propertyName !== "sections") {
                if (fieldErrors[propertyName]) {
                    this.toggleAlertDialog();
                    return false;
                }
            } else {
                for (const sectionFieldErrorIndex in fieldErrors[propertyName]) {
                    const sectionFieldError = fieldErrors[propertyName][sectionFieldErrorIndex];
                    for (const sectionPropertyName in sectionFieldError) {
                        if (sectionFieldError[sectionPropertyName]) {
                            this.toggleAlertDialog();
                            return false;
                        }
                    }
                }
            }
        }
        return true;
    }

    toggleAlertDialog = () => {
        this.setState({
            showAlertDialog: ! this.state.showAlertDialog
        });
    }

    changeVideoIdFormHandler = value => {
        let song = this.state.song;
        song.videoId = value;
        this.setState({
            song: song
        });
        this.props.changeVideoIdFormHandler(song.videoId);
    }

    changeDurationFormHandler = seconds => {
        let song = this.state.song;

        // When SongFormWidget is rendered, the song duration is inferred.
        // This duration may be equals to the one already persisted.
        // If this is the case, we don't need to update the component state
        if (song.duration === seconds) return;

        this.setState({
            song: {
                ...song,
                duration: seconds
            }
        });
    }

    changeTempoFormHandler = event => {
        let song = this.state.song;
        this.setState({
            song: {
                ...song,
                tempo: event.target.value
            }
        });
    }

    changeInstrumentTypeFormHandler = event => {

        let instrumentType = null;
        if (event.target.value !== '') {
            instrumentType = this.props.availableInstrumentsType[event.target.value];
        }
        let song = this.state.song;
        this.setState({
            song: {
                ...song,
                instrumentType: instrumentType
            }
        }, async () => {
            // update sections
            if (instrumentType !== null && instrumentType.instruments.length === 1) {
                for (const section of song.sections) {
                    await this.updateSection(section.id, { instrument: instrumentType.instruments[0] });
                }
            }
        });
    }

    changeTitleFormHandler = event => {
        let song = this.state.song;
        this.setState({
            song: {
                ...song,
                title: event.target.value
            }
        });
    }

    changeArtistFormHandler = event => {
        let song = this.state.song;

        let artistId = '';
        let artistName = '';
        if (event.target.value !== "") {
            artistId = event.target.value;
            const artist = this.props.artists[artistId];
            artistName = artist.name;
        }

        this.setState({
            song: {
                ...song,
                artistId: artistId,
                artistName: artistName
            }
        });
    }

    addSection = (event) => {
        let song = this.state.song;

        let instrument = '';
        if (song.instrumentType !== null && song.instrumentType.instruments.length === 1) {
            instrument = song.instrumentType.instruments[0];
        }

        let newSection = SongFormDTOBuilder.buildNewSection(song.sections.length, instrument);
        SectionFormDTOBuilder.addEmptyPhrasesSubSection(newSection);

        this.setState({
            song: {
                ...song,
                sections: [
                    ...song.sections.slice(0, song.sections.length),
                    newSection
                ]
            }
        });
    }

    sectionUpdateHandler = async (sectionIndex, itemAttributes) => {
        await this.updateSection(sectionIndex, itemAttributes);
    }

    updateSection = (sectionIndex, itemAttributes) => {
        return new Promise((resolve) => {
            // https://stackoverflow.com/questions/37662708/react-updating-state-when-state-is-an-array-of-objects
            this.setState({
                    song: {
                        ...this.state.song,
                        sections: [
                            ...this.state.song.sections.slice(0, sectionIndex),
                            Object.assign({}, this.state.song.sections[sectionIndex], itemAttributes),
                            ...this.state.song.sections.slice(sectionIndex + 1)
                        ]
                    }
                }, resolve
            );
        });
    }

    songSectionDeleteHandler = sectionIndex => {
        this.setState(prevState => ({
            song: {
                ...prevState.song,
                sections: [
                    ...prevState.song.sections.slice(0, sectionIndex),
                    ...prevState.song.sections.slice(sectionIndex + 1),
                ]
            }
        }));
    }

    subSectionCreateHandler = (sectionIndex, subSection, atPosition) => {

        const section = this.state.song.sections[sectionIndex];

        // https://stackoverflow.com/questions/37662708/react-updating-state-when-state-is-an-array-of-objects
        this.setState({
                song: {
                    ...this.state.song,
                    sections: [
                        ...this.state.song.sections.slice(0, sectionIndex),
                        {
                            ...section,
                            subSections: [
                                ...section.subSections.slice(0, atPosition),
                                subSection,
                                ...section.subSections.slice(atPosition)
                            ]
                        },
                        ...this.state.song.sections.slice(sectionIndex + 1)
                    ]
                }
            }
        );
    }

    subSectionUpdateHandler = async (sectionIndex, subSectionIndex, itemAttributes) => {

        const subSection = this.state.song.sections[sectionIndex].subSections[subSectionIndex];
        const mergedSubSection = Object.assign({}, subSection, itemAttributes);

        await this.updateSubSections(sectionIndex, [[subSectionIndex, mergedSubSection]]);

        if ('description' in itemAttributes) {
            // only if the description change, propagate the change to all the subsections that reference it
            await this.propagateChangesInSubSection(sectionIndex, subSectionIndex);
        }
    }

    subSectionDeleteHandler = (sectionIndex, subSectionIndex) => {

        const section = this.state.song.sections[sectionIndex];
        const subSection = this.state.song.sections[sectionIndex].subSections[subSectionIndex];

        let subSectionIndexesToRemove = SectionFormDTOBuilder.getSubSectionIndexesThatHasReferenceToSubsection(section, subSection);
        subSectionIndexesToRemove.push(subSectionIndex);

        subSectionIndexesToRemove.sort(function(a, b) {
            return a - b;
        });

        let subSections = [];
        let from = 0

        subSectionIndexesToRemove.forEach((subSectionIndex) => {
            subSections = [
                ...subSections,
                ...section.subSections.slice(from, subSectionIndex),
            ];
            from = subSectionIndex + 1;
        });
        subSections = [
            ...subSections,
            ...section.subSections.slice(from)
        ];
        // if the user remove last subsection, create an empty one. A section should always have one subsection
        if (subSections.length === 0) {
            let newSubSection = SectionFormDTOBuilder.createPhrasesSubSection(section);
            PhrasesSongSubSectionFormDTOBuilder.addPhrase(newSubSection);
            subSections = [
                ...subSections,
                newSubSection
            ];
        }

        // https://stackoverflow.com/questions/37662708/react-updating-state-when-state-is-an-array-of-objects
        this.setState({
                song: {
                    ...this.state.song,
                    sections: [
                        ...this.state.song.sections.slice(0, sectionIndex),
                        {
                            ...section,
                            subSections: subSections
                        },
                        ...this.state.song.sections.slice(sectionIndex + 1)
                    ]
                }
            }
        );
    }

    updateSubSections = (sectionIndex, subSectionsToChange) => {

        return new Promise((resolve) => {

            const section = this.state.song.sections[sectionIndex];

            let subSections = [];
            let from = 0

            subSectionsToChange.forEach((subSectionToChange) => {
                const subSectionIndex = subSectionToChange[0];
                const mergedSubSection = subSectionToChange[1];

                subSections = [
                    ...subSections,
                    ...section.subSections.slice(from, subSectionIndex),
                    mergedSubSection
                ];
                from = subSectionIndex + 1;
            });
            subSections = [
                ...subSections,
                ...section.subSections.slice(from)
            ];

            // https://stackoverflow.com/questions/37662708/react-updating-state-when-state-is-an-array-of-objects
            this.setState({
                    song: {
                        ...this.state.song,
                        sections: [
                            ...this.state.song.sections.slice(0, sectionIndex),
                            {
                                ...section,
                                subSections: subSections
                            },
                            ...this.state.song.sections.slice(sectionIndex + 1)
                        ]
                    }
                }, resolve
            );
        });
    }

    phraseUpdateHandler = async (sectionIndex, subSectionIndex, phraseIndex, itemAttributes) => {

        const phrase = this.state.song.sections[sectionIndex].subSections[subSectionIndex].phrases[phraseIndex];
        const mergedPhrase = Object.assign({}, phrase, itemAttributes);

        await this.updatePhrase(sectionIndex, subSectionIndex, phraseIndex, mergedPhrase);

        await this.propagateChangesInSubSection(sectionIndex, subSectionIndex);
    }

    updatePhrase = (sectionIndex, subSectionIndex, phraseIndex, mergedObject) => {

        return new Promise((resolve) => {

            const section = this.state.song.sections[sectionIndex];
            const subSection = section.subSections[subSectionIndex];

            // https://stackoverflow.com/questions/37662708/react-updating-state-when-state-is-an-array-of-objects
            this.setState({
                    song: {
                        ...this.state.song,
                        sections: [
                            ...this.state.song.sections.slice(0, sectionIndex),
                            {
                                ...section,
                                subSections: [
                                    ...section.subSections.slice(0, subSectionIndex),
                                    {
                                        ...subSection,
                                        phrases: [
                                            ...subSection.phrases.slice(0, phraseIndex),
                                            mergedObject,
                                            ...subSection.phrases.slice(phraseIndex + 1),
                                        ]
                                    },
                                    ...section.subSections.slice(subSectionIndex + 1)
                                ]
                            },
                            ...this.state.song.sections.slice(sectionIndex + 1)
                        ]
                    }
                }, resolve
            );
        });
    }

    phraseCreateHandler = async (sectionIndex, subSectionIndex, phrase) => {
        await this.createPhrase(sectionIndex, subSectionIndex, phrase);

        await this.propagateChangesInSubSection(sectionIndex, subSectionIndex);
    }

    createPhrase = (sectionIndex, subSectionIndex, phrase) => {

        return new Promise((resolve) => {

            const section = this.state.song.sections[sectionIndex];
            const subSection = section.subSections[subSectionIndex];

            // https://stackoverflow.com/questions/37662708/react-updating-state-when-state-is-an-array-of-objects
            this.setState({
                    song: {
                        ...this.state.song,
                        sections: [
                            ...this.state.song.sections.slice(0, sectionIndex),
                            {
                                ...section,
                                subSections: [
                                    ...section.subSections.slice(0, subSectionIndex),
                                    {
                                        ...subSection,
                                        phrases: [
                                            ...subSection.phrases,
                                            phrase
                                        ]
                                    },
                                    ...section.subSections.slice(subSectionIndex + 1)
                                ]
                            },
                            ...this.state.song.sections.slice(sectionIndex + 1)
                        ]
                    }
                }, resolve
            );
        });
    }

    phraseDeleteHandler = async (sectionIndex, subSectionIndex, phraseIndex) => {
        await this.deletePhrase(sectionIndex, subSectionIndex, phraseIndex);

        await this.propagateChangesInSubSection(sectionIndex, subSectionIndex);
    }

    deletePhrase = (sectionIndex, subSectionIndex, phraseIndex) => {

        return new Promise((resolve) => {

            const section = this.state.song.sections[sectionIndex];
            const subSection = section.subSections[subSectionIndex];

            // https://stackoverflow.com/questions/37662708/react-updating-state-when-state-is-an-array-of-objects
            this.setState({
                    song: {
                        ...this.state.song,
                        sections: [
                            ...this.state.song.sections.slice(0, sectionIndex),
                            {
                                ...section,
                                subSections: [
                                    ...section.subSections.slice(0, subSectionIndex),
                                    {
                                        ...subSection,
                                        phrases: [
                                            ...subSection.phrases.slice(0, phraseIndex),
                                            ...subSection.phrases.slice(phraseIndex + 1)
                                        ]
                                    },
                                    ...section.subSections.slice(subSectionIndex + 1)
                                ]
                            },
                            ...this.state.song.sections.slice(sectionIndex + 1)
                        ]
                    }
                }, resolve
            );
        });
    }

    propagateChangesInSubSection = async (sectionIndex, subSectionIndex) => {
        const section = this.state.song.sections[sectionIndex];
        const subSection = section.subSections[subSectionIndex];

        // propagate the change to all subsections that reference the subsection of the phrase

        let subSectionIndexesWithReferences = SectionFormDTOBuilder.getSubSectionIndexesThatHasReferenceToSubsection(section, subSection);
        if (subSectionIndexesWithReferences.length === 0) return;

        let subSectionsToChange = [];
        subSectionIndexesWithReferences.forEach((index) => {
            const subSectionWithReference = section.subSections[index];
            const mergedSubSectionWithReference = Object.assign({}, subSectionWithReference, {subSectionToRepeat: this.state.song.sections[sectionIndex].subSections[subSectionIndex]});
            subSectionsToChange.push([index, mergedSubSectionWithReference]);
        });

        await this.updateSubSections(sectionIndex, subSectionsToChange);
    }

    toggleSelectedSubSectionForLoop = (subSection, selected) => {
        let selectedSubSectionIdForLoop = null;
        let loopStartingTime = null;
        let loopEndingTime = null;

        if (selected) {
            selectedSubSectionIdForLoop = subSection.id;
            loopStartingTime = subSection.startingTime;
            loopEndingTime = subSection.endingTime;
        }

        this.setState({
            selectedSubSectionIdForLoop: selectedSubSectionIdForLoop,
        });
        this.props.changeLoopTimesHandler(loopStartingTime, loopEndingTime);
    }

    render() {
        const {intl, availableInstrumentsType, availableTags, artists} = this.props;
        const {song, selectedSubSectionIdForLoop, fieldErrors, showAlertDialog} = this.state;

        return (
            <div>
                <Grid container className="SongFormHeader">
                    <Grid item xs={12} sm={4}>
                        <Button variant="text" size="small" color="secondary" onClick={this.props.onCancel}>
                            <KeyboardBackspaceIcon/>
                            <FormattedMessage id="app.common.cancel"/>
                        </Button>
                    </Grid>
                    <Grid item xs={12} sm={4} style={{textAlign: "center"}}>
                        <Box m={1}>
                            <Typography component="h2" variant="h6">
                                <FormattedMessage id={this.props.title}/>
                            </Typography>
                        </Box>
                    </Grid>
                    <Grid item xs={12} sm={4}>
                        <Box display="flex" flexDirection="row-reverse">
                            <Box m={1} display={this.props.onSave ? "inline" : "none"}>
                                <Button id="save-button" variant="contained" size="small" color="secondary"
                                        onClick={this.handleSave}>
                                    <SaveIcon/>
                                    <FormattedMessage id="app.common.save"/>
                                </Button>
                            </Box>
                            <Box m={1}>
                                <Button variant="outlined" size="small" color="secondary"
                                        onClick={this.handleSaveAndExit}>
                                    <SaveAltIcon/>
                                    <FormattedMessage id="app.common.saveAndReturn"/>
                                </Button>
                            </Box>
                        </Box>
                    </Grid>
                </Grid>
                <div className='SongFormContainer'>
                    <form>
                        <AlertDialog
                            open={showAlertDialog}
                            onClose={this.toggleAlertDialog}
                            title={intl.formatMessage({id: 'app.common.warning'})}
                            message={intl.formatMessage({id: 'app.songform.warning.formInvalid'})}
                            buttonText={intl.formatMessage({id: 'app.common.ok'})}
                        />
                        <Grid container spacing={3}>
                            <Grid item xs={6} sm={3}>
                                <TextField
                                    label={intl.formatMessage({id: 'app.songform.field.title'})}
                                    id="title-input"
                                    name="title"
                                    value={song.title}
                                    error={fieldErrors.title}
                                    style={{marginRight: 3}}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    required
                                    fullWidth
                                    onChange={this.changeTitleFormHandler}
                                />
                                <ArtistsSelect
                                    label={intl.formatMessage({id: 'app.songform.field.artist'})}
                                    name="artistId"
                                    value={song.artistId}
                                    error={fieldErrors.artistId}
                                    artists={artists}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    margin="normal"
                                    required
                                    onChange={this.changeArtistFormHandler}
                                />
                            </Grid>
                            <Grid item xs={6} sm={2}>
                                <InstrumentTypeSelect
                                    label={intl.formatMessage({id: 'app.songform.field.instrumenttype'})}
                                    instruments={availableInstrumentsType}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    name="instrumentTypeId"
                                    error={fieldErrors.instrumentTypeId}
                                    value={song.instrumentType ? song.instrumentType.id : ''}
                                    required
                                    onChange={this.changeInstrumentTypeFormHandler}
                                />
                            </Grid>
                            <Grid item xs={false} sm={2}>
                            </Grid>
                            <Grid item xs={6} sm={3}>
                                <YouTubeVideoPicker
                                    label={intl.formatMessage({id: 'app.songform.field.videoId'})}
                                    name="videoId"
                                    value={song.videoId}
                                    onChange={this.changeVideoIdFormHandler}
                                    relatedTerms={song.title + ' ' + song.artistName}
                                />
                            </Grid>
                            <Grid item xs={2}>
                                <SongTimePickerWidget
                                    label={intl.formatMessage({id: 'app.songform.field.duration'})}
                                    value={song.duration}
                                    required
                                    error={fieldErrors.duration}
                                    onChange={this.changeDurationFormHandler}
                                />
                                <TextField
                                    label={intl.formatMessage({id: 'app.songform.field.tempo'})}
                                    name="tempo"
                                    value={song.tempo}
                                    error={fieldErrors.tempo}
                                    onChange={this.changeTempoFormHandler}
                                    type="number"
                                    style={{marginRight: 3}}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    margin="normal"
                                />
                            </Grid>
                            {
                                song.sections.map((section, sectionKey) => {
                                    const error = fieldErrors.hasOwnProperty('sections') ? fieldErrors.sections[sectionKey] : null;
                                    return <SectionWidget
                                        section={section}
                                        indexPosition={sectionKey}
                                        availableTags={availableTags}
                                        songInstrumentType={song.instrumentType}
                                        selectedSubSectionIdForLoop={selectedSubSectionIdForLoop}
                                        toggleSelectedSubSectionForLoop={this.toggleSelectedSubSectionForLoop}
                                        onUpdate={this.sectionUpdateHandler}
                                        onSubSectionCreate={this.subSectionCreateHandler}
                                        onSubSectionUpdate={this.subSectionUpdateHandler}
                                        onSubSectionDelete={this.subSectionDeleteHandler}
                                        onPhraseUpdate={this.phraseUpdateHandler}
                                        onPhraseCreate={this.phraseCreateHandler}
                                        onPhraseDelete={this.phraseDeleteHandler}
                                        onDelete={this.songSectionDeleteHandler}
                                        key={section.id}
                                        error={error}
                                    />
                                })
                            }
                            <Grid item xs={12}>
                                <Button variant="contained" color="default" aria-label="agregar frase" size="small"
                                        onClick={this.addSection}>
                                    <NoteAddIcon/> <FormattedMessage id="app.songform.button.newSection"/>
                                </Button>
                            </Grid>
                        </Grid>
                    </form>
                </div>
            </div>
        );
    }
}

SongForm.propTypes = {
    song: PropTypes.object.isRequired,
    title: PropTypes.string.isRequired,
    availableInstrumentsType: PropTypes.object.isRequired,
    availableTags: PropTypes.arrayOf(PropTypes.string).isRequired,
    artists: PropTypes.object.isRequired,
    onSave: PropTypes.func,
    onSaveAndExit: PropTypes.func.isRequired,
    onCancel: PropTypes.func.isRequired,
};

export default injectIntl(SongForm);
