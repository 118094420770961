import { PhraseFormDTOBuilder } from './PhraseFormDTOBuilder';

// todo estás constantes deben ser de entity
export const SUBSECTION_TYPES_PHRASES = 'phrases';
export const SUBSECTION_TYPES_REPEAT = 'repeat';

export class SongSubSectionFormDTOBuilder {
}
SongSubSectionFormDTOBuilder.build = function (type, id, startingTime, endingTime) {
  const subSectionFormDTO = {
    type,
    id,
    startingTime,
    endingTime
  };
  return subSectionFormDTO;
};
SongSubSectionFormDTOBuilder.hasReferenceToSubSection = function (subSection, referencedSubSection) {
  if (subSection.type === SUBSECTION_TYPES_PHRASES) {
    return false;
  } else if (subSection.type === SUBSECTION_TYPES_REPEAT) {
    return SongSubSectionFormDTOBuilder.equals(subSection.subSectionToRepeat, referencedSubSection);
  } else {
    throw new Error();
  }
};
SongSubSectionFormDTOBuilder.equals = function (subSectionA, subSectionB) {
  return subSectionA.id === subSectionB.id;
};

export class PhrasesSongSubSectionFormDTOBuilder extends SongSubSectionFormDTOBuilder {
}
PhrasesSongSubSectionFormDTOBuilder.build = function (id, description = '', phrasesJson = [], startingTime = null, endingTime = null) {
  const phraseSongSubSectionDTO = SongSubSectionFormDTOBuilder.build(SUBSECTION_TYPES_PHRASES, id, startingTime, endingTime);
  phraseSongSubSectionDTO.description = description;
  phraseSongSubSectionDTO.phrases = [];

  phrasesJson.forEach(function (element) {
    PhrasesSongSubSectionFormDTOBuilder.addPhrase(phraseSongSubSectionDTO, element.description, element.help);
  });
  return phraseSongSubSectionDTO;
};
PhrasesSongSubSectionFormDTOBuilder.getNewPhraseId = function () {
  return Date.now() + Math.random();
};
PhrasesSongSubSectionFormDTOBuilder.addPhrase = function (subSection, description = '', help = '') {
  subSection.phrases.push(PhrasesSongSubSectionFormDTOBuilder.buildPhrase(subSection, description, help));
};
PhrasesSongSubSectionFormDTOBuilder.buildPhrase = function (subSection, description = '', help = '') {
  return PhraseFormDTOBuilder.build(PhrasesSongSubSectionFormDTOBuilder.getNewPhraseId(), description, help);
};

export class RepeatSongSubSectionFormDTOBuilder extends SongSubSectionFormDTOBuilder {
}
RepeatSongSubSectionFormDTOBuilder.build = function (id, subSectionToRepeat, startingTime = null, endingTime = null) {
  const repeatSongSubSectionDTO = SongSubSectionFormDTOBuilder.build(SUBSECTION_TYPES_REPEAT, id, startingTime, endingTime);
  repeatSongSubSectionDTO.subSectionToRepeat = subSectionToRepeat;
  return repeatSongSubSectionDTO;
};
