export class ArtistsRepository {
  constructor (firestore) {
    this.firestore = firestore;
  }

  findAll (uid) {
    const firestore = this.firestore();
    return firestore.collection('artists')
      .where('owner', '==', uid)
      .get()
      .then((snapshot) => {
        const artists = {};
        snapshot.docs.forEach(doc => {
          const { name, image } = doc.data();
          const artist = {
            id: doc.id,
            name,
            image
          };
          artists[artist.id] = artist;
        });
        return artists;
      });
  }

  async create (artistEntity) {
    const firestore = this.firestore();

    return firestore
      .collection('artists')
      .add(artistEntity)
      .then(function (docRef) {
        artistEntity.id = docRef.id;
        return artistEntity;
      });
  }

  async edit (artistEntity) {
    const firestore = this.firestore();

    return firestore
      .collection('artists')
      .doc(artistEntity.id)
      .set(artistEntity);
  }

  async delete (artistEntity) {
    const firestore = this.firestore();

    return firestore
      .collection('artists')
      .doc(artistEntity.id)
      .delete()
      .catch(function (error) {
        console.error('Error deleting artist: ', error);
      });
  }
}
