import {
  FETCH_ARTISTS_PENDING,
  FETCH_ARTISTS_SUCCESS,
  FETCH_ARTISTS_ERROR,
  ARTISTS_CREATED, ARTISTS_DELETED, ARTISTS_EDITED
} from './../actions/artistsActions';

const initialState = {
  fetchPending: false,
  artists: {},
  error: null
};

const artistsReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_ARTISTS_PENDING:
      return {
        ...state,
        fetchPending: true
      };
    case FETCH_ARTISTS_SUCCESS:
      return {
        ...state,
        fetchPending: false,
        artists: action.artists
      };
    case FETCH_ARTISTS_ERROR:
      return {
        ...state,
        fetchPending: false,
        error: action.error
      };
    case ARTISTS_CREATED:
      state.artists[action.artist.id] = action.artist;
      return {
        ...state,
        artists: {
          ...state.artists
        }
      };
    case ARTISTS_EDITED:
      state.artists[action.artist.id] = action.artist;
      return {
        ...state,
        artists: {
          ...state.artists
        }
      };
    case ARTISTS_DELETED:
      delete state.artists[action.artist.id];
      return {
        ...state,
        artists: {
          ...state.artists
        }
      };
    default:
      return state;
  }
};

export default artistsReducer;

export const getArtists = state => state.artists.artists;
export const getArtistById = (artists, id) => artists[id];
export const getArtistsPending = state => state.artists.fetchPending;
export const getArtistsFetched = state => Object.keys(getArtists(state)).length > 0;
export const getArtistsError = state => state.artists.error;
