import React from 'react';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  songPlayerControlsRoot: {
    backgroundColor: theme.palette.primary.dark,
    fontSize: '20px',
    color: 'white',
    borderTop: '1px solid grey',
    textAlign: 'center',
    position: 'fixed',
    left: '0',
    bottom: '0',
    width: '100%'
  },
  phantomStyle: {
    display: 'block',
    width: '100%'
  }
}));

function ReproductionControls ({ visibility, children }) {
  const classes = useStyles();
  const visibilityClassName = visibility ? 'ReproductionControlsVisible' : 'ReproductionControlsInvisible';

  return (
      <div className={`ReproductionControls ${visibilityClassName}`}>
        <div className={`${classes.phantomStyle} ReproductionControlsPhantom`} />
        <div className={`${classes.songPlayerControlsRoot} ReproductionControlsRoot`}>
          {children}
        </div>
      </div>
  );
}
export default ReproductionControls;
