import React from 'react';
import IconButton from "@material-ui/core/IconButton";
import DeleteForeverIcon from "@material-ui/icons/DeleteForever";
import Grid from "@material-ui/core/Grid";
import SongTimePickerWidget from "../../SongTimePicker";
import Tooltip from "@material-ui/core/Tooltip";
import ToggleButton from '@material-ui/lab/ToggleButton';
import ThreeSixtyIcon from '@material-ui/icons/ThreeSixty';
import PropTypes from "prop-types";
import {injectIntl} from "react-intl";
import AlertDialog from "../../../Reusable/AlertDialog"
import TextField from "@material-ui/core/TextField";

class SubSectionWidget extends React.PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            showSelectSubSectionForLoopWarningDialog: false,
        };
    }

    deleteSubsectionHandler = (e) => {
        const { indexPosition } = this.props;
        this.props.onSubSectionDelete(indexPosition);
    };

    changeSongSubSectionStartingTimeHandler = (seconds) => {
        const { indexPosition } = this.props;
        this.props.onSubSectionUpdate(indexPosition, {startingTime: seconds});
    };

    changeSongSubSectionEndingTimeHandler = (seconds) => {
        const { indexPosition } = this.props;
        this.props.onSubSectionUpdate(indexPosition, {endingTime: seconds});
    };

    changeSongSubSectionDescriptionHandler = (event) => {
        const { indexPosition } = this.props;
        const value = event.target.value;
        this.props.onSubSectionUpdate(indexPosition, {description: value});
    };

    toggleSelectedSubSectionForLoop = () => {
        const { subSection } = this.props;
        if ((subSection.startingTime == null) || (subSection.endingTime == null)) {
            this.setState({
                showSelectSubSectionForLoopWarningDialog: true
            });
            return false;
        }

        const toggleSelectedForLoop = !this.props.selectedForLoop;
        this.props.toggleSelectedSubSectionForLoop(subSection, toggleSelectedForLoop);
    }

    closeSelectSubSectionForLoopWarningDialog = () => {
        this.setState({
            showSelectSubSectionForLoopWarningDialog: false
        });
    }

    render() {
        let {subSection, selectedForLoop, descriptionLabel, descriptionValue, descriptionHelperText, descriptionChangeEnabled, intl} = this.props;
        return (
            <Grid container spacing={6} key={subSection.id} className='SubSectionFormContainer'>
                <Grid item xs={5} sm={2}>
                    <SongTimePickerWidget
                        label={intl.formatMessage({id: 'app.songform.section.subsection.field.startingTime'})}
                        value={subSection.startingTime}
                        required={false}
                        onChange={this.changeSongSubSectionStartingTimeHandler}
                    />
                </Grid>
                <Grid item xs={5} sm={2}>
                    <SongTimePickerWidget
                        label={intl.formatMessage({id: 'app.songform.section.subsection.field.endingTime'})}
                        value={subSection.endingTime}
                        onChange={this.changeSongSubSectionEndingTimeHandler}
                        required={false}
                    />
                </Grid>
                <Grid item xs={2} sm={1}>
                    <ToggleButton
                        value="check"
                        selected={selectedForLoop}
                        onChange={this.toggleSelectedSubSectionForLoop}
                    >
                        <ThreeSixtyIcon color={selectedForLoop ? 'secondary' : 'inherit'}/>
                    </ToggleButton>
                    <AlertDialog
                        open={this.state.showSelectSubSectionForLoopWarningDialog}
                        onClose={this.closeSelectSubSectionForLoopWarningDialog}
                        title={intl.formatMessage({id: 'app.common.warning'})}
                        message={intl.formatMessage({id: 'app.songform.section.subsection.warning.selectSubSectionForLoop'})}
                        buttonText={intl.formatMessage({id: 'app.common.ok'})}
                    />
                </Grid>
                <Grid item xs={false} sm={2}>
                </Grid>
                <Grid item xs={10} sm={4} className={'SubSectionDescriptionFieldContainer'}>
                    <TextField
                        label={descriptionLabel}
                        helperText={descriptionHelperText}
                        name="description"
                        value={descriptionValue}
                        fullWidth
                        onChange={this.changeSongSubSectionDescriptionHandler}
                        disabled={! descriptionChangeEnabled}
                    />
                </Grid>
                <Grid item xs={2} sm={1} className="DeleteButtonSubSectionContainer">
                    <Tooltip
                        title={intl.formatMessage({id: 'app.songform.section.subsection.button.deletebutton.hint'})}>
                        <IconButton aria-label="Delete subsection" size="small"
                                    onClick={this.deleteSubsectionHandler}>
                            <DeleteForeverIcon fontSize="inherit"/>
                        </IconButton>
                    </Tooltip>
                </Grid>
                {this.props.children}
            </Grid>
        );
    }
}

SubSectionWidget.propTypes = {
    subSection: PropTypes.object.isRequired,
    indexPosition: PropTypes.number.isRequired,
    selectedForLoop: PropTypes.bool.isRequired,
    toggleSelectedSubSectionForLoop: PropTypes.func.isRequired,
    onSubSectionDelete: PropTypes.func.isRequired,
    onSubSectionUpdate: PropTypes.func.isRequired,
    descriptionLabel: PropTypes.string.isRequired,
    descriptionValue: PropTypes.string.isRequired,
    descriptionHelperText: PropTypes.string,
    descriptionChangeEnabled: PropTypes.bool.isRequired,
    children: PropTypes.any.isRequired
};

export default injectIntl(SubSectionWidget);
