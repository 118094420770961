import React from 'react';
import {Box} from "@material-ui/core";
import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";
import FormControl from '@material-ui/core/FormControl';
import InputLabel from "@material-ui/core/InputLabel";
import Input from "@material-ui/core/Input";
import InputAdornment from "@material-ui/core/InputAdornment";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import TextField from "@material-ui/core/TextField";
import Paper from '@material-ui/core/Paper';
import Grid from "@material-ui/core/Grid";
import {FormattedMessage} from "react-intl";
import {injectIntl} from "react-intl";
import Typography from "@material-ui/core/Typography";
import YouTubeIcon from '@material-ui/icons/YouTube';
import YouTube from "react-youtube";
import {getYouTubeAPIClient} from "../../YouTubeAPIClient";
import PropTypes from "prop-types";

class YouTubeVideoPicker extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            open: false,
            videoId: props.value,
            videoIdError: false,
            relatedVideosId: []
        };
    }

    matchYoutubeUrl(url) {
        let p = /^(?:https?:\/\/)?(?:m\.|www\.)?(?:youtu\.be\/|youtube\.com\/(?:embed\/|v\/|watch\?v=|watch\?.+&v=))((\w|-){11})(?:\S+)?$/;
        if (url.match(p)) {
            return url.match(p)[1];
        }
        return false;
    }

    onChangeVideoId = (event) => {
        let value = event.target.value;

        if (this.matchYoutubeUrl(value)) {
            let url = new URL(value);
            value = url.searchParams.get("v");
        }

        this.setVideoId(value);
    }

    setVideoId = videoId => {
        this.setState({
            videoId: videoId
        });
    }

    handleAccept = () => {
        let {videoId} = this.state;

        let valid = true;

        if (videoId === "") {
            valid = false;
            this.setState({
                videoIdError: true
            });
        }

        if (valid) {
            if (this.props.onChange) {
                this.props.onChange(videoId);
            }

            this.setState({
                videoIdError: false,
            });

            this.handleClose();
        }
    }

    handleOpen = () => {
        this.retrieveRelatedVideos();

        this.setState({
            open: true
        });
    }

    handleClose = () => {
        this.setState({
            open: false
        });
    }

    retrieveRelatedVideos = () => {
        const {relatedTerms} = this.props;

        const youtubeAPIClient = getYouTubeAPIClient();
        youtubeAPIClient.searchVideos(relatedTerms, 6)
            .then(results => {
                const videosId = results.map((video) => video.id);

                this.setState({
                    relatedVideosId: videosId
                });
            });
    }

    render() {
        let {value, relatedTerms, intl} = this.props;
        let {relatedVideosId} = this.state;

        let required = this.props.hasOwnProperty("required") ? this.props.required : false;
        let error = this.props.hasOwnProperty("error") ? this.props.error : false;

        let self = this;

        return <FormControl>
            <InputLabel required={required} error={error}>{this.props.label}</InputLabel>
            <Input
                disabled
                error={error}
                value={value}
                endAdornment={
                    <InputAdornment position="end">
                        <IconButton
                            aria-label="set time"
                            onClick={this.handleOpen}
                        >
                            <YouTubeIcon/>
                        </IconButton>
                    </InputAdornment>
                }
            />

            <Dialog
                open={this.state.open}
                onClose={this.handleClose}
                aria-labelledby="form-dialog-title"
                maxWidth="md"
                fullWidth={true}
            >
                <DialogTitle id="form-dialog-title">
                    {this.props.label}
                </DialogTitle>
                <DialogContent dividers>
                    <Grid container spacing={3}>
                        <Grid item xs={6}>
                            <TextField
                                autoFocus
                                label={intl.formatMessage({id: 'app.widget.youTubeVideoPicker.idOrURL'})}
                                margin="dense"
                                required={required}
                                error={this.state.videoIdError}
                                value={this.state.videoId}
                                onChange={this.onChangeVideoId}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <YouTube
                                videoId={this.state.videoId}
                                opts={{height: '150', width: '150'}}
                            />
                        </Grid>
                    </Grid>
                    <Paper elevation={2} variant={"elevation"}>
                        <Box m={2}>
                            <Typography component="h2" variant="h6" gutterBottom>
                                <FormattedMessage id="app.songform.widget.youTubePicker.relatedVideosWithATerm"
                                                  values={{relatedTerms: relatedTerms}}/>
                            </Typography>
                            <Grid container spacing={3}>
                                {relatedVideosId.map((videoId, key) =>
                                    <Grid item xs={2} key={key}>
                                        <Box display="flex" justifyContent="center">
                                            <YouTube
                                                videoId={videoId}
                                                opts={{height: '100', width: '100'}}
                                            />
                                        </Box>
                                        <Box display="flex" justifyContent="center">
                                            <Button variant="outlined" color="secondary"
                                                    onClick={() => self.setVideoId(videoId)}>
                                                <FormattedMessage id="app.common.select"/>
                                            </Button>
                                        </Box>
                                    </Grid>
                                )}
                            </Grid>
                        </Box>
                    </Paper>
                </DialogContent>
                <DialogActions>
                    <Button onClick={this.handleClose} color="secondary">
                        <FormattedMessage id="app.common.cancel"/>
                    </Button>
                    <Button onClick={this.handleAccept} color="secondary">
                        <FormattedMessage id="app.common.ok"/>
                    </Button>
                </DialogActions>
            </Dialog>
        </FormControl>;
    }
}

YouTubeVideoPicker.propTypes = {
    label: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
    relatedTerms: PropTypes.string.isRequired,
    required: PropTypes.bool,
    error: PropTypes.bool,
};

export default injectIntl(YouTubeVideoPicker);
