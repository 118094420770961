import React from "react";
import PropTypes from 'prop-types';
import {compose} from "redux";
import { withStyles } from '@material-ui/core/styles';
import Container from "@material-ui/core/Container";
import SharedStyles from "../../dashboard/SharedStyles";
import WithLoading from "../Reusable/WithLoading/WithLoading";
import WithErrorHandling from "../Reusable/WithErrorHandling/WithErrorHandling";
import ReproductionWidget from "./ReproductionWidget";
import ReproductionViewAppBar from "./ReproductionViewAppBar";

import './ReproductionView.css';
import {INSTRUMENT_TYPE_RELATED_TOOL} from "../../store/entities/instrumentTypes";

const ComposedReproductionWidget = compose(
    WithLoading,
    WithErrorHandling
)(ReproductionWidget);

class ReproductionView extends React.PureComponent {

    mouseInterval = null;
    // The wake lock sentinel.
    wakeLock = null;

    constructor(props) {
        super(props);
        this.state = {
            tools: this.getDefaultToolsState(),
            controlsVisibility: false
        };
    }

    async componentDidMount() {
        await this.requestWakeLock();
        document.addEventListener('visibilitychange', this.handleVisibilityChange, false);
        this.showControls();
    }

    componentWillUnmount() {
        this.releaseWakeLock();
        document.removeEventListener('visibilitychange', this.handleVisibilityChange);
    }

    getDefaultToolsState = () => {
        let defaultState = {};

        const {songInfo} = this.props;
        if (songInfo === null) return defaultState;

        const {instrumentType, song} = songInfo;

        for (const tool of instrumentType.relatedTools) {
            switch (tool) {
                case INSTRUMENT_TYPE_RELATED_TOOL.TRANSPOSE:
                    defaultState[INSTRUMENT_TYPE_RELATED_TOOL.TRANSPOSE] = {
                        offset: 0
                    };
                    break;
                case INSTRUMENT_TYPE_RELATED_TOOL.CAPO:
                    if (song.sections[0].hasOwnProperty('capo')) {
                        defaultState[INSTRUMENT_TYPE_RELATED_TOOL.CAPO] = {
                            value: song.sections[0].capo,
                            on: false
                        };
                    }
                    break;
                default:
                    break;
            }
        }
        return defaultState;
    }

    onReproductionStart = () => {
        let {songInfo, startSongReproduction} = this.props;
        startSongReproduction(songInfo);
    };

    onReproductionEnd = () => {
        let {stopSongReproduction} = this.props;
        stopSongReproduction();
        this.showControls();
    };

    onToolChange = (tool, property, value) => {
        const { tools } = this.state;
        this.setState(state => ({
            tools: {
                ...tools,
                [tool]: {
                    ...tools[tool],
                    [property]: value
                }
            }
        }));
    };

    showControls = () => {
        let {controlsVisibility} = this.state;

        if (! controlsVisibility) {
            this.setState(state => ({
                controlsVisibility: true
            }));
        }

        clearInterval(this.mouseInterval);
        this.mouseInterval = setInterval(() => {
            clearInterval(this.mouseInterval);
            this.setState(state => ({
                controlsVisibility: false
            }));
        }, 3000);
    }

    handleVisibilityChange = async () => {
        if (typeof document.visibilityState !== 'undefined' && document.visibilityState === 'visible') {
            await this.requestWakeLock();
        }
    }
    // Function that attempts to request a screen wake lock.
    requestWakeLock = async () => {
        if ('wakeLock' in navigator) {
            try {
                this.wakeLock = await navigator.wakeLock.request('screen');
                //this.wakeLock.addEventListener('release', () => {
                //    console.log('Screen Wake Lock released');
                //});
            } catch (err) {
                console.error(`${err.name}, ${err.message}`);
            }
        }
    };

    releaseWakeLock = () => {
        if ('wakeLock' in navigator && this.wakeLock != null) {
            this.wakeLock.release();
            this.wakeLock = null;
        }
    }

    render() {
        const {classes, songInfo, songPending, songError, trainingMode, setTrainingMode} = this.props;
        const {tools, controlsVisibility} = this.state;

        const title = (!songInfo) ? "" : songInfo.song.title;
        return (
            <div onMouseEnter={this.showControls} onMouseDown={this.showControls} onMouseMove={this.showControls}>
                <ReproductionViewAppBar
                    visibility={controlsVisibility}
                    title={title}
                    trainingMode={trainingMode}
                    setTrainingMode={setTrainingMode}
                    tools={tools}
                    onToolChange={this.onToolChange}
                />
                <main className={classes.content}>
                    <Container maxWidth="xl" className={classes.container_fullscreen}>
                        <ComposedReproductionWidget
                            isLoading={songPending || !songInfo}
                            error={songError}
                            songInfo={songInfo}
                            trainingMode={trainingMode}
                            tools={tools}
                            controlsVisibility={controlsVisibility}
                            onReproductionStart={this.onReproductionStart}
                            onReproductionEnd={this.onReproductionEnd}
                            countingIn={(this.props.countingIn !== undefined) ? this.props.countingIn : true}
                        />
                    </Container>
                </main>
            </div>
        );
    }
}

ReproductionView.propTypes = {
    songInfo: PropTypes.object.isRequired,
    songPending: PropTypes.bool.isRequired,
    error: PropTypes.bool,
    trainingMode: PropTypes.bool.isRequired,
    startSongReproduction: PropTypes.func.isRequired,
    stopSongReproduction: PropTypes.func.isRequired,
    countingIn: PropTypes.bool // This property is helpful for testing purpose. When the test is running, this property is false to avoid the countin animation
}

const styles = theme => ({
    ...SharedStyles(theme),
});
export default withStyles(styles)(ReproductionView);
