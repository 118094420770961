import React from 'react';
import Button from '@material-ui/core/Button';
import Grid from "@material-ui/core/Grid";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Tooltip from '@material-ui/core/Tooltip';
import LibraryAddIcon from "@material-ui/icons/LibraryAdd";
import IconButton from "@material-ui/core/IconButton";
import DeleteSweepIcon from "@material-ui/icons/DeleteSweep";
import {
    PhrasesSongSubSectionFormDTOBuilder, RepeatSongSubSectionFormDTOBuilder,
    SUBSECTION_TYPES_PHRASES,
    SUBSECTION_TYPES_REPEAT
} from "../DTO/Song/Section/SubSection/SongSubSectionFormDTOBuilder";
import RepeatSubSectionWidget from "./SubSectionWidget/RepeatSubSectionWidget/RepeatSubSectionWidget";
import PhrasesSubSectionWidget from "./SubSectionWidget/PhrasesSubSectionWidget/PhrasesSubSectionWidget";
import SectionKeySelect from "../../Selects/SectionKeySelect";
import InstrumentSelect from "../../Selects/InstrumentSelect";
import RepeatSectionSplitButton from "./SubSectionWidget/RepeatSubSectionWidget/RepeatSectionSplitButton";
import TagsSelect from "../../Selects/TagsSelect";
import {FormattedMessage, injectIntl} from "react-intl";
import {SectionFormDTOBuilder} from "../DTO/Song/Section/SectionFormDTOBuilder";
import TextField from "@material-ui/core/TextField";

class SectionWidget extends React.PureComponent {

    deleteSectionHandler = (e) => {
        const {intl, indexPosition} = this.props;

        const message = intl.formatMessage({id: 'app.songform.section.confirmation.deleteSection'});
        if (window.confirm(message)) {
            this.props.onDelete(indexPosition);
        }
    };

    changeSectionInstrumentHandler = (value) => {
        const { indexPosition } = this.props;
        this.props.onUpdate(indexPosition, {instrument: value});
    }

    changeSectionKeyHandler = (event) => {
        const { indexPosition } = this.props;
        const value = event.target.value;
        this.props.onUpdate(indexPosition, {sectionKey: value});
    }

    changeCapoHandler = (event) => {
        const { indexPosition } = this.props;
        const value = event.target.value;
        this.props.onUpdate(indexPosition, {capo: Number(value) });
    }

    changeSectionTagHandler = (values) => {
        const { indexPosition } = this.props;
        this.props.onUpdate(indexPosition, {tags: values});
    }

    addPhrasesSubSection = (afterSubSectionKey) => (event) => {
        const { indexPosition, section } = this.props;

        let newSubSection = SectionFormDTOBuilder.createPhrasesSubSection(section);
        PhrasesSongSubSectionFormDTOBuilder.addPhrase(newSubSection);

        this.props.onSubSectionCreate(indexPosition, newSubSection, afterSubSectionKey + 1);
    }

    addRepeatSubSection = (subSectionToRepeatPosition, afterSubSectionKey) => {
        const { indexPosition, section } = this.props;
        const subSectionToRepeatKey = subSectionToRepeatPosition - 1;
        const subSectionToRepeat = SectionFormDTOBuilder.getSubSectionByKey(section, subSectionToRepeatKey);

        let newSubSection = RepeatSongSubSectionFormDTOBuilder.build(SectionFormDTOBuilder.getSubSectionNewID(section), subSectionToRepeat);

        this.props.onSubSectionCreate(indexPosition, newSubSection, afterSubSectionKey + 1);
    }

    subSectionDeleteHandler = (subSectionIndex) => {
        const {intl, section, indexPosition} = this.props;

        const subSection = section.subSections[subSectionIndex];

         let message = intl.formatMessage({id: 'app.songform.section.subsection.confirmation.deleteSubSection'});

         if (SectionFormDTOBuilder.getSubSectionIndexesThatHasReferenceToSubsection(section, subSection).length > 0) {
            message += " " + intl.formatMessage({id: 'app.songform.section.subsection.warning.deleteReferencedSubSection'});
         }
         if (window.confirm(message)) {
             this.props.onSubSectionDelete(indexPosition, subSectionIndex);
         }
    }

    subSectionUpdateHandler = (subSectionIndex, itemAttributes) => {
        const {indexPosition} = this.props;
        this.props.onSubSectionUpdate(indexPosition, subSectionIndex, itemAttributes);
    }

    onPhraseUpdate = (subSectionIndex, phraseIndex, itemAttributes) => {
        const {indexPosition} = this.props;
        this.props.onPhraseUpdate(indexPosition, subSectionIndex, phraseIndex, itemAttributes);
    }

    onPhraseCreate = (subSectionIndex, phrase) => {
        const {indexPosition} = this.props;
        this.props.onPhraseCreate(indexPosition, subSectionIndex, phrase);
    }

    onPhraseDelete = (subSectionIndex, phraseIndex) => {
        const {indexPosition} = this.props;
        this.props.onPhraseDelete(indexPosition, subSectionIndex, phraseIndex);
    }

    render() {
        let {section, songInstrumentType, availableTags, selectedSubSectionIdForLoop, toggleSelectedSubSectionForLoop, intl} = this.props;
        const error = this.props.error === null ? {} : this.props.error;

        let self = this;

        const showCapoField = songInstrumentType !== null && songInstrumentType.relatedTools.includes('capo');

        let songSubSectionFormView = section.subSections.map(function (subSection, subSectionKey) {
            const selectedForLoop = (selectedSubSectionIdForLoop === null) ? false : subSection.id === selectedSubSectionIdForLoop;
            return <Grid container spacing={3} key={subSectionKey} style={{padding: '20px 40px 0 40px'}}>
                <Grid item xs={12}>
                    {
                        (subSection.type === SUBSECTION_TYPES_REPEAT) && (
                            <RepeatSubSectionWidget
                                key={subSection.id}
                                indexPosition={subSectionKey}
                                subSection={subSection}
                                onSubSectionUpdate={self.subSectionUpdateHandler}
                                onSubSectionDelete={self.subSectionDeleteHandler}
                                selectedForLoop={selectedForLoop}
                                toggleSelectedSubSectionForLoop={toggleSelectedSubSectionForLoop}
                            />
                        )
                    }
                    {
                        (subSection.type === SUBSECTION_TYPES_PHRASES) && (
                            <PhrasesSubSectionWidget
                                key={subSection.id}
                                indexPosition={subSectionKey}
                                subSection={subSection}
                                onSubSectionUpdate={self.subSectionUpdateHandler}
                                onSubSectionDelete={self.subSectionDeleteHandler}
                                selectedForLoop={selectedForLoop}
                                toggleSelectedSubSectionForLoop={toggleSelectedSubSectionForLoop}
                                onPhraseUpdate={self.onPhraseUpdate}
                                onPhraseCreate={self.onPhraseCreate}
                                onPhraseDelete={self.onPhraseDelete}
                            />
                        )
                    }
                </Grid>
                <Grid item xs={12}>
                    <RepeatSectionSplitButton
                        section={section}
                        onClick={ (subSectionToRepeatPosition) => self.addRepeatSubSection(subSectionToRepeatPosition, subSectionKey) }
                        style={{float: 'right'}}
                    />
                    <Button
                        aria-label="add subsection"
                        variant="contained"
                        color="secondary"
                        className="addSubsectionButtons"
                        size="small"
                        onClick={self.addPhrasesSubSection(subSectionKey)}
                    >
                        <LibraryAddIcon/> <FormattedMessage id="app.songform.section.button.newSubSection"/>
                    </Button>
                </Grid>
            </Grid>;
        });

        return (
            <Card className='SectionFormContainer'>
                <CardContent>
                    <Grid container spacing={3}>

                        <Grid item xs={12} className="DeleteButtonSectionContainer">
                            <Tooltip title={intl.formatMessage({id: 'app.songform.section.button.deletebutton.hint'})}>
                                <IconButton aria-label="Delete subsección" size="small"
                                            onClick={this.deleteSectionHandler}>
                                    <DeleteSweepIcon fontSize="inherit"/>
                                </IconButton>
                            </Tooltip>
                        </Grid>
                        <Grid item xs={6} sm={2}>
                            <SectionKeySelect
                                label={intl.formatMessage({id: 'app.songform.section.field.sectionKey'})}
                                name="sectionKey"
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                value={section.sectionKey}
                                margin="none"
                                style={{marginRight: 3}}
                                required
                                error={error.hasOwnProperty('sectionKey') ? error.sectionKey : false}
                                onChange={self.changeSectionKeyHandler}
                            />
                        </Grid>
                        <Grid item xs={6} sm={2}>
                            <InstrumentSelect
                                name="instrument"
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                value={section.instrument}
                                instrumentType={songInstrumentType ? songInstrumentType : null}
                                margin="none"
                                style={{marginRight: 3}}
                                required
                                error={error.hasOwnProperty('instrument') ? error.instrument : false}
                                onChange={self.changeSectionInstrumentHandler}
                            />
                        </Grid>
                        <Grid item xs={showCapoField} sm={2}>
                            {
                                (showCapoField && (
                                    <TextField
                                        label={intl.formatMessage({id: 'app.songform.section.field.capo'})}
                                        name="capo"
                                        value={section.hasOwnProperty('capo') ? section.capo : ''}
                                        error={error.hasOwnProperty('capo') ? error.capo : false}
                                        onChange={self.changeCapoHandler}
                                        type="number"
                                        style={{marginRight: 3}}
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        margin="none"
                                    />
                                ))
                            }
                        </Grid>
                        <Grid item xs={false} sm={2}></Grid>
                        <Grid item xs={12} sm={4}>
                            <TagsSelect
                                label={intl.formatMessage({id: 'app.songform.section.field.tags'})}
                                margin="none"
                                values={section.tags}
                                availableTags={availableTags}
                                onChange={self.changeSectionTagHandler}
                            />
                        </Grid>
                    </Grid>
                    { songSubSectionFormView }
                </CardContent>
            </Card>
        );
    }
}

export default injectIntl(SectionWidget);
