import React from "react";
import { Box, Container, Fade } from "@material-ui/core";
import SignupForm from "./SignupForm";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import CircularProgress from '@material-ui/core/CircularProgress';
import { FormattedMessage } from "react-intl";
import { loadReCaptcha } from 'react-recaptcha-v3'
import { GOOGLE_RECAPTCHA_KEY_SITE } from "config";

class SignupView extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      waitingForRequest: false
    }
  }

  signupHandler = (displayName, email, password, recaptchaToken) => {
    this.setState({waitingForRequest: true});
    this.props.signup(displayName, email, password, recaptchaToken).catch((error) => {
      this.setState({waitingForRequest: false});
    });
  }

  componentDidMount() {
    loadReCaptcha(GOOGLE_RECAPTCHA_KEY_SITE);
  }

  render() {
    const waitingForRequest = this.state.waitingForRequest;

    return (
      <Container maxWidth="lg">
        <Grid container spacing={1}>
          <Grid item xs={12} sm={4}>
          </Grid>
          <Grid item xs={12} sm={4}>
            <Typography component="h2" variant="h6" gutterBottom>
              <FormattedMessage id="app.session.signup"/>
            </Typography>
            <Box mt={8}>
              <Fade
                in={waitingForRequest}
                unmountOnExit
              >
                <Box mt={4}>
                  <CircularProgress color="secondary"/>
                </Box>
              </Fade>
              <SignupForm
                submitButtonDisabled={waitingForRequest}
                onSubmit={this.signupHandler}
              />
            </Box>
          </Grid>
        </Grid>
      </Container>
    )
  }
}

export default SignupView;
