export class InstrumentsTypeRepository {
  constructor (firestore) {
    this.firestore = firestore;
  }

  findAll () {
    const firestore = this.firestore();

    return firestore.collection('instrumentTypes')
      .get()
      .then((snapshot) => {
        const instrumentsType = {};

        snapshot.docs.forEach(doc => {
          const { name, instruments, notation, relatedTools } = doc.data();

          const instrumentType = {
            id: doc.id,
            name,
            instruments,
            notation,
            relatedTools: (relatedTools === undefined) ? [] : relatedTools
          };
          instrumentsType[instrumentType.id] = instrumentType;
        });
        return instrumentsType;
      });
  }
}
