import React from 'react';

import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import Typography from '@material-ui/core/Typography';

class ReproductionCountIn extends React.Component {
  render () {
    const { count } = this.props;
    if (count) {
      return (
                <Dialog
                    open={true}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            <Typography component="span" variant="h3" color="primary" style={{ margin: 20 }}>
                                {count}
                            </Typography>
                        </DialogContentText>
                    </DialogContent>
                </Dialog>
      );
    }
    return null;
  }
}

export default ReproductionCountIn;
