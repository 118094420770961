import React from 'react';
import YouTube from 'react-youtube';

export default function YouTubeInnerPlayer (props) {
  const opts = {
    playerVars: { // https://developers.google.com/youtube/player_parameters
      autoplay: 0,
      controls: 0,
      showinfo: 0,
      enablejsapi: 1,
      origin: window.location.href
    }
  };
  return <YouTube id={'YT-' + props.videoId}
            className="youtube-player"
            videoId={props.videoId}
            opts={opts}
            onReady={props.onReady}
        />;
}
