import React from 'react';

import Typography from '@material-ui/core/Typography';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';

import ArtistAvatar from '../../ArtistAvatar/ArtistAvatar';
import { Link } from 'react-router-dom';
import IconButton from '@material-ui/core/IconButton';
import PlayArrowRoundedIcon from '@material-ui/icons/PlayArrowRounded';
import { Box, ListItemSecondaryAction } from '@material-ui/core';
import { FormattedMessage } from 'react-intl';

import styles from './LastPlayedSongsList.module.css';

class LastPlayedSongsList extends React.Component {
  render () {
    const { lastPlayedSongs } = this.props;

    if (!lastPlayedSongs) {
      return (<div></div>);
    }

    const lastPlayedSongsView = lastPlayedSongs.map(function (lastPlayedSong, key) {
      return (<ListItem key={key}>
                <ListItemAvatar>
                    <ArtistAvatar
                        image={lastPlayedSong.artistImagePath}
                        size="50px"
                    />
                </ListItemAvatar>
                <ListItemText primary={lastPlayedSong.songTitle} secondary={lastPlayedSong.artistName}
                              className={styles.AuthorAndSongTitleHolder}/>
                <ListItemSecondaryAction>
                    <Link to={'/songs/play/' + lastPlayedSong.songId}>
                        <IconButton edge="end" aria-label="play">
                            <PlayArrowRoundedIcon/>
                        </IconButton>
                    </Link>
                </ListItemSecondaryAction>
            </ListItem>);
    });

    return (
            <div>
                <Typography variant="h5" color={'secondary'}>
                    <FormattedMessage id="app.lastplayedsongslist.title"/>
                </Typography>

                {lastPlayedSongsView.length === 0 && (
                    <Box style={{ marginTop: 10 }}>
                        <Typography variant="body1" >
                            <FormattedMessage id="app.common.noResult"/>
                        </Typography>
                    </Box>
                )}
                {lastPlayedSongsView.length > 0 && (
                    <List>
                        {lastPlayedSongsView}
                    </List>
                )}
            </div>
    );
  }
}

export default LastPlayedSongsList;
