
export class HarmonicaNotationFormatter {
  format (songPhrase) {
    // replace character ' ' to Unicode. https://stackoverflow.com/questions/24432576/reactjs-render-string-with-non-breaking-spaces
    songPhrase = songPhrase.replace(/ /g, '\u00a0');
    songPhrase = songPhrase.replace(/\t/g, '\u00a0\u00a0\u00a0\u00a0\u00a0\u00a0\u00a0\u00a0');

    const finalPhrase = [];

    const phraseSplitedByNewLine = songPhrase.split(/\r\n|\r|\n/);
    phraseSplitedByNewLine.forEach((line) => {
      // check if has numbers..
      if (line.match(/\d+/)) {
        finalPhrase.push("<span class='harmonica-notation'>" + line + '</span>\n');
      } else {
        finalPhrase.push(line + '\n');
      }
    });

    return finalPhrase.join('');
  }
}
