import React from 'react';
import Select from '@material-ui/core/Select';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';

class CustomSelect extends React.Component {
  render () {
    const inputLabelShrink = (this.props.InputLabelProps) ? this.props.InputLabelProps.shrink : false;

    const autoWidth = (this.props.hasOwnProperty('autoWidth')) ? this.props.autoWidth : true;

    const optionsView = this.props.options.map(function (option) {
      return <option key={option.key} value={option.key}>{option.value}</option>;
    });

    return (
            <FormControl margin={this.props.margin} style={{ marginRight: 3 }} fullWidth={autoWidth}>

                <InputLabel
                    htmlFor="key"
                    required={this.props.required}
                    error={this.props.error}
                    shrink={inputLabelShrink}
                >
                    {this.props.label}
                </InputLabel>

                <Select
                    autoWidth={autoWidth}
                    native
                    inputProps={{
                      name: this.props.name,
                      id: this.props.id
                    }}

                    displayEmpty
                    value={this.props.value}
                    onChange={this.props.onChange}
                    error={this.props.error}
                >
                    <option value=""></option>

                    {optionsView}

                </Select>
            </FormControl>
    );
  }
}

export default CustomSelect;
