import { createSelector, defaultMemoize } from 'reselect';
import {
  SONGS_FETCH_ERROR,
  SONGS_FETCH_PENDING,
  SONGS_FETCH_SUCCESS,
  CREATE_SONG_SUCCESS,
  DELETE_SONG_SUCCESS,
  FETCH_LAST_PLAYED_SONGS_ERROR,
  FETCH_LAST_PLAYED_SONGS_SUCCESS,
  FETCH_LAST_PLAYED_SONGS_PENDING,
  SONGS_SET_FILTER,
  EDIT_SONG_SUCCESS
} from '../actions/songsActions';
import { getArtistById, getArtists } from './artistsReducer';
import { getInstrumentsTypes, getInstrumentTypeById } from './instrumentsTypesReducer';

const initialState = {
  fetchPending: false,
  songsFetched: false,
  songsStore: [],
  error: null,
  filter: {
    title: '',
    artistId: null,
    instrumentType: null,
    instrument: '',
    tags: []
  },

  fetchLastPlayedSongsPending: false,
  lastPlayedSongs: [],
  errorLastPlayedSongs: null
};

const songsReducer = (state = initialState, action) => {
  switch (action.type) {
    case SONGS_SET_FILTER:
      // The next is necessary for the memoization of reselect
      return {
        ...state,
        filter: {
          ...action.filter,
          tags: [...action.filter.tags]
        }
      };
    case SONGS_FETCH_PENDING:
      return {
        ...state,
        fetchPending: true
      };
    case SONGS_FETCH_SUCCESS: {
      const currentTags = [];
      action.songs.forEach(function (song) {
        song.sections.forEach(function (section) {
          section.tags.forEach(function (tag) {
            currentTags.push(tag);
          });
        });
      });
      /** When user's tags are fetched, we have to check if there are songs filtered by tags,
       * that are not in use anymore */
      const fixedFilterTags = state.filter.tags.filter((t) => currentTags.includes(t));

      return {
        ...state,
        fetchPending: false,
        songsStore: action.songs,
        songsFetched: true,
        error: null,
        filter: {
          ...state.filter,
          tags: fixedFilterTags
        }
      };
    }
    case SONGS_FETCH_ERROR:
      return {
        ...state,
        fetchPending: false,
        error: action.error
      };
    case CREATE_SONG_SUCCESS:
    case EDIT_SONG_SUCCESS:
    case DELETE_SONG_SUCCESS: {
      /**
       * It's not necessary to make any changes in the state since the songs repository has real-time updates.
       * When there is a new song, SONGS_FETCH_SUCCESS will be triggered.
       **/
      return state;
    }
    case FETCH_LAST_PLAYED_SONGS_PENDING:
      return {
        ...state,
        fetchLastPlayedSongsPending: true
      };
    case FETCH_LAST_PLAYED_SONGS_SUCCESS:
      return {
        ...state,
        fetchLastPlayedSongsPending: false,
        errorLastPlayedSongs: null,
        lastPlayedSongs: action.songs
      };
    case FETCH_LAST_PLAYED_SONGS_ERROR:
      return {
        ...state,
        fetchLastPlayedSongsPending: false,
        lastPlayedSongs: [],
        errorLastPlayedSongs: action.error
      };
    default:
      return state;
  }
};

export default songsReducer;

export const getSongsFilter = state => state.songs.filter;
export const getFilterTitle = state => state.songs.filter.title;
export const getFilterArtistId = state => state.songs.filter.artistId;
export const getFilterInstrumentType = state => state.songs.filter.instrumentType;
export const getFilterInstrument = state => state.songs.filter.instrument;
export const getFilterTags = state => state.songs.filter.tags;
export const getSongsPending = state => state.songs.fetchPending;
export const getSongsFetched = state => state.songs.songsFetched;
export const getSongsError = state => state.songs.error;
export const getRawSongs = state => state.songs.songsStore;
export const getSongsCount = state => state.songs.songsStore.length;

// The extra variables are accessible like so..
// We create a selector that ignores the state variable
// Returning just the passed id
// https://flufd.github.io/reselect-with-multiple-parameters/
const getSongId = (_, id) => id;
export const getSong = createSelector(
  [
    getRawSongs,
    getInstrumentsTypes,
    getArtists,
    getSongId
  ],
  (rawSongs, instrumentTypes, artists, id) => {
    const song = rawSongs.find((song) => song.id === id);
    if (!song) return null;
    return {
      song,
      artist: getArtistById(artists, song.artistId),
      instrumentType: getInstrumentTypeById(instrumentTypes, song.instrumentTypeId)
    };
  }
);

export const getSongsFromArtist = (state, artistId) => {
  const rawSongs = getRawSongs(state);

  const songsFromArtist = [];

  rawSongs.filter(function (song) {
    return song.artistId === artistId;
  }).forEach(function (song) {
    songsFromArtist.push({
      song,
      artist: getArtistById(getArtists(state), song.artistId),
      instrumentType: getInstrumentTypeById(getInstrumentsTypes(state), song.instrumentTypeId)
    });
  });

  return songsFromArtist;
};
const memoizeArrayProducingFn = (fn) => {
  // https://github.com/reduxjs/reselect/issues/441#issuecomment-685271154
  const memArray = defaultMemoize((...array) => array);
  return (...args) => memArray(...fn(...args));
};
export const getUsedTags = createSelector(
  [getRawSongs],
  memoizeArrayProducingFn(
    (songs) => {
      const distinctTags = new Set();
      songs.forEach(function (song) {
        song.sections.forEach(function (section) {
          section.tags.forEach(function (tag) {
            distinctTags.add(tag);
          });
        });
      });
      const usedTags = [...distinctTags];
      usedTags.sort(function (tagA, tagB) {
        tagA = tagA.toUpperCase();
        tagB = tagB.toUpperCase();
        if (tagA > tagB) {
          return 1;
        }
        if (tagA < tagB) {
          return -1;
        }
        return 0;
      });
      return usedTags;
    }
  )
);
export const getFilteredSongs = createSelector(
  [
    getRawSongs,
    getUsedTags,
    getFilterTitle,
    getFilterArtistId,
    getFilterInstrumentType,
    getFilterInstrument,
    getFilterTags,
    getInstrumentsTypes,
    getArtists
  ],
  (
    rawSongs,
    current_tags,
    filterTitle,
    filterArtistId,
    filterInstrumentType,
    filterInstrument,
    filterTags,
    instrumentTypes,
    artists
  ) => {
    const songs = [];

    /** When user's tags are fetched, we have to check if there are songs filtered by tags,
     * that are not in use anymore.
     * The next time that SONGS_FETCH_SUCCESS is call, the state will be fixed
     * */
    const fixedFilterTags = (filterTags.length > 0) ? filterTags.filter((t) => current_tags.includes(t)) : [];

    const rawSongsFiltered = rawSongs.filter(function (song) {
      let match = true;

      if (filterTitle) {
        match = match && song.title.toUpperCase().startsWith(filterTitle.toUpperCase());
      }
      if (filterArtistId) {
        match = match && song.artistId === filterArtistId;
      }
      if (fixedFilterTags.length > 0) {
        const tagsUsed = song.sections.map((section) => section.tags);
        const tagsUsedMerged = [].concat.apply([], tagsUsed);
        match = match && tagsUsedMerged.some(r => fixedFilterTags.indexOf(r) >= 0);
      }
      if (filterInstrumentType) {
        match = match && song.instrumentTypeId === filterInstrumentType.id;
      }
      if (filterInstrument) {
        match = match && song.sections.map((section) => section.instrument).includes(filterInstrument);
      }
      return match;
    });
    rawSongsFiltered.sort(function (songA, songB) {
      const titleA = songA.title.toUpperCase();
      const titleB = songB.title.toUpperCase();

      if (titleA > titleB) {
        return 1;
      }
      if (titleA < titleB) {
        return -1;
      }
      return 0;
    });

    rawSongsFiltered.forEach(function (song) {
      songs.push({
        song,
        artist: getArtistById(artists, song.artistId),
        instrumentType: getInstrumentTypeById(instrumentTypes, song.instrumentTypeId)
      });
    });
    return songs;
  }
);
export const getUsedInstrumentsTypes = createSelector(
  [getRawSongs, getInstrumentsTypes],
  (songs, instruments) => {
    const usedInstruments = {};
    songs.forEach((song) => {
      usedInstruments[song.instrumentTypeId] = getInstrumentTypeById(instruments, song.instrumentTypeId);
    });
    return usedInstruments;
  }
);

export const getLastPlayedSongsPending = (state) => !getSongsFetched(state) || state.songs.fetchLastPlayedSongsPending;
export const getLastPlayedSongsError = state => state.songs.errorLastPlayedSongs;
const getLastPlayedSongIds = state => state.songs.lastPlayedSongs;
export const getLastPlayedSongs = (state) => {
  const songs = getRawSongs(state);
  if (songs.length === 0) return [];

  const lastPlayedSongs = [];
  getLastPlayedSongIds(state).forEach(function (playedSongId) {
    const song = songs.find((song) => song.id === playedSongId);
    // A recently played song may be deleted at this time. We need to check that.
    if (song !== undefined) {
      const artist = getArtistById(getArtists(state), song.artistId);

      lastPlayedSongs.push({
        song,
        artist
      });
    }
  });
  return lastPlayedSongs;
};
