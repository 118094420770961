export class StatsRepository {
  constructor (firestore) {
    this.firestore = firestore;
  }

  async getMonthlyStats (uid) {
    const getMonthTitle = (date) => {
      const year = date.getUTCFullYear().toString().substring(2);
      const month = date.getUTCMonth() + 1;
      return `${year}-${month}`;
    };

    const MONTHS_TO_CONSIDER = 12;
    const currentDate = new Date();
    const firstDayToConsider = new Date(currentDate.getFullYear(), currentDate.getMonth() + 1 - MONTHS_TO_CONSIDER, 1);

    const firestore = this.firestore();
    return firestore
      .collection('users')
      .doc(uid)
      .collection('monthlyStats')
      .where('monthDate', '>=', firstDayToConsider)
      .orderBy('monthDate', 'desc')
      .get()
      .then((snapshot) => {
        const resultMap = {};

        const d = new Date();
        d.setDate(2);
        for (let i = 0; i < MONTHS_TO_CONSIDER; i++) {
          resultMap[getMonthTitle(d)] = [getMonthTitle(d), 0];
          d.setMonth(d.getMonth() - 1);
        }

        snapshot.docs.forEach(doc => {
          const { monthDate, reproductionsCount } = doc.data();
          resultMap[getMonthTitle(monthDate.toDate())] = [getMonthTitle(monthDate.toDate()), reproductionsCount];
        });

        return Object.values(resultMap).reverse();
      });
  }

  async updateMonthlyStats (uid) {
    const currentDate = new Date();

    /**
     * The document for the month starts at 00:00:00 AM of the first day (UTC TIME)
     */
    const firstDayOfMonthUTCDate = new Date(Date.UTC(currentDate.getFullYear(), currentDate.getMonth(), 1));

    const firestore = this.firestore();
    const monthlyStatsRef = firestore
      .collection('users')
      .doc(uid)
      .collection('monthlyStats');

    const currentMonthSnapshot = await monthlyStatsRef
      .where('monthDate', '>=', firstDayOfMonthUTCDate)
      .limit(1)
      .get();

    if (currentMonthSnapshot.empty) {
      monthlyStatsRef.add({
        monthDate: firstDayOfMonthUTCDate,
        reproductionsCount: 1
      });
    } else {
      const currentMonthDoc = currentMonthSnapshot.docs[0];
      currentMonthDoc.ref.update({
        reproductionsCount: currentMonthDoc.data().reproductionsCount + 1
      });
    }
  }
}
