import {compose} from "redux";
import WithLoading from "../../Reusable/WithLoading/WithLoading";
import WithErrorHandling from "../../Reusable/WithErrorHandling/WithErrorHandling";
import LastPlayedSongsList from "./LastPlayedSongsList";
import React from "react";

const ComposedLastPlayedSongsList = compose(
    WithLoading,
    WithErrorHandling
)(LastPlayedSongsList);

class LastPlayedSongsSubView extends React.Component
{
    componentDidMount = () =>
    {
        const { fetchLastPlayedSongs } = this.props;
        fetchLastPlayedSongs();
    };

    render()
    {
        const {lastPlayedSongs, lastPlayedSongsError, lastPlayedSongsPending} = this.props;

        return (
            <ComposedLastPlayedSongsList
                isLoading={lastPlayedSongsPending}
                error={lastPlayedSongsError}
                lastPlayedSongs={lastPlayedSongs}
            />
        );
    }
}

export default LastPlayedSongsSubView;