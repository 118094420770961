import React from 'react';
import ErrorMessage from './ErrorMessage';

function WithErrorHandling (Component) {
  return function WithErrorHandlingComponent ({ error, ...props }) {
    if (!error) { return (<Component {...props} />); }
    return (<ErrorMessage messageId={'app.common.messages.errorRequest'} />);
  };
}
export default WithErrorHandling;
