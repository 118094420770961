import { getUID } from 'store/reducers/sessionReducer';

export const FETCH_STATS_PENDING = 'FETCH_STATS_PENDING';
export const FETCH_STATS_SUCCESS = 'FETCH_STATS_SUCCESS';
export const FETCH_STATS_ERROR = 'FETCH_STATS_ERROR';

function fetchMonthlyStatsPending () {
  return {
    type: FETCH_STATS_PENDING
  };
}

function fetchMonthlyStatsSuccess (stats) {
  return {
    type: FETCH_STATS_SUCCESS,
    stats
  };
}

function fetchMonthlyStatsError (error) {
  return {
    type: FETCH_STATS_ERROR,
    error
  };
}

export function fetchMonthlyStats () {
  return (dispatch, getState, { services }) => {
    dispatch(fetchMonthlyStatsPending());

    const uid = getUID(getState());

    services.statsRepository
      .getMonthlyStats(uid)
      .then((stats) => {
        dispatch(fetchMonthlyStatsSuccess(stats));
      }).catch(error => {
        dispatch(fetchMonthlyStatsError(error));
      });
  };
}
