import React from 'react';
import PropTypes from 'prop-types';

import { Badge } from '@material-ui/core';
import MusicNoteIcon from '@material-ui/icons/MusicNote';
import IconButton from '@material-ui/core/IconButton';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import { makeStyles } from '@material-ui/core/styles';
import FormGroup from '@material-ui/core/FormGroup';

const useStyles = makeStyles(theme => ({
  transposeBadgeHolder: {
    paddingLeft: 20,
    paddingRight: 2,
    paddingTop: 12,
    paddingBottom: 12
  },
  badgeIcon: {
    marginRight: 6
  },
  transposeButton: {
    paddingLeft: 8,
    paddingRight: 0
  }
}));

function TransposeInput (props) {
  const normalize = (value) => {
    if (value < -11) {
      return normalize(value + 12);
    } else if (value > 11) {
      return normalize(value - 12);
    }
    return value;
  };
  const toString = (value) => {
    if (value > 0) {
      value = '+' + value;
    }
    return value;
  };

  const classes = useStyles();
  const normalizedValue = normalize(props.value);

  return (
        <FormGroup row>
            <div className={classes.transposeBadgeHolder}>
                <Badge color="secondary" badgeContent={toString(normalizedValue)}>
                    <MusicNoteIcon className={(normalizedValue !== 0) ? classes.badgeIcon : ''}/>
                </Badge>
            </div>
            <IconButton aria-label="increase" className={classes.transposeButton + ' increaseTransposeValueButton'} onClick={ () => props.onChange(normalizedValue + 1)} disabled={normalizedValue === 11}>
                <KeyboardArrowUpIcon />
            </IconButton>
            <IconButton aria-label="reduce" className={classes.transposeButton + ' decreaseTransposeValueButton'} onClick={ () => props.onChange(normalizedValue - 1)} disabled={normalizedValue === -11}>
                <KeyboardArrowDownIcon />
            </IconButton>
        </FormGroup>
  );
}
TransposeInput.propTypes = {
  value: PropTypes.number.isRequired,
  onChange: PropTypes.func.isRequired
};
export default TransposeInput;
