import React from 'react';

import Song from './Song/Song';
import Section from './Song/Section/Section';
import SubSection from './Song/Section/SubSection/SubSection';
import PropTypes from 'prop-types';
import { SongDTO } from 'components/ReproductionView/DTO/Song/SongDTO';

class ReproductionReader extends React.Component {
  constructor (props) {
    super(props);

    const { song } = this.props;

    this.readerElement = React.createRef();
    this.subSectionsElements = [];
    this.lastManualScrollTopPosition = null;

    song
      .getAllSubSections()
      .forEach((subSection) => {
        if (typeof this.subSectionsElements[subSection.sectionOwner.id] === 'undefined') {
          this.subSectionsElements[subSection.sectionOwner.id] = [];
        }
        this.subSectionsElements[subSection.sectionOwner.id][subSection.id] = React.createRef();
      });
  }

  componentDidUpdate (prevProps, prevState, snapshot) {
    const { reproduction, song } = this.props;

    // get DTO sub sections
    const actualSubSection = song.getSubSectionAtTime(reproduction.getCurrentTime());
    const priorSubSection = song.getPriorSubSectionAtTime(reproduction.getCurrentTime());
    const nextSubSection = song.getNextSubSectionAtTime(reproduction.getCurrentTime());

    // calculate run limits and progress
    const actualRunStartingTime = this.calculateActualRunStartingTime(actualSubSection, priorSubSection);
    const actualRunEndingTime = this.calculateActualRunEndingTime(actualSubSection, nextSubSection);

    let estimatedNeededPosition;

    if (reproduction.getCurrentTime() > actualRunEndingTime && nextSubSection) {
      // la canción superó la última sección utilizada, pero aún no comenzó la siguiente
      estimatedNeededPosition = this.calculateTopPosition(nextSubSection, actualSubSection);
    } else {
      const actualRunProgress = 100 / (actualRunEndingTime - actualRunStartingTime) * (reproduction.getCurrentTime() - actualRunStartingTime);

      // calculate run top y height
      const actualRunTopPosition = this.calculateTopPosition(actualSubSection, priorSubSection);
      const actualRunHeight = this.calculateHeight(actualSubSection, nextSubSection);

      // estimate needed position
      estimatedNeededPosition = actualRunTopPosition + (actualRunHeight / 100 * actualRunProgress);
    }

    const songInstructionsElement = this.readerElement.current;
    const estimatedScrollTopPosition = estimatedNeededPosition - (songInstructionsElement.clientHeight / 2);
    if (!reproduction.isPlaying() && this.lastManualScrollTopPosition !== null) {
      /**
       * Para el caso en que:
       *  - la canción está detenida
       *  - el usuario hace scroll para abajo para releer algo
       *  - los controles visuales se esconden
       *  - el scroll se mantiene (antes volvía a la parte superior)
       */
      songInstructionsElement.scrollTop = this.lastManualScrollTopPosition;
    } else {
      songInstructionsElement.scrollTop = estimatedScrollTopPosition;
    }
  }

  getSubSectionComponentRef (subSection) {
    return this.subSectionsElements[subSection.sectionOwner.id][subSection.id];
  }

  getSubSectionComponentRefOffsetTop (subSection) {
    const componentRef = this.getSubSectionComponentRef(subSection);
    return componentRef ? componentRef.current.offsetTop : 0;
  }

  getSubSectionComponentRefOffsetHeight (subSection) {
    const componentRef = this.getSubSectionComponentRef(subSection);
    return componentRef ? componentRef.current.offsetHeight : 0;
  }

  calculateActualRunStartingTime (actualSubSection, priorSubSection) {
    if (typeof actualSubSection !== 'undefined') {
      return actualSubSection.startingTime;
    }
    if (typeof priorSubSection !== 'undefined') {
      return priorSubSection.endingTime ? priorSubSection.endingTime : priorSubSection.startingTime;
    }
    return 0;
  }

  calculateActualRunEndingTime (actualSubSection, nextSubSection) {
    if ((typeof actualSubSection !== 'undefined') && actualSubSection.endingTime) {
      return actualSubSection.endingTime;
    }

    if (typeof nextSubSection !== 'undefined') {
      return nextSubSection.startingTime;
    }

    const { song } = this.props;
    return song.getDuration();
  }

  calculateTopPosition (subSection, priorSubSection) {
    if (typeof subSection !== 'undefined') {
      return this.getSubSectionComponentRefOffsetTop(subSection);
    }
    if (typeof priorSubSection !== 'undefined') {
      const priorSubSectionComponentRef = this.getSubSectionComponentRef(priorSubSection);
      return priorSubSectionComponentRef.current.offsetTop + priorSubSectionComponentRef.current.offsetHeight;
    }
    return 0;
  }

  calculateHeight (subSection, nextSubSection) {
    if (typeof subSection !== 'undefined') {
      return this.getSubSectionComponentRefOffsetHeight(subSection);
    }
    if (typeof nextSubSection !== 'undefined') {
      // the beginning of the next subsection should be maximum level of the current possibility
      return this.getSubSectionComponentRef(nextSubSection).current.offsetTop;
    }
    return this.readerElement.current.scrollHeight;
  }

  onScroll () {
    const { reproduction } = this.props;
    if (!reproduction.isPlaying()) {
      this.lastManualScrollTopPosition = this.readerElement.current.scrollTop;
    } else {
      this.lastManualScrollTopPosition = null;
    }
  }

  render () {
    const { song } = this.props;
    return (
      <div className="ReproductionReader" ref={this.readerElement} onScroll={this.onScroll.bind(this)}>
        <Song>
          {
            song.sections.map((section, key) => {
              return <Section
                key={key}
                instrumentType={song.instrumentTypeName}
                sectionKey={section.sectionKey}
                instrument={section.instrument}
                tempo={song.getTempo()}
                tags={section.tags}
              >
                {
                  section.subSections.map((subSection, key) => {
                    return <SubSection
                      key={key}
                      subSection={subSection}
                      innerRef={this.subSectionsElements[subSection.sectionOwner.id][subSection.id]}
                    />;
                  })
                }
              </Section>;
            })
          }
        </Song>
      </div>
    );
  }
}

ReproductionReader.propTypes = {
  song: PropTypes.instanceOf(SongDTO).isRequired
};
export default ReproductionReader;
