import React from 'react';
import Grid from '@material-ui/core/Grid';
import { PhrasesSongSubSection, RepeatSongSubSection } from '../../../../DTO/Song/Section/SubSection/SongSubSection';
import RepeatSubSection from './RepeatSubSection/RepeatSubSection';
import PhrasesSubSection from './PhrasesSubSection/PhrasesSubSection';
import PropTypes from 'prop-types';

const SubSection = ({ subSection, innerRef }) => {
  let concreteSubSection = null;

  if (subSection instanceof RepeatSongSubSection) {
    concreteSubSection = <RepeatSubSection
      subSection={subSection}
    />;
  } else if (subSection instanceof PhrasesSongSubSection) {
    concreteSubSection = <PhrasesSubSection
      description={subSection.description}
      startingTime={subSection.startingTime}
      endingTime={subSection.endingTime}
      phrases={subSection.phrases}
    />;
  }

  return (
    <Grid container className='SubSectionViewContainer' ref={innerRef}>
      {concreteSubSection}
    </Grid>
  );
};

SubSection.propTypes = {
  subSection: PropTypes.object.isRequired,
  innerRef: PropTypes.object.isRequired
};

export default SubSection;
