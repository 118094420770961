
export function getArtistEntityFromDTO (artistDTO, uid) {
  const { name, image, key } = artistDTO;

  const entity = {
    name,
    image,
    id: key,
    owner: uid
  };

  return entity;
}
