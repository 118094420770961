import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableFooter from '@material-ui/core/TableFooter';
import TableRow from '@material-ui/core/TableRow';
import PlayArrowRoundedIcon from '@material-ui/icons/PlayArrowRounded';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import { Link } from 'react-router-dom';

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContentText from '@material-ui/core/DialogContentText';
import { FormattedMessage } from 'react-intl';
import Typography from '@material-ui/core/Typography';
import { Box } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  root: {
    marginTop: theme.spacing(3),
    overflowX: 'auto'
  },
  cellActions: {
    textAlign: 'center',
    width: '140px'
  },
  actionButton: {
    margin: '0px 4px'
  },
  link: {
    color: 'inherit',
    fontWeight: 'bold',
    textDecoration: 'none'
  },
  tableFooterRowCell: {
    textAlign: 'end',
    fontStyle: 'italic'
  }
}));

export default function SongsList ({ songs, onSongDelete, showArtist, origin }) {
  const [deleteDialogOpen, setDeleteDialogOpen] = React.useState(false);
  const [deleteSongValue, setDeleteSongValue] = React.useState(null);

  function handleOpenDeleteDialog (song) {
    setDeleteSongValue(song);
    setDeleteDialogOpen(true);
  }

  function handleConfirmDeleteDialog () {
    onSongDelete(deleteSongValue);
    handleCloseDeleteDialog();
  }

  function handleCloseDeleteDialog () {
    setDeleteSongValue(null);
    setDeleteDialogOpen(false);
  }

  const classes = useStyles();

  if (songs.length > 0) {
    const tableView = songs.map(function (songListItemDTO) {
      return (
                    <TableRow key={songListItemDTO.id}>
                        <TableCell scope="row">
                            <Link
                                to={{
                                  pathname: '/songs/play/' + songListItemDTO.id
                                }}
                                className={classes.link}
                            >
                                {songListItemDTO.songTitle}
                            </Link>
                        </TableCell>
                        {showArtist && (
                            <TableCell scope="row">
                                {songListItemDTO.artistName}
                            </TableCell>
                        )}
                        <TableCell scope="row">
                            {songListItemDTO.getKeysInvolved().join(', ')}
                        </TableCell>
                        <TableCell scope="row">
                            {songListItemDTO.instrumentTypeName} ({songListItemDTO.getInstrumentsInvolved().join(', ')})
                        </TableCell>
                        <TableCell scope="row">
                            {songListItemDTO.getTagsInvolved().map((tag) => '#' + tag).join(', ')}
                        </TableCell>
                        <TableCell className={classes.cellActions}>
                            <Link
                                to={{
                                  pathname: '/songs/play/' + songListItemDTO.id
                                }}
                                className={classes.actionButton}
                            >
                                <IconButton size="small" color="secondary">
                                    <PlayArrowRoundedIcon/>
                                </IconButton>

                            </Link>
                            <Link
                                to={{
                                  pathname: '/songs/edition/' + songListItemDTO.id,
                                  state: {
                                    origin
                                  }
                                }}
                                className={classes.actionButton}
                            >

                                <IconButton size="small" color="secondary">
                                    <EditIcon/>
                                </IconButton>

                            </Link>
                            <IconButton
                                size="small"
                                color="secondary"
                                onClick={() => handleOpenDeleteDialog(songListItemDTO)}
                            >
                                <DeleteIcon/>
                            </IconButton>

                        </TableCell>
                    </TableRow>
      );
    }
    );
    return (
            <div className={classes.root}>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>
                                <FormattedMessage id="app.songslist.list.header.title"/>
                            </TableCell>

                            {showArtist &&
                            (<TableCell>
                                <FormattedMessage id="app.songslist.list.header.artist"/>
                            </TableCell>)}

                            <TableCell>
                                <FormattedMessage id="app.songslist.list.header.key"/>
                            </TableCell>

                            <TableCell>
                                <FormattedMessage id="app.songslist.list.header.instrument"/>
                            </TableCell>

                            <TableCell>
                                <FormattedMessage id="app.songslist.list.header.tags"/>
                            </TableCell>

                            <TableCell className={classes.cellActions}>
                                <FormattedMessage id="app.common.lists.actions"/>
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {tableView}
                    </TableBody>
                    <TableFooter>
                        <TableRow>
                            <TableCell colSpan={6} className={classes.tableFooterRowCell}>
                                <FormattedMessage id="app.songslist.list.footer.info" values={{ count: songs.length }}/>
                            </TableCell>
                        </TableRow>
                    </TableFooter>
                </Table>
                <Dialog
                    open={deleteDialogOpen}
                    onClose={handleCloseDeleteDialog}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle id="alert-dialog-slide-title">
                        <FormattedMessage id="app.common.confirmation"/>
                    </DialogTitle>
                    <DialogContent dividers>
                        <DialogContentText id="alert-dialog-description">
                            <FormattedMessage id="app.songslist.confirmation.deletesong"/>
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleCloseDeleteDialog} color="secondary" autoFocus>
                            <FormattedMessage id="app.common.cancel"/>
                        </Button>
                        <Button onClick={handleConfirmDeleteDialog} color="secondary">
                            <FormattedMessage id="app.common.ok"/>
                        </Button>
                    </DialogActions>
                </Dialog>
            </div>
    );
  } else {
    return (
            <Box style={{ marginTop: 10 }}>
                <Typography variant="body1">
                    <FormattedMessage id="app.common.noResult"/>
                </Typography>
            </Box>
    );
  }
}
