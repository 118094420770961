import { deleteSong } from './songsActions';
import { getSongsFromArtist } from '../reducers/songsReducer';
import { getUID } from '../reducers/sessionReducer';
import { getArtistEntityFromDTO } from '../entities/artist';
import { SongToDeleteDTO } from './DTO/song/SongToDeleteDTO';
import { addSuccess } from 'redux-flash-messages/lib';
import { getArtists, getArtistsFetched } from '../reducers/artistsReducer';

export const FETCH_ARTISTS_PENDING = 'FETCH_ARTISTS_PENDING';
export const FETCH_ARTISTS_SUCCESS = 'FETCH_ARTISTS_SUCCESS';
export const FETCH_ARTISTS_ERROR = 'FETCH_ARTISTS_ERROR';

export const ARTISTS_CREATED = 'ARTIST_CREATED';
export const ARTISTS_EDITED = 'ARTISTS_EDITED';
export const ARTISTS_DELETED = 'ARTISTS_DELETED';

export function fetchArtists () {
  return (dispatch, getState, { services }) => {
    /**
         * the "pending" action is triggered only when there is no local data
         * (and it will avoid showing the user a loading when he is already viewing information).
         */
    if (!getArtistsFetched(getState())) dispatch(fetchArtistsPending());

    const uid = getUID(getState());

    return services.artistsRepository
      .findAll(uid)
      .then((artists) => {
        // evitar cambio en el state cuando lo recuperado es igual a lo que ya estaba almacenado
        if (JSON.stringify(getArtists(getState())) !== JSON.stringify(artists)) { dispatch(fetchArtistsSuccess(artists)); }
      }).catch(error => {
        dispatch(fetchArtistsError(error));
      });
  };
}

function fetchArtistsPending () {
  return {
    type: FETCH_ARTISTS_PENDING
  };
}

function fetchArtistsSuccess (artists) {
  return {
    type: FETCH_ARTISTS_SUCCESS,
    artists
  };
}

function fetchArtistsError (error) {
  return {
    type: FETCH_ARTISTS_ERROR,
    error
  };
}

/**
 * Thunk: createArtist store the artist in the database and then dispatch 'artistCreated' action.
 * @param artist
 * @returns {function(...[*]=)}
 */
export function createArtist (artistDTO) {
  return (dispatch, getState, { services }) => {
    const uid = getUID(getState());

    services.artistsRepository
      .create(getArtistEntityFromDTO(artistDTO, uid))
      .then(function (artistEntity) {
        dispatch(artistCreated(artistEntity));

        addSuccess({ text: 'app.actions.artists.create.success' });
      })
      .catch(function (error) {
        console.error('Error adding document: ', error);
      });
  };
}

function artistCreated (artistEntity) {
  return {
    type: ARTISTS_CREATED,
    artist: artistEntity
  };
}

export function editArtist (artistDTO) {
  return (dispatch, getState, { services }) => {
    const uid = getUID(getState());

    const artistEntity = getArtistEntityFromDTO(artistDTO, uid);

    services.artistsRepository
      .edit(artistEntity)
      .then(function () {
        dispatch(editArtistSuccess(artistEntity));

        addSuccess({ text: 'app.actions.artists.edit.success' });
      })
      .catch(function (error) {
        console.error('Error updating document: ', error);
      });
  };
}

function editArtistSuccess (artistEntity) {
  return {
    type: ARTISTS_EDITED,
    artist: artistEntity
  };
}

export function deleteArtist (artistDTO) {
  return (dispatch, getState, { services }) => {
    return new Promise(async function (resolve, reject) {
      const uid = getUID(getState());

      const artistEntity = getArtistEntityFromDTO(artistDTO, uid);

      const songsFromArtist = getSongsFromArtist(getState(), artistEntity.id);

      songsFromArtist.forEach(function (songFromArtist) {
        dispatch(deleteSong(new SongToDeleteDTO(songFromArtist.song.id)));
      });

      services.artistsRepository
        .delete(artistEntity)
        .then(function () {
          dispatch(deleteArtistSuccess(artistEntity));
          addSuccess({ text: 'app.actions.artists.delete.success' });
        });
    });
  };
}

function deleteArtistSuccess (artistEntity) {
  return {
    type: ARTISTS_DELETED,
    artist: artistEntity
  };
}
