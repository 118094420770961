import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { createSongForArtist } from '../store/actions/songsActions';
import SongCreationView from '../components/SongCreationView/SongCreationView';
import { getArtistById, getArtists } from '../store/reducers/artistsReducer';
import { getInstrumentsTypes } from '../store/reducers/instrumentsTypesReducer';
import { SongFormDTOBuilder } from '../components/SongForm/DTO/Song/SongFormDTOBuilder';
import { getUsedTags } from '../store/reducers/songsReducer';

function mapStateToProps (state, ownProps) {
  const { match: { params } } = ownProps;

  let songFormDTO;
  if (params.idArtist !== undefined) {
    const artist = getArtistById(getArtists(state), params.idArtist);
    songFormDTO = SongFormDTOBuilder.build(null, null, artist.id, artist.name);
  } else {
    songFormDTO = SongFormDTOBuilder.build();
  }
  return {
    songFormDTO,
    instrumentsTypes: getInstrumentsTypes(state),
    usedTags: getUsedTags(state),
    artists: getArtists(state)
  };
}

const mapDispatchToProps = dispatch => bindActionCreators({
  createSongForArtist
}, dispatch);

const SongCreationContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(SongCreationView);

export default SongCreationContainer;
