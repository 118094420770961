import { getUID } from '../reducers/sessionReducer';
import { getSongStateObjectFromEntity } from '../entities/song';

import { addSuccess } from 'redux-flash-messages/lib';
import { getRawSongs, getSongsFetched } from '../reducers/songsReducer';

export const SONGS_SET_FILTER = 'SONGS_SET_FILTER';
export const SONGS_FETCH_PENDING = 'SONGS_FETCH_PENDING';
export const SONGS_FETCH_SUCCESS = 'SONGS_FETCH_SUCCESS';
export const SONGS_FETCH_ERROR = 'SONGS_FETCH_ERROR';

export const CREATE_SONG_SUCCESS = 'CREATE_SONG_SUCCESS';
export const EDIT_SONG_SUCCESS = 'EDIT_SONG_SUCCESS';
export const DELETE_SONG_SUCCESS = 'DELETE_SONG_SUCCESS';

export const FETCH_LAST_PLAYED_SONGS_PENDING = 'FETCH_LAST_PLAYED_SONGS_PENDING';
export const FETCH_LAST_PLAYED_SONGS_SUCCESS = 'FETCH_LAST_PLAYED_SONGS_SUCCESS';
export const FETCH_LAST_PLAYED_SONGS_ERROR = 'FETCH_LAST_PLAYED_SONGS_ERROR';

export const SONG_REPRODUCTION_START = 'SONG_REPRODUCTION_START';
export const SONG_REPRODUCTION_STOP = 'SONG_REPRODUCTION_STOP';

export function setSongsFilter (filter) {
  return (dispatch) => {
    dispatch({
      type: SONGS_SET_FILTER,
      filter
    });
  };
}

function fetchSongsPending () {
  return {
    type: SONGS_FETCH_PENDING
  };
}

function fetchSongsSuccess (songs) {
  return {
    type: SONGS_FETCH_SUCCESS,
    songs
  };
}

function fetchSongsError (error) {
  return {
    type: SONGS_FETCH_ERROR,
    error
  };
}

export function songReproductionStart (song) {
  return (dispatch) => {
    dispatch({
      type: SONG_REPRODUCTION_START,
      song
    });
  };
}

export function songReproductionStop () {
  return (dispatch) => {
    dispatch({
      type: SONG_REPRODUCTION_STOP
    });
  };
}

export function fetchSongs () {
  return (dispatch, getState, { services }) => {
    /**
     * the "pending" action is triggered only when there is no local data
     * (and it will avoid showing the user a loading when he is already viewing information).
     */
    if (!getSongsFetched(getState())) dispatch(fetchSongsPending());

    const uid = getUID(getState());
    const onSnapshotSuccess = (songsEntities) => {
      const songs = [];
      for (const songEntity of songsEntities) {
        songs.push(getSongStateObjectFromEntity(songEntity));
      }
      /**
       * Avoid changes in the state when what is retrieved is the same as what was already stored.
       */
      if (JSON.stringify(getRawSongs(getState())) !== JSON.stringify(songs)) {
        dispatch(fetchSongsSuccess(songs));
      }
    };
    const onSnapshotError = (error) => {
      console.log(error);
      dispatch(fetchSongsError(error));
    };
    services.songsRepository
      .findAll(uid, onSnapshotSuccess, onSnapshotError);
  };
}

function createSongSuccess (song) {
  return {
    type: CREATE_SONG_SUCCESS,
    song
  };
}

export function createSongForArtist (song) {
  return (dispatch, getState, { services }) => {
    const uid = getUID(getState());

    services.songsRepository
      .create(song, uid)
      .then(function (songEntityCreated) {
        dispatch(createSongSuccess(getSongStateObjectFromEntity(songEntityCreated)));
        addSuccess({ text: 'app.actions.songs.create.success' });
      })
      .catch(function (error) {
        console.error('Error adding document: ', error);
      });
  };
}

function editSongSuccess (song) {
  return {
    type: EDIT_SONG_SUCCESS,
    song
  };
}

export function editSong (song) {
  return (dispatch, getState, { services }) => {
    const uid = getUID(getState());

    return services.songsRepository
      .edit(song, uid)
      .then(function (songEntityEdited) {
        dispatch(editSongSuccess(getSongStateObjectFromEntity(songEntityEdited)));

        addSuccess({ text: 'app.actions.songs.edit.success' });
      })
      .catch(function (error) {
        console.error('Error adding document: ', error);
      });
  };
}

function deleteSongSuccess (songId) {
  return {
    type: DELETE_SONG_SUCCESS,
    songId
  };
}

export function deleteSong (songToDeleteDTO) {
  return (dispatch, getState, { services }) => {
    return new Promise(function (resolve, reject) {
      services.songsRepository
        .delete(songToDeleteDTO.id)
        .then(function () {
          dispatch(deleteSongSuccess(songToDeleteDTO.id));

          addSuccess({ text: 'app.actions.songs.delete.success' });
        });
    });
  };
}

function fetchLastPlayedSongsPending () {
  return {
    type: FETCH_LAST_PLAYED_SONGS_PENDING
  };
}

function fetchLastPlayedSongsSuccess (songs) {
  return {
    type: FETCH_LAST_PLAYED_SONGS_SUCCESS,
    songs
  };
}

function fetchLastPlayedSongsError (error) {
  return {
    type: FETCH_LAST_PLAYED_SONGS_ERROR,
    error
  };
}

export function fetchLastPlayedSongs () {
  return (dispatch, getState, { services }) => {
    dispatch(fetchLastPlayedSongsPending());

    const uid = getUID(getState());

    services.songsRepository
      .findLastPlayedSongs(uid)
      .then((songsIds) => {
        dispatch(fetchLastPlayedSongsSuccess(songsIds));
      })
      .catch(error => {
        dispatch(fetchLastPlayedSongsError(error));
      });
  };
}

export function startSongReproduction (songInfo) {
  return (dispatch, getState, { services }) => {
    const { song } = songInfo;
    dispatch(songReproductionStart(song));

    const uid = getUID(getState());
    services.songReproductionService.start(uid, song);
  };
}

export function stopSongReproduction (songDto) {
  return (dispatch, getState) => {
    dispatch(songReproductionStop());
  };
}
