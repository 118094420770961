
export default function toMinutesSecondsFormat (value) {
  // value might have a value in seconds like: 1.7777777

  const seconds = parseInt(value); // round at most 2 decimal places
  // const miliseconds = Math.floor( (value - seconds) * 100 );

  const h = Math.floor(seconds / 3600);
  const m = extractMinutes(seconds);
  const s = extractSeconds(seconds);
  return [
    m > 9 ? m : (h ? '0' + m : m || '0'),
    s > 9 ? s : '0' + s
    // miliseconds
  ].filter(a => a).join(':');
}

export function extractMinutes (value) {
  if (value === null) return ''; // workaround https://github.com/facebook/react/issues/2203

  // value might have a value in seconds like: 1.7777777
  const seconds = parseInt(value); // round at most 2 decimal places
  return Math.floor(seconds / 60);
}

export function extractSeconds (value) {
  if (value === null) return ''; // workaround https://github.com/facebook/react/issues/2203

  // value might have a value in seconds like: 1.7777777
  const seconds = parseInt(value); // round at most 2 decimal places
  return Math.floor(seconds % 60);
}
