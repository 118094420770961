import { PhrasesSongSubSectionFormDTOBuilder, RepeatSongSubSectionFormDTOBuilder, SongSubSectionFormDTOBuilder } from './SubSection/SongSubSectionFormDTOBuilder';

export class SectionFormDTOBuilder {
}
SectionFormDTOBuilder.build = function (id, instrument, tags, sectionKey, subSectionsJson, customMetadata) {
  const sectionFormDTO = {
    id,
    instrument,
    tags,
    sectionKey,
    subSections: [],
    subSectionNextId: 0,
    ...customMetadata
  };
  subSectionsJson.forEach(function (element) {
    let subSection;
    if ((element.type === 'phrases') || (element.type === undefined)) {
      subSection = SectionFormDTOBuilder.createPhrasesSubSection(sectionFormDTO, element.description, element.phrases, element.startingTime, element.endingTime);
    } else if (element.type === 'repeat') {
      const subSectionToRepeat = SectionFormDTOBuilder.getSubSectionByKey(sectionFormDTO, element.songSubSectionKey);
      subSection = SectionFormDTOBuilder.createRepeatSubSection(sectionFormDTO, subSectionToRepeat, element.startingTime, element.endingTime);
    }
    SectionFormDTOBuilder.addSubSectionToTail(sectionFormDTO, subSection);
  });
  return sectionFormDTO;
};
SectionFormDTOBuilder.equals = function (sectionA, sectionB) {
  return sectionA.id === sectionB.id;
};
SectionFormDTOBuilder.addSubSectionToTail = function (section, subSection) {
  section.subSections.push(subSection);
};
SectionFormDTOBuilder.hasSubSectionInKey = function (section, subSectionKey) {
  return typeof section.subSections[subSectionKey] !== 'undefined';
};
SectionFormDTOBuilder.getSubSectionIndexesThatHasReferenceToSubsection = function (section, referencedSubSection) {
  const indexes = [];
  section.subSections.forEach((subSection, index) => {
    if (SongSubSectionFormDTOBuilder.hasReferenceToSubSection(subSection, referencedSubSection)) {
      indexes.push(index);
    }
  });
  return indexes;
};
SectionFormDTOBuilder.getKeyOfSubSection = function (section, subSection) {
  return section.subSections.findIndex((aSubSection) => SongSubSectionFormDTOBuilder.equals(aSubSection, subSection));
};
SectionFormDTOBuilder.addEmptyPhrasesSubSection = function (section, atPosition = null) {
  const newSubSection = SectionFormDTOBuilder.createPhrasesSubSection(section);
  PhrasesSongSubSectionFormDTOBuilder.addPhrase(newSubSection);
  SectionFormDTOBuilder.addSubSectionAtPosition(section, newSubSection, atPosition);
  return newSubSection;
};
SectionFormDTOBuilder.addSubSectionAtPosition = function (section, subSection, atPosition) {
  if (atPosition === null) {
    // add to the tail
    section.subSections.push(subSection);
  } else {
    // todo check valid position
    section.subSections.splice(atPosition, 0, subSection);
  }
};
SectionFormDTOBuilder.getSubSectionByKey = function (section, subSectionKey) {
  if (SectionFormDTOBuilder.hasSubSectionInKey(section, subSectionKey)) {
    return section.subSections[subSectionKey];
  } else {
    throw new Error('No existe la subsección con índice ' + subSectionKey);
  }
};
SectionFormDTOBuilder.createPhrasesSubSection = function (section, description = '', phrases = [], startingTime = null, endingTime = null) {
  return PhrasesSongSubSectionFormDTOBuilder.build(SectionFormDTOBuilder.getSubSectionNewID(section), description, phrases, startingTime, endingTime);
};
SectionFormDTOBuilder.createRepeatSubSection = function (section, subSectionToRepeat, startingTime = null, endingTime = null) {
  return RepeatSongSubSectionFormDTOBuilder.build(SectionFormDTOBuilder.getSubSectionNewID(section), subSectionToRepeat, startingTime, endingTime);
};
SectionFormDTOBuilder.getSubSectionNewID = function (section) {
  const newId = section.subSectionNextId + (section.id * 100);
  section.subSectionNextId++;
  return newId;
};
