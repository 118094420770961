import React from 'react';
import { makeStyles } from '@material-ui/core';

import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import PropTypes from 'prop-types';

const useStyles = makeStyles(theme => ({
  root: {
    color: theme.palette.secondary.main
  }
}));

function PlaybackRateSelect (props) {
  const [playbackRate, setPlaybackRate] = React.useState(1);

  const handleChange = event => {
    const newValue = event.target.value;
    setPlaybackRate(newValue);
    props.onChange(newValue);
  };

  const classes = useStyles();

  const optionsView = props.rates.map(function (option, key) {
    return <MenuItem value={option} key={key} >{option}x</MenuItem>;
  });

  return (
        <FormControl className={classes.formControl}>
            <Select
                disableUnderline
                value={playbackRate}
                onChange={handleChange}
                style={{ paddingLeft: '12px' }}
            >
                {optionsView}
            </Select>
        </FormControl>
  );
}

PlaybackRateSelect.propTypes = {
  rates: PropTypes.array.isRequired,
  onChange: PropTypes.func.isRequired
};

export default React.memo(PlaybackRateSelect);
