import React from 'react';

import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import { Box, ListItemSecondaryAction } from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import FitnessCenterIcon from '@material-ui/icons/FitnessCenter';

import StrengthMeter from './StrengthMeter';
import { Link } from 'react-router-dom';
import Typography from '@material-ui/core/Typography';
import { FormattedMessage } from 'react-intl';

class SongsToStrengthenList extends React.Component {
  render () {
    const { songsToStrengthen } = this.props;

    if (songsToStrengthen.length > 0) {
      const songsToStrengthenView = songsToStrengthen.map(function (songToStrengthen, key) {
        return (
                    <ListItem key={key}>
                        <ListItemAvatar>
                            <StrengthMeter value={songToStrengthen.strength}/>
                        </ListItemAvatar>
                        <ListItemText primary={songToStrengthen.songTitle} secondary={songToStrengthen.artistName}/>
                        <ListItemSecondaryAction>
                            <Link to={'/songs/play/' + songToStrengthen.songId} style={{ margin: '0px 4px' }}>
                                <IconButton edge="end" aria-label="Entrenar">
                                    <FitnessCenterIcon/>
                                </IconButton>
                            </Link>
                        </ListItemSecondaryAction>
                    </ListItem>
        );
      });
      return (
                <List>
                    {songsToStrengthenView}
                </List>
      );
    } else {
      return (
                <Box style={{ marginTop: 10 }}>
                    <Typography variant="body1" >
                        <FormattedMessage id="app.common.noResult"/>
                    </Typography>
                </Box>
      );
    }
  }
}

export default SongsToStrengthenList;
