import React from 'react';
import PropTypes from 'prop-types';

import Slider from '@material-ui/core/Slider';
import { orange } from '@material-ui/core/colors';

import { withStyles } from '@material-ui/core/styles';
import toMinutesSecondsFormat from '../../../utils/time';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

const CustomSlider = withStyles({
  root: {
    color: orange[700],
    height: 4,
    padding: '9px 0 6px'
  },
  thumb: {
    height: 14,
    width: 14,
    backgroundColor: '#fff',
    border: '1px solid currentColor',
    marginTop: -5,
    marginLeft: -6,
    '&:focus,&:hover,&$active': {
      boxShadow: 'inherit'
    }
  },
  active: {},
  valueLabel: {
    left: 'calc(-50% - 8px)',
    top: -34,
    '& *': {
      background: orange[500],
      color: 'black'
    }
  },
  track: {
    height: 4,
    borderRadius: 4
  },
  rail: {
    height: 2,
    borderRadius: 4
  },
  mark: {
    height: 8,
    width: 1,
    marginTop: -3
  },
  markLabel: {
    top: 16
  }
})(Slider);

function ReproductionProgress (props) {
  let marks = props.marks ? props.marks : [];
  marks = marks.map(m => ({ value: m, label: toMinutesSecondsFormat(m) }));

  return (
        <Grid container>
            <Grid item xs={2} md={1}>
                <Typography component="div" color={'secondary'} className='CurrentTime'>
                    {toMinutesSecondsFormat(props.value)}
                </Typography>
            </Grid>
            <Grid item xs={8} md={10} className='ReproductionProgress'>
                <CustomSlider
                    valueLabelDisplay="auto"
                    getAriaLabel={index =>
                      index === 0 ? 'start time' : (index === 1 ? 'current time' : 'ending time')
                    }
                    ThumbComponent={'span'}
                    value={props.value}
                    onChange={props.onChange}
                    min={0}
                    max={props.duration}
                    marks={marks}
                    valueLabelFormat={toMinutesSecondsFormat}
                />
            </Grid>
            <Grid item xs={2} md={1}>
                <Typography component="div" color={'secondary'} className='SongDuration'>
                    {toMinutesSecondsFormat(props.duration)}
                </Typography>
            </Grid>
        </Grid>
  );
}

ReproductionProgress.propTypes = {
  value: PropTypes.number.isRequired,
  duration: PropTypes.number.isRequired,
  marks: PropTypes.array,
  onChange: PropTypes.func
};

export default ReproductionProgress;
