import styled from 'styled-components';
import StandardTimeline from '@bit/ldelia.react-media.timeline';
import { withTheme } from '@material-ui/core/styles';

const Timeline = styled(StandardTimeline)`
  background-color: transparent;
  border-top: 0;
  border-bottom: 0;
  border-color: #424242;
  
  .media-timeline-value-line-canvas {
    color: ${props => props.theme.palette.secondary[500]};
  }
  .media-timeline-range-selector-canvas {
    color: ${props => props.theme.palette.secondary[100]};
  }
  .media-timeline-value-line {
    background-color: ${props => props.theme.palette.tertiary};
    width: 3px;
  }
  .media-timeline-tick-time {
    color: ${props => props.theme.palette.secondary[500]};
  }
`;

export default withTheme(Timeline);
