export class SongReproductionService {
  constructor (songsRepository, statsRepository) {
    this.songsRepository = songsRepository;
    this.statsRepository = statsRepository;
  }

  start (uid, song) {
    // We need to perform the following tasks

    // 1) logging the reproduction
    this.songsRepository.logReproduction(song, uid);

    // 2) update last played songs
    this.songsRepository.updateLastPlayedSongs(song, uid);

    // 3) update month stats
    this.statsRepository.updateMonthlyStats(uid);
  }
}
