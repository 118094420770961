import React from 'react';

import TextareaAutosize from "@material-ui/core/TextareaAutosize";
import IconButton from "@material-ui/core/IconButton";
import DeleteIcon from "@material-ui/icons/Delete";
import Tooltip from "@material-ui/core/Tooltip";
import {FormattedMessage, injectIntl} from "react-intl";
import Grid from "@material-ui/core/Grid";
import {FormControl, FormHelperText} from "@material-ui/core";

class PhraseWidget extends React.PureComponent {
    deletePhraseHandler = (phrase) => (e) => {
        const {intl, indexPosition} = this.props;

        if (window.confirm(intl.formatMessage({id: 'app.songform.section.subsection.phrase.confirmation.deletePhrase'}))) {
            this.props.onDelete(indexPosition);
        }
    };

    changeSongSubSectionPhraseDescriptionHandler = (event) => {
        const {indexPosition} = this.props;
        const value = event.target.value;
        this.props.onChange(indexPosition, {description: value});
    };

    changeSongSubSectionPhraseHelpHandler = (event) => {
        const {indexPosition} = this.props;
        const value = event.target.value;
        this.props.onChange(indexPosition, {help: value});
    };

    preventTabBehaviour = () => (e) => {
        const TABKEY = 9;
        if (e.keyCode === TABKEY) {
            if (e.preventDefault) {
                e.preventDefault();
            }

            let textarea = e.target;

            const start = textarea.selectionStart;
            const end = textarea.selectionEnd;

            // set textarea value to: text before caret + tab + text after caret
            textarea.value = textarea.value.substring(0, start) + "\t" + textarea.value.substring(end);

            // put caret at right position again
            textarea.selectionStart = textarea.selectionEnd = start + 1;

            return false;
        }
    };

    render() {
        const {phrase, indexPosition, intl} = this.props;

        return (
            <Grid container style={{ marginBottom: "15px"}}>
                <Grid item xs={11}>
                    <Grid container className='PhraseContainer'>
                        <Grid item xs={12} md={8} className="phraseDescriptionHolder">
                            <TextareaAutosize
                                placeholder={intl.formatMessage({id: 'app.songform.section.subsection.phrase.field.description'}, {number: indexPosition + 1})}
                                name="description"
                                value={phrase.description}
                                className="phraseDescription"
                                onChange={this.changeSongSubSectionPhraseDescriptionHandler}
                                onKeyDown={this.preventTabBehaviour()}
                            />
                        </Grid>
                        <Grid item xs={12} md={4} className="phraseHelpHolder">
                            <FormControl>
                                <TextareaAutosize
                                    name="help"
                                    value={phrase.help}
                                    className="phraseHelp"
                                    onChange={this.changeSongSubSectionPhraseHelpHandler}
                                />
                                <FormHelperText><FormattedMessage id="app.songform.section.subsection.phrase.field.help"/></FormHelperText>
                            </FormControl>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={1} style={{textAlign: "right", paddingRight: "20px"}}>
                    <Tooltip
                        title={intl.formatMessage({id: 'app.songform.section.subsection.phrase.button.deletebutton.hint'})}>
                        <IconButton aria-label="Delete phrase" size="small" onClick={this.deletePhraseHandler(phrase)}>
                            <DeleteIcon fontSize="inherit"/>
                        </IconButton>
                    </Tooltip>
                </Grid>
            </Grid>
        );
    }
}

export default injectIntl(PhraseWidget);
