import React from 'react';

import PlayArrowIcon from '@material-ui/icons/PlayArrow';
import IconButton from '@material-ui/core/IconButton';

function PlayButton ({ onClick, hidden = false }) {
  if (!hidden) {
    return (
            <IconButton aria-label="play" onClick={onClick}>
                <PlayArrowIcon/>
            </IconButton>
    );
  }
  return null;
}

export default PlayButton;
