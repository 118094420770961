import { SectionFormDTOBuilder } from './Section/SectionFormDTOBuilder';

export class SongFormDTOBuilder {
}
SongFormDTOBuilder.build = function (id = '', instrumentType = null, artistId = '', artistName = '', title = '', videoId = '', duration = null, tempo = '', sectionsJson = []) {
  const songFormDTO = {
    key: id,
    instrumentType,
    artistId,
    artistName,
    title,
    videoId,
    duration,
    tempo,
    sections: []
  };
  sectionsJson.forEach(function (element) {
    const customMetadata = {};

    if (element.hasOwnProperty('capo')) {
      customMetadata.capo = element.capo;
    }

    const newSection = SongFormDTOBuilder.buildNewSection(songFormDTO.sections.length, element.instrument, element.sectionKey, element.tags, element.subSections, customMetadata);
    songFormDTO.sections.push(newSection);
  });
  return songFormDTO;
};
SongFormDTOBuilder.buildNewSection = function (id, instrument = '', sectionKey = '', tags = [], subSections = [], customMetadata = {}) {
  return SectionFormDTOBuilder.build(id, instrument, tags, sectionKey, subSections, customMetadata);
};
