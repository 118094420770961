
import React from 'react';
import PhrasesSongSubSection from '../PhrasesSubSection/PhrasesSubSection';

export default function RepeatSubSection (props) {
  const subSection = props.subSection;

  const repeatedSubSection = subSection.subSectionToRepeat;

  return (
        <PhrasesSongSubSection
            description={'Repetición ' + repeatedSubSection.description}
            startingTime={subSection.startingTime}
            endingTime={subSection.endingTime}
            phrases={repeatedSubSection.phrases}
        />
  );
}
