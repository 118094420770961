import { withRouter } from 'react-router-dom';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { editSong } from '../store/actions/songsActions';

import { getSong, getSongsError, getSongsPending, getUsedTags } from '../store/reducers/songsReducer';

import { getInstrumentsTypes } from '../store/reducers/instrumentsTypesReducer';
import SongEditionView from '../components/SongEditionView/SongEditionView';
import { SongFormDTOBuilder } from '../components/SongForm/DTO/Song/SongFormDTOBuilder';
import { getArtists } from '../store/reducers/artistsReducer';

function mapStateToProps (state, ownProps) {
  const { match: { params } } = ownProps;

  let songFormDTO = null;
  const songInfo = getSong(state, params.id);
  if (songInfo) {
    const { song, artist, instrumentType } = songInfo;
    songFormDTO = SongFormDTOBuilder.build(song.id, instrumentType, artist.id, artist.name, song.title, song.videoId, song.duration, song.tempo, song.sections);
  }

  const result = {
    instrumentsTypes: getInstrumentsTypes(state),
    artists: getArtists(state),
    songError: getSongsError(state),
    songFormDTO,
    songPending: getSongsPending(state),

    usedTags: getUsedTags(state)
  };
  return result;
}

const mapDispatchToProps = dispatch => bindActionCreators({
  editSong
}, dispatch);

const SongEditionContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(SongEditionView));

export default SongEditionContainer;
