
import { CHANGE_ACCOUNT_DETAILS_SUCCESS, LOGIN_SUCCESS, LOGOUT_SUCCESS } from '../actions/sessionActions';

export const AUTH_STATE_UNKNOW = -1;
export const AUTH_STATE_LOGOUT = 0;
export const AUTH_STATE_LOGIN = 1;

const initialState = {
  auth_state: AUTH_STATE_UNKNOW,
  uid: null,
  displayName: null,
  email: null,
  emailVerified: null
};

const sessionReducer = (state = initialState, action) => {
  switch (action.type) {
    case LOGIN_SUCCESS:
      // Verificar si hay que modificar algo del state
      if (state.auth_state === AUTH_STATE_LOGIN &&
                state.uid === action.uid &&
                state.displayName === action.displayName &&
                state.email === action.email &&
                state.emailVerified === action.emailVerified
      ) return state;

      return {
        ...state,
        auth_state: AUTH_STATE_LOGIN,
        uid: action.uid,
        displayName: action.displayName,
        email: action.email,
        emailVerified: action.emailVerified
      };
    case LOGOUT_SUCCESS:

      return {
        ...state,
        auth_state: AUTH_STATE_LOGOUT,
        uid: null,
        displayName: null,
        email: null,
        emailVerified: null
      };
    case CHANGE_ACCOUNT_DETAILS_SUCCESS:
      return {
        ...state,
        displayName: action.displayName
      };
    default:
      return state;
  }
};

export default sessionReducer;

export const getAuthState = state => {
  return state.session.auth_state;
};
export const getUID = state => state.session.uid;
export const getDisplayName = state => state.session.displayName;
export const getEmail = state => state.session.email;
export const isEmailVerified = state => state.session.emailVerified;
