import React from "react";

import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';

import Button from '@material-ui/core/Button';
import Grid from "@material-ui/core/Grid";
import {FormattedMessage, injectIntl} from "react-intl";
import PropTypes from "prop-types";
import {Box} from "@material-ui/core";
import InputLabel from '@material-ui/core/InputLabel';
import Input from '@material-ui/core/Input';
import FormControl from '@material-ui/core/FormControl';
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogActions from "@material-ui/core/DialogActions";
import InputAdornment from "@material-ui/core/InputAdornment";
import PersonIcon from "@material-ui/icons/Person";

class ChangeAccountDetailsForm extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            formModel: {
                displayName: props.displayName,
            },
            showAlertDialog: false,
            alertDialogMessage: '',
        };
    }

    changeFormHandler = event => {

        const name = event.target.name;
        const value = event.target.value;

        let formModel = this.state.formModel;
        formModel[name] = value;

        this.setState({
            formModel: formModel
        });
    }

    handleCloseAlertDialog = () => {
        this.setState({showAlertDialog: false} );
    }

    handleSubmit = (event) => {
        event.preventDefault();

        let { displayName } = this.state.formModel;
        const { intl } = this.props;

        displayName = displayName.trim();

        if (displayName === '') {
            this.setState({showAlertDialog: true, alertDialogMessage: intl.formatMessage({id: 'app.common.incompleteFormWarning'}) } );
            return;
        }

        this.props.onSubmit(displayName);
    }

    render() {

        const {formModel, showAlertDialog, alertDialogMessage} = this.state;
        const {intl} = this.props;

        return (
            <Card>
                <CardContent>
                    <Grid>
                        <Grid item xs>
                            <FormControl>
                                <InputLabel htmlFor="standard-adornment-displayName-1">
                                    <FormattedMessage id="app.profile.account.form.displayName"/>
                                </InputLabel>
                                <Input
                                    id="standard-adornment-displayName-1"
                                    type={'text'}
                                    name="displayName"
                                    value={formModel.displayName}
                                    onChange={this.changeFormHandler}
                                    required
                                    endAdornment={
                                        <InputAdornment position="end">
                                            <PersonIcon/>
                                        </InputAdornment>
                                    }
                                />
                            </FormControl>
                        </Grid>
                        <Dialog
                            open={showAlertDialog}
                            keepMounted
                            onClose={this.handleCloseAlertDialog}
                            aria-labelledby="alert-dialog-slide-title"
                            aria-describedby="alert-dialog-slide-description"
                        >
                            <DialogTitle id="alert-dialog-slide-title">{intl.formatMessage({id: 'app.common.warning'})}</DialogTitle>
                            <DialogContent>
                                <DialogContentText id="alert-dialog-slide-description">
                                    {alertDialogMessage}
                                </DialogContentText>
                            </DialogContent>
                            <DialogActions>
                                <Button onClick={this.handleCloseAlertDialog} color="secondary">
                                    {intl.formatMessage({id: 'app.common.ok'})}
                                </Button>
                            </DialogActions>
                        </Dialog>
                    </Grid>

                    <Box display="flex" justifyContent="flex-end">
                        <Button size="small" onClick={this.handleSubmit} color="secondary" variant={"contained"} >
                            <FormattedMessage id="app.common.ok"/>
                        </Button>
                    </Box>
                </CardContent>
            </Card>
        );
    }
}

ChangeAccountDetailsForm.propTypes = {
    onSubmit: PropTypes.func.isRequired,
    displayName: PropTypes.string.isRequired
}
export default injectIntl(ChangeAccountDetailsForm);