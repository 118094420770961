import React from 'react';
import Grid from '@material-ui/core/Grid';
import List from '@material-ui/core/List';
import SubSectionWidget from '../SubSectionWidget';
import { injectIntl } from 'react-intl';
import RepeatedPhraseView from './RepeatedPhraseView';

class RepeatSubSectionWidget extends React.PureComponent {
  render () {
    const { subSection, onSubSectionUpdate, onSubSectionDelete, selectedForLoop, toggleSelectedSubSectionForLoop, intl, indexPosition } = this.props;

    const repeatedSubSection = subSection.subSectionToRepeat;

    const repeatedSubSectionPhrasesView = repeatedSubSection.phrases.map((phrase, key) => {
      return <RepeatedPhraseView
                key={key}
                phrase={phrase}
            />;
    });

    return (
            <SubSectionWidget
                subSection={subSection}
                indexPosition={indexPosition}
                onSubSectionUpdate={onSubSectionUpdate}
                onSubSectionDelete={onSubSectionDelete}
                selectedForLoop={selectedForLoop}
                toggleSelectedSubSectionForLoop={toggleSelectedSubSectionForLoop}
                descriptionValue={repeatedSubSection.description}
                descriptionLabel={intl.formatMessage({ id: 'app.songform.section.repeatSubSection.field.description' }, { position: indexPosition + 1 })}
                descriptionChangeEnabled={false}
            >
                <Grid item xs={12} className='RepeatSubSectionFormContainer'>
                    <List
                        className="SongListPhrase"
                        key={subSection.id}
                    >
                        {repeatedSubSectionPhrasesView}
                    </List>
                </Grid>
            </SubSectionWidget>
    );
  }
}
export default injectIntl(RepeatSubSectionWidget);
