
export const TRAINING_MODE_CHANGED = 'TRAINING_MODE_CHANGED';

function trainingModeChanged (value) {
  return {
    type: TRAINING_MODE_CHANGED,
    value
  };
}

export function setTrainingMode (value) {
  return (dispatch, getState, { services }) => {
    dispatch(trainingModeChanged(value));
  };
}
