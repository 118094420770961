import { getInstrumentsTypes, getInstrumentsTypesFetched } from '../reducers/instrumentsTypesReducer';

export const FETCH_INSTRUMENTS_TYPES_PENDING = 'FETCH_INSTRUMENTS_TYPES_PENDING';
export const FETCH_INSTRUMENTS_TYPES_SUCCESS = 'FETCH_INSTRUMENTS_TYPES_SUCCESS';
export const FETCH_INSTRUMENTS_TYPES_ERROR = 'FETCH_INSTRUMENTS_TYPES_ERROR';

function fetchInstrumentsTypesPending () {
  return {
    type: FETCH_INSTRUMENTS_TYPES_PENDING
  };
}

function fetchInstrumentsTypesSuccess (instruments) {
  return {
    type: FETCH_INSTRUMENTS_TYPES_SUCCESS,
    instruments
  };
}

function fetchInstrumentsTypesError (error) {
  return {
    type: FETCH_INSTRUMENTS_TYPES_ERROR,
    error
  };
}

export function fetchInstrumentsTypes () {
  return (dispatch, getState, { services }) => {
    if (!getInstrumentsTypesFetched(getState())) dispatch(fetchInstrumentsTypesPending());

    return services.instrumentsTypeRepository
      .findAll()
      .then((instrumentsTypes) => {
        if (JSON.stringify(getInstrumentsTypes(getState())) !== JSON.stringify(instrumentsTypes)) { dispatch(fetchInstrumentsTypesSuccess(instrumentsTypes)); }
      }).catch(error => {
        dispatch(fetchInstrumentsTypesError(error));
      });
  };
}
