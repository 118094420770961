import React from 'react';
import { makeStyles } from '@material-ui/core/styles/index';
import IconButton from '@material-ui/core/IconButton/index';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import AppBar from '@material-ui/core/AppBar/index';
import Toolbar from '@material-ui/core/Toolbar/index';
import Typography from '@material-ui/core/Typography/index';
import { injectIntl } from 'react-intl';
import FormGroup from '@material-ui/core/FormGroup';
import { withRouter } from 'react-router-dom';
import TransposeInput from './tools/transpose/TransposeInput';
import { INSTRUMENT_TYPE_RELATED_TOOL } from '../../store/entities/instrumentTypes';
import { ToggleButton } from '@material-ui/lab';
import HearingIcon from '@material-ui/icons/Hearing';
import { Box } from '@material-ui/core';
import MenuItem from '@material-ui/core/MenuItem';
import ConfigMenu from '../../dashboard/ConfigMenu';
import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import PropTypes from 'prop-types';
import CapoInput from './tools/capo/CapoInput';

const useStyles = makeStyles(theme => ({
  appBar: {
    backgroundColor: theme.palette.primary.dark,
    position: 'absolute',
    top: 0,
    left: 0
  },
  toggleButtonSelected: {
    color: theme.palette.secondary.main + '!important',
    backgroundColor: 'inherit!important'
  }
}));

function ReproductionViewAppBar (props) {
  const classes = useStyles();
  const theme = useTheme();
  const smDeviceOrUpper = useMediaQuery(theme.breakpoints.up('sm'));
  const intl = props.intl;

  if (!props.visibility) return (<div></div>);

  const goBack = () => {
    const history = props.history;
    history.goBack();
  };

  const menuItems = [];

  let TransposeInputComponent = null;
  if (props.tools[INSTRUMENT_TYPE_RELATED_TOOL.TRANSPOSE]) {
    TransposeInputComponent = <TransposeInput value={props.tools[INSTRUMENT_TYPE_RELATED_TOOL.TRANSPOSE].offset} onChange={ (value) => props.onToolChange(INSTRUMENT_TYPE_RELATED_TOOL.TRANSPOSE, 'offset', value) }/>;
    if (!smDeviceOrUpper) {
      menuItems.push(
                <MenuItem key={menuItems.length}>
                    {TransposeInputComponent}
                </MenuItem>
      );
    }
  }

  let CapoInputComponent = null;

  if (props.tools[INSTRUMENT_TYPE_RELATED_TOOL.CAPO]) {
    const capoInputClickHandler = (value) => props.onToolChange(INSTRUMENT_TYPE_RELATED_TOOL.TRANSPOSE, 'offset', -value);
    CapoInputComponent = <CapoInput value={props.tools[INSTRUMENT_TYPE_RELATED_TOOL.CAPO].value} onClick={capoInputClickHandler} />;
    if (!smDeviceOrUpper) {
      menuItems.push(
                <MenuItem key={menuItems.length}>
                    {CapoInputComponent} Capo
                </MenuItem>
      );
    }
  }

  return (
        <div>
            <AppBar className={classes.appBar}>
                <Toolbar>
                    <IconButton onClick={goBack}>
                        <ArrowBackIcon/>
                    </IconButton>
                    <Typography component="h1" variant="h6" color="inherit" noWrap>
                        {props.title}
                    </Typography>
                    <FormGroup row style={{ marginLeft: 'auto' }}>
                        <ToggleButton
                            selected={props.trainingMode}
                            onChange={() => {
                              props.setTrainingMode(!props.trainingMode);
                            }}
                            value="checkedA"
                            classes={{
                              selected: classes.toggleButtonSelected
                            }}
                        >
                            <HearingIcon/>
                            <Box component="span" display={{ xs: 'none', sm: 'block' }}>
                                {intl.formatMessage({ id: 'app.config.trainingMode' })}
                            </Box>
                        </ToggleButton>
                    </FormGroup>
                    {
                        (CapoInputComponent !== null && smDeviceOrUpper) && (
                          CapoInputComponent
                        )
                    }
                    {
                        (TransposeInputComponent !== null && smDeviceOrUpper) && (
                          TransposeInputComponent
                        )
                    }
                    {
                        (menuItems.length > 0) && (
                            <ConfigMenu items={menuItems} />
                        )
                    }
                </Toolbar>
            </AppBar>
        </div>
  );
}

ReproductionViewAppBar.propTypes = {
  visibility: PropTypes.bool.isRequired,
  title: PropTypes.string.isRequired,
  trainingMode: PropTypes.bool.isRequired,
  setTrainingMode: PropTypes.func.isRequired,
  tools: PropTypes.object.isRequired,
  onToolChange: PropTypes.func.isRequired
};

export default withRouter(injectIntl(ReproductionViewAppBar));
