import messages_en from './en.json';
import messages_es from './es.json';

export const messages = {
  en: messages_en,
  es: messages_es
};

export let language = navigator.language.split(/[-_]/)[0]; // language without region code

if (!messages.hasOwnProperty(language)) {
  // language fallback is english
  language = 'en';
}
