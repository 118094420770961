
import React from 'react';

class Song extends React.Component {
  render () {
    return <div className="SongViewContainer">
                { this.props.children }
        </div>;
  }
}

export default Song;
