import React from "react";

import CustomSelect from "./CustomSelect";

class InstrumentSelect extends React.Component {

    changeHandler = (event) => {
        this.props.onChange(event.target.value);
    }
    render() {
        if (this.props.instrumentType === null) {
            return (<div></div>);
        } else {
            let autoWidth = (this.props.hasOwnProperty('autoWidth')) ? this.props.autoWidth : true;
            const error = (this.props.hasOwnProperty('error')) ? this.props.error : false;

            let instruments = this.props.instrumentType.instruments;
            instruments = instruments.map(function (obj) {
                return {key: obj, value: obj};
            });

            let value = this.props.value;

            return (
                <CustomSelect
                    label={this.props.instrumentType.name}
                    name={this.props.name}
                    autoWidth={autoWidth}
                    InputLabelProps={this.props.InputLabelProps}
                    id={this.props.id}
                    error={error}
                    options={instruments}
                    value={value}
                    margin={this.props.margin}
                    style={this.props.style}
                    onChange={this.changeHandler}
                    required={this.props.required}
                />
            );
        }
    }
}

export default InstrumentSelect;
