import { YouTubePlayer } from '../../domain/Reproduction/Player/YouTubePlayer';
import React from 'react';
import { PlayAlongPlayer } from '../../domain/Reproduction/Player/PlayAlongPlayer';
import PlayAlongInnerPlayer from './innerPlayer/PlayAlongInnerPlayer';
import YouTubeInnerPlayer from './innerPlayer/YouTubeInnerPlayer';

export default function ReproductionPlayer (props) {
  function onReady (event) {
    const model = props.model;
    model.setInnerPlayer(event.target);
  }

  if (props.videoId && (props.model instanceof YouTubePlayer)) {
    return (
            <div className="SongPlayerTrainingSection">
                <YouTubeInnerPlayer
                    videoId={props.videoId}
                    onReady={onReady}
                />
            </div>
    );
  } else if (props.model instanceof PlayAlongPlayer) {
    return (
            <div className="SongPlayerPlayAlongSection">
                <PlayAlongInnerPlayer
                    onReady={onReady}
                />
            </div>
    );
  }
  return null;
}
