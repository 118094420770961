import React from 'react';

import { withStyles } from '@material-ui/core/styles';

import { grey, green, lightGreen, yellow, orange, deepOrange } from '@material-ui/core/colors';

import LinearProgress from '@material-ui/core/LinearProgress';

const BorderLinearProgress = withStyles({
  root: {
    height: 12,
    backgroundColor: grey[500],
    marginLeft: 10,
    marginRight: 10
  },
  bar: {
    borderRadius: 20,
    backgroundColor: '#ff6c5c'
  }
})(LinearProgress);

const VeryHighLinearProgress = withStyles({
  bar: {
    backgroundColor: green[500]
  }
})(BorderLinearProgress);

const HighLinearProgress = withStyles({
  bar: {
    backgroundColor: lightGreen[500]
  }
})(BorderLinearProgress);

const MediumLinearProgress = withStyles({
  bar: {
    backgroundColor: yellow[500]
  }
})(BorderLinearProgress);

const WeakLinearProgress = withStyles({
  bar: {
    backgroundColor: orange[500]
  }
})(BorderLinearProgress);

const VeryWeakLinearProgress = withStyles({
  bar: {
    backgroundColor: deepOrange[500]
  }
})(BorderLinearProgress);

export default function StrengthMeter (props) {
  let linearProgressWidget = <div></div>;

  switch (props.value) {
    case 'VERY_WEAK':

      linearProgressWidget = <VeryWeakLinearProgress
                value={15}
                variant="determinate"
            />;
      break;

    case 'WEAK':

      linearProgressWidget = <WeakLinearProgress
                value={35}
                variant="determinate"
            />;
      break;

    case 'MEDIUM':

      linearProgressWidget = <MediumLinearProgress
                value={50}
                variant="determinate"
            />;
      break;

    case 'HIGH':

      linearProgressWidget = <HighLinearProgress
                        value={75}
                        variant="determinate"
            />;
      break;

    case 'VERY_HIGH':

      linearProgressWidget = <VeryHighLinearProgress
                value={100}
                variant="determinate"
            />;
      break;

    default:
      break;
  }

  return linearProgressWidget;
}
