import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import {
  getFilteredSongs,
  getSongsError,
  getSongsFilter,
  getSongsPending,
  getUsedTags,
  getUsedInstrumentsTypes,
  getSongsCount
} from '../store/reducers/songsReducer';
import { deleteSong, setSongsFilter } from '../store/actions/songsActions';

import SongsListView from '../components/SongsListView/SongsListView';
import { SongListItemDTO } from '../components/SongsList/DTO/SongListItemDTO';
import { getArtists } from '../store/reducers/artistsReducer';

function mapStateToProps (state, ownProps) {
  const songs = getFilteredSongs(state);

  const songsDTO = [];
  songs.forEach(function (songInfo) {
    const { song, artist, instrumentType } = songInfo;

    songsDTO.push(new SongListItemDTO(
      song.id,
      song.title,
      artist.name,
      song.sections,
      instrumentType.name
    ));
  });

  return {
    songsError: getSongsError(state),
    songsPending: getSongsPending(state),
    songsFilter: getSongsFilter(state),
    instrumentsTypes: getUsedInstrumentsTypes(state),
    usedTags: getUsedTags(state),
    songsCount: getSongsCount(state),
    artists: getArtists(state),
    filteredSongs: songsDTO
  };
}

const mapDispatchToProps = dispatch => bindActionCreators({
  deleteSong,
  setSongsFilter
}, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SongsListView);
